<template>
    <div
        id="modal-queue-message-viewer"
        class="modal modal-blur fade"
        tabindex="-1"
        aria-modal="true"
        role="dialog"
    >
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Visualizar mensagem completa</h5>
                    <button
                        id="closeForm"
                        type="button"
                        class="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                    ></button>
                </div>
                <div class="modal-body pt-2">
                    <div class="modal-body-container">
                        <pre>{{ content }}</pre>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "CodeEditorModal",
    props: {
        job: {
            type: Object
        }
    },
    data() {
        return {
            content: ""
        }
    },
    mounted() {
        var modal = new bootstrap.Modal(
            document.getElementById("modal-queue-message-viewer"),
            {
                keyboard: false
            }
        )
        modal.show()

        try {
            JSON.parse(this.job.data.message)
            this.content = JSON.stringify(
                JSON.parse(this.job.data.message),
                null,
                4
            )
        } catch (error) {
            this.content = this.job.data.message
        }
    }
}
</script>
