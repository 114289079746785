<template>
    <div>
        <div class="widget-content">
            <div class="widget-title">
                <div class="row">
                    <div class="col">
                        <h4 v-if="config.show_label">{{ config.label }}</h4>
                    </div>
                    <div class="col-auto">
                        <div v-if="queryData" class="table-pagination">
                            <small v-if="queryData.total" class="total"
                                >Exibindo de
                                {{
                                    (queryData.page - 1) *
                                        queryData.show_per_page +
                                    1
                                }}
                                até
                                {{ queryData.page * queryData.show_per_page }}
                                ({{
                                    numberFormat(queryData.total)
                                }}
                                registros)</small
                            >
                            <button
                                class="btn btn-sm"
                                :disabled="queryData.page <= 1"
                                @click="prevPage()"
                            >
                                &laquo; prev
                            </button>
                            <button
                                class="btn btn-sm"
                                :disabled="
                                    queryData.total_pages <= queryData.page
                                "
                                @click="nextPage()"
                            >
                                next &raquo;
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div
                v-if="queryData && queryData.total"
                class="widget-table-container"
            >
                <table class="table">
                    <thead>
                        <th v-for="column in columnsToShow" :key="column">
                            {{ column.label }}
                        </th>
                    </thead>
                    <tbody>
                        <tr v-for="row in queryData.items" :key="row._id">
                            <td v-for="column in columnsToShow" :key="column">
                                {{
                                    row[column.name]
                                        ? formatRowData(
                                              column,
                                              row[column.name]
                                          )
                                        : formatRowData(
                                              column,
                                              row.data[column.name]
                                          )
                                }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <div v-if="editMode" class="widget-options">
            <i class="btn-icon ti ti-settings-filled" @click="showConfig()"></i>
            <i class="btn-icon ti ti-copy" @click="duplicate()"></i>
            <i
                class="btn-icon ti ti-trash text-red"
                @click="removeWidget()"
            ></i>
        </div>

        <WidgetConfigModal
            v-if="editMode && showWidgetConfig"
            :key="showWidgetConfig"
            :widget="widget"
            :defaults="config"
            :params="widgetParams"
            :tables="tables"
            @refresh-data="refreshData"
            @update-config="updateConfig"
        />
    </div>
</template>

<script>
import moment from "moment-timezone"
import WidgetConfigModal from "./WidgetConfigModal.vue"

export default {
    name: "DatabaseListWidget",
    components: {
        WidgetConfigModal
    },
    props: {
        widget: Object,
        editMode: Boolean,
        flows: Array,
        tables: Array,
        widgetsQueryResult: Object
    },
    data() {
        return {
            config: {
                periodType: "dashboard",
                table_id: "",
                start_date: null,
                end_date: null,
                show_flow_name: false,
                show_label: true,
                label: "Registros",
                columns: [],
                period_field: "created_at",
                sort_column: "created_at",
                sort_dir: "desc",
                show_per_page: 10
            },
            modalWidgetOptions: null,
            showWidgetConfig: null,
            queryData: {},
            columnsToShow: [],
            widgetParams: [
                {
                    name: "periodType",
                    type: "period",
                    label: "Período",
                    required: true,
                    refresh_on_update: true,
                    allow_all: true
                },
                {
                    name: "show_label",
                    type: "boolean",
                    label: "Exibir título",
                    required: false
                },
                {
                    name: "label",
                    type: "text",
                    label: "Título",
                    required: false
                },
                { name: "sep1", type: "separator" },
                {
                    name: "table_id",
                    type: "table-selector",
                    label: "Selecione a tabela",
                    required: false,
                    refresh_on_update: true
                },
                // {name: "columns", type: "text", label: "Colunas para exibição (separados por vírgula)", required: true},
                {
                    name: "columns",
                    type: "table-column-selector",
                    label: "Colunas para exibição",
                    required: true
                },
                {
                    name: "period_field",
                    type: "text",
                    label: "Coluna para filtro de período",
                    required: true
                },
                {
                    name: "sort_column",
                    type: "text",
                    label: "Coluna para ordenar resultados",
                    required: true
                },
                {
                    name: "sort_dir",
                    type: "radio",
                    label: "Direção da ordenação",
                    required: true,
                    options: [
                        { label: "ASC", value: "asc" },
                        { label: "DESC", value: "desc" }
                    ]
                },
                {
                    name: "show_per_page",
                    type: "text",
                    label: "Registros por página",
                    required: false
                }
            ]
        }
    },
    mounted() {
        moment.tz.setDefault("America/Sao_Paulo")

        if (this.widget.config) {
            this.config = this.widget.config
        }

        this.$watch("widgetsQueryResult", this.updateQueryData, { deep: true })

        this.updateConfig()
        this.updateQueryData()
    },
    methods: {
        showConfig() {
            this.showWidgetConfig = Date.now()
        },
        removeWidget() {
            this.$emit("removeWidget", this.widget)
        },
        duplicate() {
            this.$emit("duplicateWidget", this.widget)
        },
        updateConfig() {
            this.$emit("updateConfig", this.widget, this.config)
        },
        updateQueryData() {
            this.queryData = this.widgetsQueryResult[this.widget.id]
            this.columnsToShow = []

            if (this.queryData && Array.isArray(this.config.columns)) {
                this.config.columns.forEach((c) => {
                    var column = this.queryData.columns.find(
                        (col) => col.name == c.trim()
                    )
                    if (column) {
                        this.columnsToShow.push(column)
                    }
                })
            }
        },
        refreshData() {
            this.$emit("refreshData")
        },
        numberFormat(number) {
            return new Intl.NumberFormat("pt-BR").format(number)
        },
        formatDate(string) {
            return moment(string).format("DD/MM/YYYY HH:mm:ss")
        },
        nextPage() {
            this.$emit("refreshWidgetData", this.widget, {
                page: this.queryData.page + 1
            })
        },
        prevPage() {
            this.$emit("refreshWidgetData", this.widget, {
                page: this.queryData.page - 1
            })
        },
        formatRowData(column, value) {
            if (column.type == "date" || column.type == "datetime") {
                return this.formatDate(value)
            }

            if (column.type == "boolean") {
                return value ? "Sim" : "Não"
            }

            return value
        }
    }
}
</script>
