<template>
    <div>
        <div>
            <div class="page-wrapper">
                <div class="page-body">
                    <div class="row g-2">
                        <div class="col">
                            <div class="card">
                                <div class="card-header">
                                    <div class="col">
                                        <h3 class="card-title">
                                            <i class="ti ti-database-export"></i>
                                            Datasources
                                        </h3>
                                    </div>
                                    <div class="col-auto">
                                        <a href="/datasources/new" class="btn btn-primary">
                                            <i class="ti ti-plus"></i>
                                            Criar datasource
                                        </a>
                                    </div>
                                </div>

                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-3 mb-2">
                                            <input type="text" class="form-control" v-model="filter.query"
                                                @change="updateFilter" placeholder="Buscar datasources" />
                                        </div>
                                    </div>
                                </div>

                                <div class="table" v-if="datasourcesList.length">
                                    <table class="table table-vcenter card-table">
                                        <thead>
                                            <tr>
                                                <th>Nome</th>
                                                <th>Tipo</th>
                                                <th>Grupo</th>
                                                <th>Atualização</th>
                                                <th>Ações</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="datasource in datasourcesList" v-bind:key="datasource._id">
                                                <td>
                                                    {{ datasource.name }}
                                                </td>
                                                <td>
                                                    <span class="badge bg-azure-lt">{{ datasource.type }}</span>
                                                </td>
                                                <td>
                                                    <span v-if="!groupName(datasource.group)"
                                                        class="badge bg-gray-lt text-muted">Sem grupo</span>
                                                    <span v-else class="badge bg-blue-lt">{{ groupName(datasource.group)
                                                        }}</span>
                                                </td>
                                                <td>
                                                    {{
                                                        formatDate(
                                                            datasource.updated_at
                                                        )
                                                    }}
                                                </td>
                                                <td>
                                                    <a v-bind:href="'/datasources/view/' + datasource._id" class="btn btn-sm btn-azure">
                                                        <i class="ti ti-eye"></i>
                                                        Visualizar
                                                    </a>
                                                    &nbsp;
                                                    <a v-bind:href="'/datasources/edit/' + datasource._id"  class="btn btn-sm">
                                                        <i class="ti ti-pencil"></i>
                                                        Editar
                                                    </a>
                                                    &nbsp;
                                                    <a v-bind:href="'/datasources/delete/' + datasource._id" class="btn btn-sm bg-danger-lt">
                                                        <i class="ti ti-trash"></i>
                                                        Remover
                                                    </a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                                <div class="card-body" v-if="!datasourcesList.length && !filter.query">
                                    <div class="empty">
                                        <p class="empty-title">
                                            Nenhum datasource criado
                                            ainda.
                                        </p>
                                        <p class="empty-subtitle text-muted">
                                            Clique no botão abaixo para criar
                                            sua primeiro datasource
                                            personalizada!
                                        </p>
                                        <div class="empty-action">
                                            <a href="/datasources/new" class="btn btn-primary">
                                                <i class="ti ti-plus"></i>
                                                Criar datasource
                                            </a>
                                        </div>
                                    </div>
                                </div>

                                <div class="card-body" v-if="!datasourcesList.length && filter.query">
                                    <div class="empty bg-muted-lt">
                                        <p class="empty-title">
                                            Nenhum datasource encontrado
                                            para esta busca.
                                        </p>
                                    </div>
                                </div>

                                <div class="card-footer">
                                    <Pagination :key="paginationKey" :showPerPage="showPerPage"
                                        :currentPage="currentPage" :total="total" v-if="total"
                                        @pageChange="updatePage" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import EventBus from "../services/event-bus"
import Api from "../services/api"
import { store } from "../store"
import Pagination from "../components/Pagination"
import moment from "moment"
import EventBus from "../services/event-bus"

export default {
    name: "GroupsPage",
    components: {
        Pagination
    },
    data() {
        return {
            paginationKey: null,
            showPerPage: 10,
            currentPage: 1,
            total: 0,
            datasourcesList: [],
            filter: {
                query: ""
            }
        }
    },
    async mounted() {
        store.showSidebar = true
        store.showHeader = true
        store.showGroupButton = true

        this.currentPage = parseInt(this.$route.query.page) || 1
        this.filter.query = this.$route.query.query || ""
        this.filter.group = localStorage.getItem("selectedGroup") || ""
        this.loadDatasources()

        EventBus.on("update-selected-group", () => {
            this.filter.group = localStorage.getItem("selectedGroup") || ""
            this.updateFilter()
        })
    },
    methods: {
        formatDate(date) {
            return moment(date).format("DD/MM/YYYY HH:mm:ss")
        },
        async loadDatasources() {
            this.paginationKey = Math.random()

            var response = await Api.datasources.list(
                this.currentPage,
                this.showPerPage,
                this.filter
            )

            this.datasourcesList = response.data.items
            this.total = response.data.total
        },
        updateFilter() {
            this.$router.replace({
                query: {
                    page: 1,
                    query: this.filter.query
                }
            })

            this.loadDatasources()
        },

        updatePage(page) {
            this.currentPage = page
            this.paginationKey = Math.random()

            var queryParams = {
                page: page
            }

            if (this.filter.query) queryParams.query = this.filter.query

            this.$router.replace({
                query: queryParams
            })

            this.loadDatasources()
        },

        groupName(group_id) {
            if (!group_id) return null

            var group = null
            store.groups.forEach((item) => {
                if (item._id == group_id) {
                    group = item
                }
            })

            return group ? group.name : null
        }
    }
}
</script>
