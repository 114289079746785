<template>
    <div>
        <div class="widget-content valign-middle text-center">
            <div>
                <span
                    v-if="queryData !== undefined"
                    class="text-count"
                    :class="'size-' + config.font_size"
                    >{{ numberFormat(queryData.value) }}</span
                >
                <span
                    v-else
                    class="text-count text-muted"
                    :class="'size-' + config.font_size"
                    >0</span
                >
                <p v-show="config.show_label">{{ config.label }}</p>
            </div>
            <small
                v-if="config.show_flow_name && flowName"
                class="text-muted"
                >{{ flowName }}</small
            >
        </div>

        <div v-if="editMode" class="widget-options">
            <i class="btn-icon ti ti-settings-filled" @click="showConfig()"></i>
            <i class="btn-icon ti ti-copy" @click="duplicate()"></i>
            <i
                class="btn-icon ti ti-trash text-red"
                @click="removeWidget()"
            ></i>
        </div>

        <WidgetConfigModal
            v-if="editMode && showWidgetConfig"
            :key="showWidgetConfig"
            :widget="widget"
            :defaults="config"
            :params="widgetParams"
            :flows="flows"
            @refresh-data="refreshData"
            @update-config="updateConfig"
        />
    </div>
</template>

<script>
import WidgetConfigModal from "./WidgetConfigModal.vue"

export default {
    name: "LogCountWidget",
    components: {
        WidgetConfigModal
    },
    props: {
        widget: Object,
        editMode: Boolean,
        flows: Array,
        widgetsQueryResult: Object
    },
    data() {
        return {
            config: {
                periodType: "dashboard",
                flow_id: "",
                start_date: null,
                end_date: null,
                show_flow_name: false,
                show_label: true,
                font_size: "m",
                label: "",
                search_term: "",
                search_field: "message"
            },
            modalWidgetOptions: null,
            showWidgetConfig: null,
            queryData: {},
            flowName: null,
            widgetParams: [
                {
                    name: "search_term",
                    type: "text",
                    label: "Buscar por logs com (termo ou frase exata)",
                    required: true
                },
                {
                    name: "search_field",
                    type: "radio",
                    label: "Buscar pelo termo em",
                    required: true,
                    options: [
                        { label: "Mensagem do log", value: "message" },
                        { label: "Label do log", value: "label" }
                    ]
                },
                {
                    name: "flow_id",
                    type: "flow-selector",
                    label: "Selecione o Flow",
                    required: false,
                    refresh_on_update: true
                },
                {
                    name: "periodType",
                    type: "period",
                    label: "Período",
                    required: true,
                    refresh_on_update: true
                },
                {
                    name: "font_size",
                    type: "radio",
                    label: "Tamanho da fonte",
                    required: true,
                    options: [
                        { label: "P", value: "p" },
                        { label: "M", value: "m" },
                        { label: "G", value: "g" }
                    ]
                },
                {
                    name: "show_label",
                    type: "boolean",
                    label: "Exibir legenda",
                    required: false
                },
                {
                    name: "label",
                    type: "text",
                    label: "Legenda",
                    required: false
                },
                {
                    name: "show_flow_name",
                    type: "boolean",
                    label: "Exibir nome do Flow",
                    required: false
                }
            ]
        }
    },
    mounted() {
        if (this.widget.config) {
            this.config = this.widget.config
        }

        this.$watch("widgetsQueryResult", this.updateQueryData, { deep: true })

        this.updateConfig()
        this.updateQueryData()
    },
    methods: {
        showConfig() {
            this.showWidgetConfig = Date.now()
        },
        removeWidget() {
            this.$emit("removeWidget", this.widget)
        },
        duplicate() {
            this.$emit("duplicateWidget", this.widget)
        },
        updateConfig() {
            this.$emit("updateConfig", this.widget, this.config)
        },
        updateQueryData() {
            this.flowName = this.flows.find(
                (flow) => flow._id === this.config.flow_id
            )?.name
            this.queryData = this.widgetsQueryResult[this.widget.id]
        },
        refreshData() {
            this.$emit("refreshData")
        },
        numberFormat(number) {
            return new Intl.NumberFormat("pt-BR").format(number)
        }
    }
}
</script>

<style scoped>
.size-p {
    font-size: 1rem;
}
.size-m {
    font-size: 2rem;
}
.size-g {
    font-size: 3rem;
}
</style>
