<template>
    <div class="page-wrapper">
        <div class="page-body">
            <div class="row g-2">
                <div class="col">
                    <div class="card">
                        <div class="card-header">
                            <div class="col">
                                <h3 class="card-title">
                                    <i class="ti ti-box"></i>
                                    {{ isNew ? "Novo form" : "Editar form" }}
                                </h3>
                                <small v-if="!isNew" class="text-muted"
                                    >ID: {{ form._id }}</small
                                >
                            </div>
                            <div class="col-auto">
                                <button
                                    v-if="isNew"
                                    id="importFormButton"
                                    class="btn"
                                    @click="importForm()"
                                >
                                    <i class="ti ti-upload"></i>
                                    Importar form
                                </button>
                                <span>&nbsp;</span>
                                <button
                                    id="exportFormButton"
                                    class="btn"
                                    @click="exportForm()"
                                >
                                    <i class="ti ti-download"></i>
                                    Exportar este form
                                </button>
                            </div>
                            <div class="col-auto px-2">
                                <a
                                    id="showFormButton"
                                    :href="
                                        workspace.engine_url +
                                        'forms/' +
                                        form._id
                                    "
                                    class="btn btn-outline-primary"
                                    target="_blank"
                                >
                                    <i class="ti ti-eye"></i>
                                    Visualizar
                                </a>
                            </div>
                        </div>

                        <div class="card-body">
                            <div class="row mb-3">
                                <div class="col">
                                    <label class="form-label required"
                                        >Nome do formulário</label
                                    >
                                    <input
                                        id="nameInput"
                                        v-model="form.name"
                                        type="text"
                                        class="form-control"
                                        placeholder="Exemplo: Solicitação de suporte de TI"
                                    />
                                    <div class="form-hint">
                                        Visível apenas internamente no Workspace
                                    </div>
                                </div>
                                <div class="col-auto">
                                    <label class="form-label">Grupo</label>
                                    <div class="input-group">
                                        <span class="input-group-text">
                                            <i class="ti ti-folders"></i>
                                        </span>
                                        <select
                                            v-model="form.group"
                                            class="form-select"
                                        >
                                            <option value="">Sem grupo</option>
                                            <option
                                                v-for="group in groupsList"
                                                :key="group._id"
                                                :value="group._id"
                                            >
                                                {{ group.name }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-auto">
                                    <label class="form-label required"
                                        >Status</label
                                    >
                                    <select
                                        id="formStatusSelect"
                                        v-model="form.status"
                                        class="form-select"
                                    >
                                        <option value="draft">Rascunho</option>
                                        <option value="published">
                                            Publicado
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div class="card">
                            <div class="card-header">
                                <ul
                                    class="nav nav-tabs card-header-tabs"
                                    data-bs-toggle="tabs"
                                    role="tablist"
                                >
                                    <li class="nav-item" role="presentation">
                                        <a
                                            id="formFieldsTab"
                                            href="#tabs-fields"
                                            class="nav-link active"
                                            data-bs-toggle="tab"
                                            aria-selected="true"
                                            role="tab"
                                            tabindex="-1"
                                        >
                                            <i class="ti ti-settings"></i>
                                            Campos do formulário
                                        </a>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <a
                                            id="viewConfigTab"
                                            href="#tabs-view-config"
                                            class="nav-link"
                                            data-bs-toggle="tab"
                                            aria-selected="false"
                                            role="tab"
                                        >
                                            <i class="ti ti-code"></i>
                                            Configurações de exibição
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div class="card-body">
                                <div class="tab-content">
                                    <div
                                        id="tabs-fields"
                                        class="tab-pane active show"
                                        role="tabpanel"
                                    >
                                        <div class="row">
                                            <div class="col">
                                                <label class="form-label"
                                                    >Campos do formulário</label
                                                >
                                                <p class="text-muted">
                                                    Aqui você deve inserir os
                                                    campos do seu formulário que
                                                    serão exibidos para
                                                    preenchimento pelos
                                                    usuários.
                                                </p>
                                                <table class="table">
                                                    <thead>
                                                        <tr>
                                                            <th></th>
                                                            <th width="20%">
                                                                Tipo
                                                            </th>
                                                            <th>
                                                                Código do campo
                                                            </th>
                                                            <th>
                                                                Label/Título
                                                            </th>
                                                            <th>Obrigatório</th>
                                                            <th
                                                                class="text-right"
                                                            >
                                                                Ações
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <draggable
                                                        v-model="form.fields"
                                                        tag="tbody"
                                                        item-key="position"
                                                        v-bind="dragOptions"
                                                        handle=".row-handler"
                                                        @start="drag = true"
                                                        @end="drag = false"
                                                        @change="
                                                            updateFieldsPosition()
                                                        "
                                                    >
                                                        <template
                                                            #item="{
                                                                element,
                                                                index
                                                            }"
                                                        >
                                                            <tr
                                                                :class="
                                                                    element.type ==
                                                                    'separator'
                                                                        ? 'row-element-separator'
                                                                        : ''
                                                                "
                                                            >
                                                                <td>
                                                                    <i
                                                                        class="ti ti-grip-vertical row-handler"
                                                                    ></i>
                                                                </td>
                                                                <td>
                                                                    <select
                                                                        v-model="
                                                                            element.type
                                                                        "
                                                                        class="form-select form-select-sm fieldTypeSelect"
                                                                        @change="
                                                                            handleFieldTypeChange(
                                                                                element
                                                                            )
                                                                        "
                                                                    >
                                                                        <option
                                                                            value="text"
                                                                        >
                                                                            Texto
                                                                            simples
                                                                        </option>
                                                                        <option
                                                                            value="textarea"
                                                                        >
                                                                            Texto
                                                                            longo
                                                                        </option>
                                                                        <option
                                                                            value="date"
                                                                        >
                                                                            Data
                                                                        </option>
                                                                        <option
                                                                            value="datetime"
                                                                        >
                                                                            Data
                                                                            e
                                                                            hora
                                                                        </option>
                                                                        <option
                                                                            value="checkbox"
                                                                        >
                                                                            Caixa
                                                                            de
                                                                            checagem
                                                                            (Checkbox)
                                                                        </option>
                                                                        <option
                                                                            value="select"
                                                                        >
                                                                            Opções
                                                                            de
                                                                            seleção
                                                                            (Dropdown)
                                                                        </option>
                                                                        <option
                                                                            value="radio"
                                                                        >
                                                                            Opções
                                                                            de
                                                                            seleção
                                                                            (Radio)
                                                                        </option>
                                                                        <option
                                                                            value="multiple_checkbox"
                                                                        >
                                                                            Múltipla
                                                                            seleção
                                                                            (Checkbox)
                                                                        </option>
                                                                        <option
                                                                            value="multiple_select"
                                                                        >
                                                                            Múltipla
                                                                            seleção
                                                                            (Dropdown)
                                                                        </option>
                                                                        <option
                                                                            value="upload"
                                                                        >
                                                                            Upload
                                                                            de
                                                                            arquivos
                                                                        </option>
                                                                        <option
                                                                            value="hidden"
                                                                        >
                                                                            Texto
                                                                            oculto
                                                                        </option>
                                                                        <option
                                                                            value="separator"
                                                                        >
                                                                            Separador/Título
                                                                        </option>
                                                                        <option
                                                                            value="table"
                                                                        >
                                                                            Tabela
                                                                        </option>
                                                                    </select>
                                                                </td>
                                                                <td
                                                                    v-if="
                                                                        element.type !==
                                                                        'separator'
                                                                    "
                                                                >
                                                                    <input
                                                                        v-model="
                                                                            element.name
                                                                        "
                                                                        type="text"
                                                                        name="name"
                                                                        class="form-control form-control-sm fieldNameInput"
                                                                        placeholder="field"
                                                                    />
                                                                </td>
                                                                <td
                                                                    v-if="
                                                                        element.type !==
                                                                        'separator'
                                                                    "
                                                                >
                                                                    <input
                                                                        v-model="
                                                                            element.label
                                                                        "
                                                                        type="text"
                                                                        class="form-control form-control-sm fieldLabelInput"
                                                                        placeholder="Label do campo"
                                                                    />
                                                                </td>
                                                                <td
                                                                    v-else
                                                                    colspan="3"
                                                                >
                                                                    <input
                                                                        v-model="
                                                                            element.label
                                                                        "
                                                                        type="text"
                                                                        class="form-control form-control-sm fieldLabelInput"
                                                                        placeholder="Label do campo"
                                                                    />
                                                                </td>
                                                                <td
                                                                    v-if="
                                                                        element.type !==
                                                                        'separator'
                                                                    "
                                                                >
                                                                    <input
                                                                        v-model="
                                                                            element.is_required
                                                                        "
                                                                        type="checkbox"
                                                                        class="form-check-input fieldRequiredCheckbox"
                                                                        :disabled="
                                                                            element.type ==
                                                                                'separator' ||
                                                                            element.type ==
                                                                                'table'
                                                                        "
                                                                    />
                                                                </td>
                                                                <td
                                                                    class="text-right"
                                                                >
                                                                    <button
                                                                        class="btn btn-sm optionsButton"
                                                                        :disabled="
                                                                            element.type ==
                                                                            'separator'
                                                                        "
                                                                        @click="
                                                                            editOptions(
                                                                                element
                                                                            )
                                                                        "
                                                                    >
                                                                        <i
                                                                            class="ti ti-list"
                                                                        ></i>
                                                                        Opções
                                                                    </button>
                                                                    <span
                                                                        >&nbsp;</span
                                                                    >
                                                                    <button
                                                                        class="btn btn-sm duplicateButton"
                                                                        @click="
                                                                            duplicateField(
                                                                                index
                                                                            )
                                                                        "
                                                                    >
                                                                        <i
                                                                            class="ti ti-copy"
                                                                        ></i>
                                                                        Duplicar
                                                                    </button>
                                                                    <span
                                                                        >&nbsp;</span
                                                                    >
                                                                    <button
                                                                        class="btn btn-sm btn-ghost-red removeButton"
                                                                        @click="
                                                                            removeField(
                                                                                index
                                                                            )
                                                                        "
                                                                    >
                                                                        <i
                                                                            class="ti ti-trash"
                                                                        ></i>
                                                                        Remover
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        </template>
                                                    </draggable>
                                                </table>

                                                <button
                                                    id="addFieldButton"
                                                    class="btn text-blue"
                                                    @click="addField()"
                                                >
                                                    <i class="ti ti-plus"></i>
                                                    Adicionar campo
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        id="tabs-view-config"
                                        class="tab-pane"
                                        role="tabpanel"
                                    >
                                        <!-- <div class="col-4 mb-3">
                                            <label class="form-label">Tipo de formulário <em>*</em></label>
                                            <select class="form-select" v-model="form.type">
                                                <option value="simple">Formulário simples</option>
                                                <option value="wizard">Wizard</option>
                                            </select>
                                        </div> -->
                                        <div class="row mb-3">
                                            <div class="col">
                                                <label
                                                    class="form-label required"
                                                    >Título de exibição do
                                                    formulário</label
                                                >
                                                <input
                                                    id="displayTitleInput"
                                                    v-model="form.title"
                                                    type="text"
                                                    class="form-control"
                                                    placeholder="Exemplo: Abra seu chamado"
                                                />
                                                <div class="form-hint">
                                                    <i
                                                        class="ti ti-info-circle"
                                                    ></i>
                                                    Visível aos usuários
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row mb-3">
                                            <div class="col">
                                                <label
                                                    class="form-label required"
                                                    >Mensagem de exibição do
                                                    formulário</label
                                                >
                                                <textarea
                                                    id="displayMessageInput"
                                                    v-model="form.message"
                                                    class="form-control"
                                                    placeholder="Exemplo: Preencha o formulário abaixo para abrir um chamado de suporte"
                                                ></textarea>
                                                <div class="form-hint">
                                                    <i
                                                        class="ti ti-info-circle"
                                                    ></i>
                                                    Visível aos usuários
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row mb-3">
                                            <div class="col">
                                                <label
                                                    class="form-label required"
                                                    >Mensagem de sucesso após
                                                    envio</label
                                                >
                                                <textarea
                                                    id="successMessageInput"
                                                    v-model="
                                                        form.success_message
                                                    "
                                                    class="form-control"
                                                    placeholder="Exemplo: Formulário enviado com sucesso!"
                                                ></textarea>
                                                <div class="form-hint">
                                                    <i
                                                        class="ti ti-info-circle"
                                                    ></i>
                                                    Visível aos usuários
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row mb-3">
                                            <div class="col">
                                                <label class="form-check">
                                                    <input
                                                        id="showBackButtonCheckbox"
                                                        v-model="
                                                            form.show_back_button
                                                        "
                                                        class="form-check-input"
                                                        type="checkbox"
                                                    />
                                                    <span
                                                        class="form-check-label"
                                                    >
                                                        Exibir botão para novo
                                                        envio após a
                                                        conclusão</span
                                                    >
                                                </label>
                                            </div>
                                        </div>
                                        <div class="row mb-3">
                                            <div class="col">
                                                <label class="form-check">
                                                    <input
                                                        id="waitFlowResultCheckbox"
                                                        v-model="
                                                            form.wait_flow_result
                                                        "
                                                        class="form-check-input"
                                                        type="checkbox"
                                                    />
                                                    <span
                                                        class="form-check-label"
                                                    >
                                                        Aguardar por resultado
                                                        da execução do Flow e
                                                        exibir ao usuário</span
                                                    >
                                                </label>
                                            </div>
                                        </div>
                                        <div class="col-4 mb-3">
                                            <label class="form-check">
                                                <input
                                                    v-model="
                                                        form.require_auth_pin
                                                    "
                                                    class="form-check-input"
                                                    type="checkbox"
                                                />
                                                <span class="form-check-label">
                                                    Exigir autenticação por PIN
                                                    para acessar o
                                                    formulário</span
                                                >
                                            </label>
                                        </div>
                                        <div
                                            v-if="form.require_auth_pin"
                                            class="row mb-3"
                                        >
                                            <div class="col">
                                                <label
                                                    class="form-label required"
                                                    >PIN de autenticação</label
                                                >
                                                <input
                                                    v-model="form.auth_pin"
                                                    type="text"
                                                    maxlength="6"
                                                    class="form-control"
                                                    placeholder="Exemplo: ABC123"
                                                />
                                                <div class="form-hint">
                                                    <i
                                                        class="ti ti-info-circle"
                                                    ></i>
                                                    Use apenas números e letras.
                                                    Máximo de 6 caracteres.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="card-footer">
                            <div class="row">
                                <div class="col">
                                    <button
                                        id="saveFormButton"
                                        class="btn btn-primary"
                                        @click="saveForm()"
                                    >
                                        <i class="ti ti-device-floppy"></i>
                                        Salvar
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div
            id="modal-edit-options"
            class="modal modal-blur fade"
            tabindex="-1"
            aria-modal="true"
            role="dialog"
        >
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">
                            Opções do campo
                            <span v-if="currentFieldEditOptions.label"
                                >"{{
                                    currentFieldEditOptions.label.trim()
                                }}"</span
                            >
                        </h5>
                        <button
                            type="button"
                            class="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        ></button>
                    </div>
                    <div class="modal-body pt-3">
                        <div class="modal-body-container">
                            <div
                                v-if="currentFieldEditOptions.type != 'table'"
                                class="row mb-3"
                            >
                                <div class="col-6">
                                    <label class="form-label"
                                        >Valor padrão</label
                                    >
                                    <input
                                        v-model="
                                            currentFieldEditOptions.default_value
                                        "
                                        type="text"
                                        class="form-control"
                                    />
                                </div>
                            </div>
                            <div
                                v-if="
                                    canSetUploadOptions(currentFieldEditOptions)
                                "
                                class="row mb-3"
                            >
                                <div class="col-6">
                                    <label class="form-check">
                                        <input
                                            v-model="
                                                currentFieldEditOptions.upload_multiple
                                            "
                                            class="form-check-input"
                                            type="checkbox"
                                        />
                                        <span class="form-check-label">
                                            Permitir múltiplos arquivos</span
                                        >
                                    </label>
                                </div>
                            </div>
                            <div
                                v-if="
                                    canSetUploadOptions(currentFieldEditOptions)
                                "
                                class="row mb-3"
                            >
                                <div class="col">
                                    <label class="form-label"
                                        >Extensões permitidas</label
                                    >
                                    <input
                                        v-model="
                                            currentFieldEditOptions.upload_extensions
                                        "
                                        type="text"
                                        class="form-control"
                                    />
                                    <div class="form-hint">
                                        Valores separados por vírgula. Exemplo:
                                        <code>pdf,docx</code>
                                    </div>
                                </div>
                            </div>
                            <div
                                v-if="
                                    canSetUploadOptions(currentFieldEditOptions)
                                "
                                class="row mb-3"
                            >
                                <div class="col">
                                    <label class="form-label required"
                                        >Tamanho máximo dos arquivos (Mb)</label
                                    >
                                    <div class="row mb-1">
                                        <div class="col-3">
                                            <input
                                                v-model="
                                                    currentFieldEditOptions.upload_maxsize
                                                "
                                                type="number"
                                                class="form-control"
                                            />
                                        </div>
                                    </div>
                                    <div class="form-hint">
                                        Máximo de <code>100 Mb</code> permitido
                                    </div>
                                </div>
                            </div>
                            <div
                                v-if="
                                    canApplyValidation(currentFieldEditOptions)
                                "
                                class="row mb-3"
                            >
                                <div class="col-6">
                                    <label class="form-label"
                                        >Regra de validação</label
                                    >
                                    <select
                                        v-model="
                                            currentFieldEditOptions.validation
                                        "
                                        class="form-select"
                                    >
                                        <option value="">Nenhuma</option>
                                        <option value="email">E-mail</option>
                                        <option value="cpf">CPF</option>
                                        <option value="cnpj">CNPJ</option>
                                        <option value="telefone_br">
                                            Telefone (BR)
                                        </option>
                                        <option value="celular_br">
                                            Celular (BR)
                                        </option>
                                        <option value="date_br">
                                            Data (BR)
                                        </option>
                                        <option value="datetime_br">
                                            Data e horário (BR)
                                        </option>
                                        <option value="time">
                                            Horário/Duração de tempo
                                        </option>
                                        <option value="cep">CEP</option>
                                        <option value="url">URL</option>
                                        <option value="number">Número</option>
                                    </select>
                                </div>
                            </div>
                            <div
                                v-if="canApplyMask(currentFieldEditOptions)"
                                class="row mb-3"
                            >
                                <div class="col-6">
                                    <label class="form-label"
                                        >Aplicar máscara de digitação?</label
                                    >
                                    <select
                                        v-model="currentFieldEditOptions.mask"
                                        class="form-select"
                                    >
                                        <option value="">Não</option>
                                        <option value="000.000.000-00">
                                            CPF
                                        </option>
                                        <option value="00.000.000/0000-00">
                                            CNPJ
                                        </option>
                                        <option value="(00) 0000-0000">
                                            Telefone (BR)
                                        </option>
                                        <option value="(00) 00000-0000">
                                            Celular (BR)
                                        </option>
                                        <option value="00/00/0000">
                                            Data (BR)
                                        </option>
                                        <option value="00/00/0000 00:00">
                                            Data e hora (BR)
                                        </option>
                                        <option value="00:00:00">
                                            Hora, minuto e segundo (BR)
                                        </option>
                                        <option value="00000-000">
                                            CEP (BR)
                                        </option>
                                        <option value="#.##0,00__reverse">
                                            Preço (BRL)
                                        </option>
                                        <option value="#,##0.00__reverse">
                                            Preço (USD)
                                        </option>
                                        <option value="custom">
                                            Personalizada
                                        </option>
                                    </select>
                                </div>
                                <div
                                    v-if="
                                        currentFieldEditOptions.mask == 'custom'
                                    "
                                    class="col-6"
                                >
                                    <label class="form-label required"
                                        >Máscara personalizada</label
                                    >
                                    <input
                                        v-model="
                                            currentFieldEditOptions.mask_custom
                                        "
                                        type="text"
                                        class="form-control"
                                        placeholder="Exemplo: 000.000.000"
                                    />
                                    <div class="form-hint">
                                        Use <code>0</code> para números,
                                        <code>S</code> para letras,
                                        <code>A</code> para alfanuméricos
                                        (letras ou números) e
                                        <code>#</code> para números variáveis
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-3">
                                <div class="col-6">
                                    <label class="form-label"
                                        >Largura do componente (%)</label
                                    >
                                    <input
                                        v-model="
                                            currentFieldEditOptions.width_percent
                                        "
                                        type="number"
                                        class="form-control"
                                        min="1"
                                        max="100"
                                        @change="
                                            handleWidthChange(
                                                currentFieldEditOptions
                                            )
                                        "
                                    />
                                </div>
                            </div>
                            <div v-if="canSetOptions(currentFieldEditOptions)">
                                <h4>
                                    {{
                                        currentFieldEditOptions.type == "table"
                                            ? "Colunas da tabela visíveis para o usuário"
                                            : "Opções para seleção pelo usuário"
                                    }}
                                </h4>

                                <div class="row mb-3">
                                    <div class="col-6">
                                        <label
                                            v-if="
                                                currentFieldEditOptions.type !=
                                                'table'
                                            "
                                            class="form-label"
                                            >{{
                                                currentFieldEditOptions.type ==
                                                "table"
                                                    ? "Fonte de dados das colunas"
                                                    : "Fonte de dados das opções"
                                            }}</label
                                        >

                                        <select
                                            v-model="
                                                currentFieldEditOptions.options_source_type
                                            "
                                            class="form-select"
                                        >
                                            <option value="">
                                                Inserção manual
                                            </option>
                                            <option value="floui_database">
                                                Floui Database
                                            </option>
                                            <!-- <option value="flow_run_output">Saída de execução de Flow</option> -->
                                        </select>
                                    </div>
                                </div>

                                <div
                                    v-if="
                                        !currentFieldEditOptions.options_source_type
                                    "
                                >
                                    <table class="table table-hovered">
                                        <thead>
                                            <tr>
                                                <th>Label</th>
                                                <th>Valor</th>
                                                <th
                                                    v-if="
                                                        currentFieldEditOptions.type ==
                                                        'table'
                                                    "
                                                >
                                                    Obrigatório
                                                </th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr
                                                v-for="(
                                                    option, index
                                                ) in currentFieldOptions"
                                                :key="option"
                                            >
                                                <td>
                                                    <input
                                                        v-model="option.label"
                                                        class="form-control form-control-sm"
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        v-model="option.value"
                                                        class="form-control form-control-sm"
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        v-if="
                                                            currentFieldEditOptions.type ==
                                                            'table'
                                                        "
                                                        v-model="
                                                            option.is_required
                                                        "
                                                        class="form-check-input"
                                                        type="checkbox"
                                                    />
                                                </td>
                                                <td>
                                                    <button
                                                        class="btn btn-sm btn-ghost-red"
                                                        @click="
                                                            removeFieldOption(
                                                                index
                                                            )
                                                        "
                                                    >
                                                        <i
                                                            class="ti ti-trash"
                                                        ></i>
                                                    </button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <button
                                        class="btn btn-sm"
                                        @click="addFieldOption()"
                                    >
                                        <i class="ti ti-plus"></i>
                                        Adicionar opção
                                    </button>
                                </div>

                                <div
                                    v-if="
                                        currentFieldEditOptions.options_source_type ==
                                        'floui_database'
                                    "
                                >
                                    <div class="row mb-3">
                                        <div class="col">
                                            <label class="form-label required"
                                                >Tabela</label
                                            >
                                            <select
                                                v-model="
                                                    currentFieldEditOptions.options_source_table
                                                "
                                                class="form-select"
                                                @change="
                                                    populateTableFieldsOptions(
                                                        currentFieldEditOptions
                                                    )
                                                "
                                            >
                                                <option
                                                    v-for="table in database_tables"
                                                    :key="table._id"
                                                    :value="table._id"
                                                >
                                                    {{ table.name }}
                                                </option>
                                            </select>
                                            <div class="form-hint">
                                                {{
                                                    currentFieldEditOptions.type ==
                                                    "table"
                                                        ? "Valor, label e obrigatoriedade dos campos serão definidos da tabela selecionada"
                                                        : "No máximo 100 registros serão exibidos para seleção ao usuário"
                                                }}
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        v-if="
                                            currentFieldEditOptions.type !=
                                            'table'
                                        "
                                        class="row mb-3"
                                    >
                                        <div class="col">
                                            <label class="form-label required"
                                                >Campo da tabela que será
                                                manipulado pelo fluxo</label
                                            >

                                            <select
                                                v-model="
                                                    currentFieldEditOptions.options_source_value_field
                                                "
                                                default="_id"
                                                class="form-select"
                                            >
                                                <option
                                                    v-for="column in currentFieldTableParamColumns"
                                                    :key="column.name"
                                                    :value="column.name"
                                                >
                                                    {{ column.label }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <div
                                        v-if="
                                            currentFieldEditOptions.type !=
                                            'table'
                                        "
                                        class="row mb-3"
                                    >
                                        <div class="col">
                                            <label class="form-label required"
                                                >Campo da tabela que será
                                                exibido ao usuário no
                                                formulário</label
                                            >

                                            <select
                                                v-model="
                                                    currentFieldEditOptions.options_source_label_field
                                                "
                                                class="form-select"
                                            >
                                                <option
                                                    v-for="column in currentFieldTableParamColumns"
                                                    :key="column.name"
                                                    :value="column.name"
                                                >
                                                    {{ column.label }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import draggable from "vuedraggable"
import Api from "../services/api"
import EventBus from "../services/event-bus"
import { store } from "../store"
import { limitGroupName, parseValidGroup } from "@/services/utils"

export default {
    name: "FormEditPage",
    components: {
        draggable
    },
    data() {
        return {
            drag: true,
            isNew: false,
            workspace: {},
            table: {},
            form: {
                status: "draft",
                fields: [],
                success_message: "O formulário foi enviado com sucesso!",
                show_back_button: true,
                group: ""
            },
            modalEditOptions: null,
            currentFieldEditOptions: {},
            currentFieldOptions: [],
            currentFieldTableParamColumns: [],
            database_tables: []
        }
    },
    computed: {
        dragOptions() {
            return {
                animation: 200,
                disabled: false,
                ghostClass: "ghost"
            }
        }
    },
    async mounted() {
        store.showSidebar = true
        store.showBackButton = true
        this.workspace = store.workspace

        var groupsResponse = await Api.groups.list(1, 100)
        this.groupsList = groupsResponse.data.items.map((group) =>
            limitGroupName(group)
        )

        const selectedGroup = parseValidGroup(
            localStorage.getItem("selectedGroup"),
            this.groupsList
        )

        if (this.$route.params.id) {
            var response = await Api.forms.get(this.$route.params.id)
            this.form = response.data

            const parsedGroup = parseValidGroup(
                this.form.group,
                this.groupsList
            )
            this.form.group = parsedGroup
        } else {
            this.isNew = true
            this.form.group = selectedGroup
        }

        this.modalEditOptions = new bootstrap.Modal(
            document.getElementById("modal-edit-options"),
            {
                keyboard: false
            }
        )

        this.loadDatabaseTables()
    },
    methods: {
        async saveForm() {
            try {
                this.validate()

                var response

                if (this.isNew) {
                    response = await Api.forms.create({
                        ...this.form,
                        group: this.form.group || null
                    })
                } else {
                    response = await Api.forms.update(this.form._id, {
                        ...this.form,
                        group: this.form.group || null
                    })
                }

                EventBus.emit("message", {
                    type: "success",
                    message: "Form salvo com sucesso!"
                })

                if (response.data._id && this.isNew) {
                    this.$router.push("/forms/edit/" + response.data._id)
                }
            } catch (error) {
                var message = error.message

                if (
                    error.response &&
                    error.response.data &&
                    error.response.data.error
                ) {
                    message = error.response.data.error
                }

                EventBus.emit("message", {
                    type: "danger",
                    message: "Ocorreu um erro ao salvar: " + message
                })
            }
        },
        updateFieldsPosition() {
            // update fields position based on their index on array
            this.form.fields.forEach((field, index) => {
                field.position = index
            })
        },
        addField() {
            var newPosition = this.form.fields.length + 1
            this.form.fields.push({
                name: "",
                label: "",
                type: "text",
                default_value: "",
                is_required: false,
                mask: "",
                mask_custom: "",
                validation: "",
                upload_multiple: false,
                upload_extensions: "",
                upload_maxsize: 10,
                options: [],
                options_source_type: "",
                options_source_table: "",
                options_source_value_field: "_id",
                options_source_label_field: "",
                position: newPosition,
                width_percent: 100
            })

            // set focus on the first input of new line
            setTimeout(() => {
                var inputs = document.querySelectorAll('input[name="name"]')
                inputs[inputs.length - 1].focus()
            }, 50)
        },
        removeField(index) {
            this.form.fields.splice(index, 1)
            this.updateFieldsPosition()
        },
        duplicateField(index) {
            var field = this.form.fields[index]
            this.form.fields.splice(
                index + 1,
                0,
                JSON.parse(JSON.stringify(field))
            )
            this.updateFieldsPosition()
        },

        loadDatabaseTables() {
            Api.database.tables.listAll().then((response) => {
                this.database_tables = response.data.items
            })
        },

        canSetOptions(field) {
            return (
                [
                    "select",
                    "radio",
                    "multiple_checkbox",
                    "multiple_select",
                    "table"
                ].indexOf(field.type) >= 0
            )
        },

        canApplyValidation(field) {
            return ["text", "textarea"].indexOf(field.type) >= 0
        },

        canApplyMask(field) {
            return ["text"].indexOf(field.type) >= 0
        },

        canSetUploadOptions(field) {
            return ["upload"].indexOf(field.type) >= 0
        },

        editOptions(field) {
            this.currentFieldEditOptions = field
            this.currentFieldOptions = field.options

            if (this.canSetOptions(field)) {
                this.populateTableFieldsOptions(field)
            }

            this.modalEditOptions.show()
        },
        addFieldOption() {
            this.currentFieldOptions.push({
                label: "",
                value: ""
            })
        },
        removeFieldOption(index) {
            this.currentFieldOptions.splice(index, 1)
        },

        validate() {
            if (!this.form.name) {
                throw new Error("O nome do form é obrigatório")
            }

            if (this.form.fields.length == 0) {
                throw new Error("É necessário adicionar pelo menos um campo")
            }

            const uniqueFields = new Set()

            this.form.fields.forEach((field, index) => {
                if (!field.name && field.type != "separator") {
                    throw new Error(
                        "O código do campo " + (index + 1) + " é obrigatório"
                    )
                }

                if (uniqueFields.has(field.name)) {
                    throw new Error(
                        "O código do campo " +
                            (index + 1) +
                            " já foi utilizado em outro campo"
                    )
                }

                uniqueFields.add(field.name)

                if (!field.label && field.type != "separator") {
                    throw new Error(
                        "O label do campo " + (index + 1) + " é obrigatório"
                    )
                }

                if (this.canSetOptions(field)) {
                    // manual options
                    if (field.options_source_type == "") {
                        if (field.options.length == 0) {
                            throw new Error(
                                "É necessário adicionar pelo menos uma opção para o campo " +
                                    (index + 1)
                            )
                        }

                        field.options.forEach((option, optionIndex) => {
                            if (!option.label) {
                                throw new Error(
                                    "O label da opção " +
                                        (optionIndex + 1) +
                                        " do campo " +
                                        (index + 1) +
                                        " é obrigatório"
                                )
                            }

                            if (!option.value) {
                                throw new Error(
                                    "O valor da opção " +
                                        (optionIndex + 1) +
                                        " do campo " +
                                        (index + 1) +
                                        " é obrigatório"
                                )
                            }
                        })
                    }

                    // floui database options
                    if (field.options_source_type == "floui_database") {
                        if (!field.options_source_table) {
                            throw new Error(
                                "É necessário selecionar uma tabela para o campo " +
                                    (index + 1)
                            )
                        }

                        if (
                            !field.options_source_value_field &&
                            field.type != "table"
                        ) {
                            throw new Error(
                                "É necessário adicionar uma coluna para ser usada como valor da opção. Acesse as opções do campo " +
                                    (index + 1)
                            )
                        }

                        if (
                            !field.options_source_label_field &&
                            field.type != "table"
                        ) {
                            throw new Error(
                                "É necessário adicionar uma coluna para ser usada como label da opção. Acesse as opções do campo " +
                                    (index + 1)
                            )
                        }
                    }
                }
            })
        },

        exportForm() {
            var data = JSON.parse(JSON.stringify(this.form))

            delete data.__v
            delete data._id
            delete data.group

            var dataStr =
                "data:text/json;charset=utf-8," +
                encodeURIComponent(JSON.stringify(data))
            var downloadAnchorNode = document.createElement("a")
            downloadAnchorNode.setAttribute("href", dataStr)
            downloadAnchorNode.setAttribute(
                "download",
                this.slugify(this.form.name) + ".form.json"
            )
            document.body.appendChild(downloadAnchorNode) // required for firefox
            downloadAnchorNode.click()
            downloadAnchorNode.remove()
        },

        importForm() {
            if (this.form._id) {
                if (
                    !confirm(
                        "Ao importar você substituirá o form atual. Deseja continuar?"
                    )
                ) {
                    return
                }
            }

            var input = document.createElement("input")
            input.type = "file"
            input.accept = ".json"

            input.onchange = async (e) => {
                var file = e.target.files[0]
                var reader = new FileReader()
                reader.onload = async (e) => {
                    // TODO: validar se o arquivo é um form válido (JSON Schema)

                    var data = JSON.parse(e.target.result)
                    var currentId = this.form._id

                    this.form = data
                    this.form._id = currentId
                    this.form.group = ""
                }
                reader.readAsText(file)
            }
            input.click()
        },

        slugify(str) {
            return str
                .toLowerCase()
                .replace(/ /g, "-")
                .replace(/[^\w-]+/g, "")
        },

        populateTableFieldsOptions(fieldEditOptions) {
            const table = this.database_tables.find(
                (table) => table._id === fieldEditOptions.options_source_table
            )

            if (!table) {
                // Remove todas as colunas atuais
                this.currentFieldTableParamColumns = []
                return
            }

            const tableColumns = table.fields.map((field) => {
                return {
                    name: field.name,
                    label: field.label
                }
            })

            // Adiciona o campo ID na lista de colunas
            this.currentFieldTableParamColumns = [
                {
                    name: "_id",
                    label: "ID"
                },
                ...tableColumns
            ]

            // Verifica se os campos de valor e label são válidos
            const isValueFieldValid = this.currentFieldTableParamColumns.find(
                (column) =>
                    column.name === fieldEditOptions.options_source_value_field
            )

            const isLabelFieldValid = this.currentFieldTableParamColumns.find(
                (column) =>
                    column.name === fieldEditOptions.options_source_label_field
            )

            // Se o valor do campo não existe na tabela selecionada, seta o valor padrão para _id
            if (!isValueFieldValid) {
                fieldEditOptions.options_source_value_field = "_id"
            }

            // Se o label do campo não existe na tabela selecionada, seta o valor padrão para _id
            if (!isLabelFieldValid) {
                fieldEditOptions.options_source_label_field = ""
            }
        },

        // Valida a largura do campo
        handleWidthChange(fieldEditOptions) {
            if (fieldEditOptions.width_percent < 1) {
                fieldEditOptions.width_percent = 1
            }

            if (fieldEditOptions.width_percent > 100) {
                fieldEditOptions.width_percent = 100
            }
        },

        // Valida o tipo do campo
        handleFieldTypeChange(field) {
            if (field.type == "separator" || field.type == "table") {
                field.is_required = false
            }
        }
    }
}
</script>

<style scoped>
table tr.row-element-separator td {
    /* azul claro */
    background: #f2f4f7;
}

table tr.row-element-separator td input {
    font-weight: bold;
}

.text-right {
    text-align: right;
}
</style>
