<template>
    <div>
        <div class="card">
            <div class="card-header">
                <div class="col-auto">
                    <h3 class="card-title">
                        <i class="ti ti-database"></i>
                        {{ table.name }}
                    </h3>
                    <small class="text-muted"
                        >Total de {{ total }} registro(s)</small
                    >
                </div>
                <div class="col-auto mx-4">
                    <a
                        id="newEntityButton"
                        :href="'/database/entities/new/' + table._id"
                        class="btn btn-blue"
                    >
                        <i class="ti ti-plus"></i>
                        Adicionar registro
                    </a>
                </div>
                <div class="col align-right">
                    <a
                        id="exportCsvButton"
                        href="#"
                        class="btn"
                        @click="exportEntities()"
                    >
                        <i class="ti ti-download"></i>
                        Exportar CSV
                    </a>
                    <span class="mx-1" />
                    <a
                        id="editStructureButton"
                        :href="'/database/tables/edit/' + table._id"
                        class="btn"
                    >
                        <i class="ti ti-pencil"></i>
                        Editar estrutura
                    </a>
                    <span class="mx-1" />
                    <a
                        id="toggleFiltersButton"
                        href="#"
                        class="btn"
                        @click="toggleFilters()"
                    >
                        <i class="ti ti-filter"></i>
                    </a>
                </div>
            </div>

            <div class="card-body card-filter d-none">
                <div class="row">
                    <div class="col-2 mb-3">
                        <label class="form-label">ID</label>
                        <input
                            id="idFilterInput"
                            v-model="filter._id"
                            type="text"
                            class="form-control"
                            placeholder="String"
                            @keyup="verifySubmitFilter"
                        />
                    </div>
                    <div
                        v-for="field in table.fields"
                        :key="field.name"
                        class="col-2 mb-3"
                    >
                        <label class="form-label">{{ field.label }}</label>

                        <input
                            v-if="field.type === 'integer'"
                            v-model="filter[field.name]"
                            type="text"
                            class="form-control fieldFilter"
                            placeholder="Integer"
                            @keyup="verifySubmitFilter"
                        />
                        <input
                            v-if="field.type === 'float'"
                            v-model="filter[field.name]"
                            type="text"
                            class="form-control fieldFilter"
                            placeholder="Float"
                            @keyup="verifySubmitFilter"
                        />
                        <input
                            v-if="
                                field.type === 'string' ||
                                field.type === 'longtext'
                            "
                            v-model="filter[field.name]"
                            type="text"
                            class="form-control fieldFilter"
                            placeholder="String"
                            @keyup="verifySubmitFilter"
                        />

                        <select
                            v-if="field.type === 'boolean'"
                            v-model="filter[field.name]"
                            class="form-select fieldFilter"
                            @change="verifySubmitFilter"
                        >
                            <option value="">Selecione</option>
                            <option value="true">True</option>
                            <option value="false">False</option>
                        </select>

                        <div
                            v-if="field.type === 'date'"
                            class="input-group mb-1"
                        >
                            <div class="input-group-text">De&nbsp;</div>
                            <input
                                v-model="filter[field.name + '_from']"
                                type="date"
                                class="form-control fieldFilter"
                                @keyup="verifySubmitFilter"
                            />
                        </div>
                        <div v-if="field.type === 'date'" class="input-group">
                            <div class="input-group-text">Até</div>
                            <input
                                v-model="filter[field.name + '_to']"
                                type="date"
                                class="form-control fieldFilter"
                                @keyup="verifySubmitFilter"
                            />
                        </div>

                        <div
                            v-if="field.type === 'datetime'"
                            class="input-group mb-1"
                        >
                            <div class="input-group-text">De&nbsp;</div>
                            <input
                                v-model="filter[field.name + '_from']"
                                type="datetime-local"
                                step="1"
                                class="form-control fieldFilter"
                                @keyup="verifySubmitFilter"
                            />
                        </div>
                        <div
                            v-if="field.type === 'datetime'"
                            class="input-group"
                        >
                            <div class="input-group-text">Até</div>
                            <input
                                v-model="filter[field.name + '_to']"
                                type="datetime-local"
                                step="1"
                                class="form-control fieldFilter"
                                @keyup="verifySubmitFilter"
                            />
                        </div>
                    </div>

                    <div class="col-2 mb-3">
                        <label class="form-label">Criado em</label>
                        <div class="input-group mb-1">
                            <div class="input-group-text">De&nbsp;</div>
                            <input
                                v-model="filter['created_at_from']"
                                type="datetime-local"
                                step="1"
                                class="form-control fieldFilter"
                                @keyup="verifySubmitFilter"
                            />
                        </div>
                        <div class="input-group">
                            <div class="input-group-text">Até</div>
                            <input
                                v-model="filter['created_at_to']"
                                type="datetime-local"
                                step="1"
                                class="form-control fieldFilter"
                                @keyup="verifySubmitFilter"
                            />
                        </div>
                    </div>
                    <div class="col-2 mb-3">
                        <label class="form-label">Atualizado em</label>
                        <div class="input-group mb-1">
                            <div class="input-group-text">De&nbsp;</div>
                            <input
                                v-model="filter['updated_at_from']"
                                type="datetime-local"
                                step="1"
                                class="form-control fieldFilter"
                                @keyup="verifySubmitFilter"
                            />
                        </div>
                        <div class="input-group">
                            <div class="input-group-text">Até</div>
                            <input
                                v-model="filter['updated_at_to']"
                                type="datetime-local"
                                step="1"
                                class="form-control fieldFilter"
                                @keyup="verifySubmitFilter"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div class="card-body">
                <div class="row">
                    <div class="col-2">
                        <select
                            id="itemsByPageSelect"
                            v-model="showPerPage"
                            class="form-select"
                        >
                            <option value="10">10 itens por página</option>
                            <option value="20">20 itens por página</option>
                            >
                            <option value="50">50 itens por página</option>
                            <option value="100">100 itens por página</option>
                        </select>
                    </div>
                    <div class="col">
                        <a
                            class="btn dropdown-toggle"
                            href="#"
                            data-bs-toggle="dropdown"
                            data-bs-auto-close="outside"
                            role="button"
                            aria-expanded="true"
                        >
                            Colunas de exibição
                        </a>
                        <div class="dropdown-menu">
                            <label class="dropdown-item"
                                ><input
                                    v-model="columns._id"
                                    class="form-check-input m-0 me-2"
                                    type="checkbox"
                                />
                                ID</label
                            >
                            <label
                                v-for="field in table.fields"
                                :key="field.name"
                                class="dropdown-item"
                            >
                                <input
                                    v-model="columns[field.name]"
                                    class="form-check-input m-0 me-2 columnCheckbox"
                                    type="checkbox"
                                />
                                {{ field.label }}
                            </label>
                            <label class="dropdown-item"
                                ><input
                                    v-model="columns.created_at"
                                    class="form-check-input m-0 me-2"
                                    type="checkbox"
                                />
                                Criado em</label
                            >
                            <label class="dropdown-item"
                                ><input
                                    v-model="columns.updated_at"
                                    class="form-check-input m-0 me-2"
                                    type="checkbox"
                                />
                                Atualizado em</label
                            >
                        </div>
                    </div>
                    <div v-show="hasFiltersApplied" class="col-auto">
                        <button
                            id="cleanFilterButton"
                            class="btn btn-ghost-red"
                            @click="cleanFilters"
                        >
                            <i class="ti ti-eraser"></i> Limpar filtros
                        </button>
                    </div>
                    <div
                        v-show="hasFiltersApplied"
                        class="col-auto align-right"
                    >
                        <button
                            id="updateFilterButton"
                            class="btn btn-ghost-blue"
                            @click="updateFilter"
                        >
                            <i class="ti ti-filter"></i> Filtrar resultados
                        </button>
                    </div>
                </div>
            </div>

            <div class="card-body">
                <div class="table-responsive">
                    <table
                        class="table table-vcenter text-nowrap datatable table-db-entities table-hover"
                    >
                        <thead>
                            <tr class="labels">
                                <th width="40">
                                    <label class="form-check spacer-top">
                                        <input
                                            id="selectAllCheckbox"
                                            v-model="select_all"
                                            class="form-check-input"
                                            type="checkbox"
                                        />
                                    </label>
                                </th>
                                <th v-if="columns._id" width="80">ID</th>
                                <th
                                    v-for="field in visibleFields"
                                    :key="field.name"
                                >
                                    {{ field.label }}
                                </th>
                                <th v-if="columns.created_at" width="160">
                                    Criação
                                </th>
                                <th v-if="columns.updated_at" width="160">
                                    Atualização
                                </th>
                                <th width="100">Ações</th>
                            </tr>
                        </thead>
                        <tbody v-if="entityList.length">
                            <tr v-for="entity in entityList" :key="entity._id">
                                <td>
                                    <label class="form-check spacer-top">
                                        <input
                                            v-model="mass_selection"
                                            class="form-check-input massSelectionCheckbox"
                                            type="checkbox"
                                            name="mass_selection"
                                            :value="entity._id"
                                        />
                                    </label>
                                </td>
                                <td v-if="columns._id">{{ entity._id }}</td>
                                <td
                                    v-for="field in visibleFields"
                                    :key="field.name"
                                >
                                    <span v-if="field.type === 'date'">{{
                                        entity.data[field.name] != undefined
                                            ? formatDate(
                                                  entity.data[field.name]
                                              )
                                            : "-"
                                    }}</span>
                                    <span v-if="field.type === 'datetime'">{{
                                        entity.data[field.name] != undefined
                                            ? formatDatetime(
                                                  entity.data[field.name]
                                              )
                                            : "-"
                                    }}</span>
                                    <span v-if="field.type === 'string'">{{
                                        entity.data[field.name] != undefined
                                            ? entity.data[field.name]
                                            : "-"
                                    }}</span>
                                    <span v-if="field.type === 'longtext'">{{
                                        entity.data[field.name] != undefined
                                            ? truncateText(
                                                  entity.data[field.name]
                                              )
                                            : "-"
                                    }}</span>
                                    <span v-if="field.type === 'integer'">{{
                                        entity.data[field.name] != undefined
                                            ? entity.data[field.name]
                                            : "-"
                                    }}</span>
                                    <span v-if="field.type === 'float'">{{
                                        entity.data[field.name] != undefined
                                            ? entity.data[field.name]
                                            : "-"
                                    }}</span>
                                    <span v-if="field.type === 'boolean'">
                                        {{
                                            entity.data[field.name] !==
                                            undefined
                                                ? entity.data[field.name]
                                                    ? "True"
                                                    : "False"
                                                : "-"
                                        }}
                                    </span>
                                </td>
                                <td v-if="columns.created_at">
                                    {{ formatDatetime(entity.created_at) }}
                                </td>
                                <td v-if="columns.updated_at">
                                    {{ formatDatetime(entity.updated_at) }}
                                </td>
                                <td>
                                    <a
                                        class="editEntityButton"
                                        :href="
                                            '/database/entities/edit/' +
                                            entity._id
                                        "
                                    >
                                        <i class="ti ti-pencil"></i>
                                    </a>
                                    <a
                                        class="removeEntityButton"
                                        href="#"
                                        @click="removeEntity(entity)"
                                    >
                                        <i class="ti ti-trash"></i>
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                        <tbody v-if="!entityList.length">
                            <tr>
                                <td colspan="1000">
                                    <div class="empty">
                                        <div class="empty-title">
                                            Nenhum registro encontrado
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div class="card-footer">
                <div class="row">
                    <div class="col">
                        <span v-if="mass_selection.length" class="dropdown">
                            <button
                                id="massDeleteDropdown"
                                class="btn dropdown-toggle"
                                data-bs-toggle="dropdown"
                            >
                                Ações em massa
                            </button>
                            <div class="dropdown-menu">
                                <a
                                    id="massDeleteButton"
                                    href="#"
                                    class="dropdown-item"
                                    @click="massDelete()"
                                >
                                    <i class="ti ti-trash"></i>
                                    Remover {{ mass_selection.length }}
                                    {{
                                        mass_selection.length > 1
                                            ? "itens selecionados"
                                            : "item selecionado"
                                    }}
                                </a>
                            </div>
                        </span>
                    </div>
                    <div class="col-auto">
                        <Pagination
                            v-if="total"
                            :key="paginationKey"
                            :show-per-page="parseInt(showPerPage)"
                            :current-page="currentPage"
                            :total="total"
                            @page-change="updatePage"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from "moment"
import Api from "../services/api"
import Pagination from "./Pagination"

export default {
    name: "DbEntitiesList",
    components: {
        Pagination
    },
    props: {
        table: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            entityList: [],
            filter: {},
            columns: {
                _id: true,
                created_at: true,
                updated_at: true
            },
            paginationKey: 0,
            showPerPage: 20,
            currentPage: 1,
            total: 0,
            mass_selection: [],
            select_all: false,
            created_at_range: null,
            updated_at_range: null
        }
    },
    computed: {
        visibleFields() {
            return this.table.fields.filter((field) => this.columns[field.name])
        },
        hasFiltersApplied() {
            return Object.keys(this.filter).length > 2
        }
    },
    async mounted() {
        this.currentPage = parseInt(this.$route.query.page) || 1
        this.showPerPage = parseInt(this.$route.query.showPerPage) || 20
        this.filter.table = this.$route.params.table_id
        this.filter.timezoneOffset = moment().utcOffset()

        if (this.$route.query.filter) {
            this.filter = JSON.parse(this.$route.query.filter)
        }

        this.initColumns()
        this.loadTableEntities()

        this.$watch("select_all", function (val) {
            if (val) {
                this.mass_selection = this.entityList.map((item) => item._id)
            } else {
                this.mass_selection = []
            }
        })

        this.$watch("showPerPage", function (val) {
            this.updatePage(1)
        })
    },
    methods: {
        async loadTableEntities() {
            this.paginationKey = Math.random()

            const response = await Api.database.entities.list(
                this.currentPage,
                this.showPerPage,
                this.filter
            )

            this.entityList = response.data.items
            this.total = response.data.total
        },
        verifySubmitFilter(e, data) {
            if (e.keyCode == 13) {
                this.updateFilter()
            }
        },
        updateFilter() {
            this.$router.replace({
                query: {
                    page: 1,
                    showPerPage: this.showPerPage,
                    filter: JSON.stringify(this.filter)
                }
            })
        },
        cleanFilters() {
            this.filter = {}
            this.filter.table = this.$route.params.table_id
            this.filter.timezoneOffset = moment().utcOffset()

            this.updateFilter()
        },
        updatePage(page) {
            this.currentPage = page
            this.paginationKey = Math.random()

            this.$router.replace({
                query: {
                    page: page,
                    showPerPage: this.showPerPage,
                    filter: JSON.stringify(this.filter)
                }
            })

            this.loadTableEntities()
        },
        initColumns() {
            var selectedColumns = sessionStorage.getItem(
                "db_columns_" + this.table._id
            )

            if (!selectedColumns) {
                for (var i = 0; i < this.table.fields.length; i++) {
                    if (this.table.fields[i].type !== "longtext") {
                        this.columns[this.table.fields[i].name] = true
                    } else {
                        this.columns[this.table.fields[i].name] = false
                    }
                }
            } else {
                this.columns = JSON.parse(selectedColumns)
            }

            this.$watch(
                "columns",
                function (val) {
                    sessionStorage.setItem(
                        "db_columns_" + this.table._id,
                        JSON.stringify(val)
                    )
                },
                { deep: true }
            )
        },
        formatDate(date) {
            return moment(date).format("DD/MM/YYYY")
        },
        formatDatetime(date) {
            return moment(date).format("DD/MM/YYYY HH:mm:ss")
        },
        truncateText(string) {
            if (string.length > 100) {
                return string.substring(0, 100) + "..."
            }

            return string
        },
        toggleFilters: function () {
            document
                .getElementsByClassName("card-filter")[0]
                .classList.toggle("d-none")
        },
        async removeEntity(entity) {
            if (
                confirm(
                    "Tem certeza que deseja remover este item? Esta ação não poderá ser desfeita."
                )
            ) {
                const response = await Api.database.entities.delete(entity._id)

                if (response.data.success) {
                    this.loadTableEntities()
                }
            }
        },
        async massDelete() {
            if (
                confirm(
                    "Tem certeza que deseja remover os itens selecionados? Esta ação não poderá ser desfeita."
                )
            ) {
                const response = await Api.database.entities.massDelete(
                    this.mass_selection
                )

                if (response.data.success) {
                    this.mass_selection = []
                    this.select_all = false
                    this.loadTableEntities()
                }
            }
        },
        async exportEntities() {
            var columns = []
            Object.keys(this.columns).forEach((key) => {
                if (this.columns[key]) {
                    columns.push(key)
                }
            })
            const response = await Api.database.entities.exportCsv(
                this.table._id,
                this.filter,
                columns
            )

            // send data to download file
            var blob = new Blob([response.data.data], { type: "text/csv" })
            var link = document.createElement("a")
            link.href = window.URL.createObjectURL(blob)
            link.download = response.data.filename
            link.click()
        }
    }
}
</script>

<style scoped>
.card-filter {
    background: #f7f7f7;
}
.table-db-entities th {
    color: #000;
    text-transform: none;
    font-size: 12px;
    border: none;
    border-right: 1px solid #ddd;
}
.table-db-entities tr.labels th {
    background: #d2dae8;
}
.table-db-entities th .form-control {
    display: inline-block;
    width: auto;
}
.table-db-entities .icon-type {
    font-size: 16px;
    color: #000;
    position: relative;
    top: 2px;
    left: -5px;
}
</style>
