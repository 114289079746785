<template>
    <div>
        <div class="row">
            <div v-if="config" class="col">
                <div v-if="!config.html" class="text-muted"></div>
                <div class="widget-content" v-html="config.html"></div>
            </div>
        </div>

        <div v-if="editMode" class="widget-options">
            <i class="btn-icon ti ti-settings-filled" @click="showConfig()"></i>
            <i class="btn-icon ti ti-copy" @click="duplicate()"></i>
            <i
                class="btn-icon ti ti-trash text-red"
                @click="removeWidget()"
            ></i>
        </div>

        <div
            :id="'modal-widget-options-' + widget.id"
            class="modal modal-blur fade"
            tabindex="-1"
            aria-modal="true"
            role="dialog"
        >
            <div class="modal-dialog modal-xl" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Configuração do widget</h5>
                        <button
                            type="button"
                            class="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        ></button>
                    </div>
                    <div class="modal-body pt-2">
                        <QuillEditor
                            v-model:content="config.html"
                            theme="snow"
                            :toolbar="toolbarOptions"
                            :content-type="'html'"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { QuillEditor } from "@vueup/vue-quill"
import "@vueup/vue-quill/dist/vue-quill.snow.css"

export default {
    name: "TextHtmlWidget",
    components: {
        QuillEditor
    },
    props: {
        widget: Object,
        editMode: Boolean
    },
    data() {
        return {
            config: {},
            modalWidgetOptions: null,
            toolbarOptions: [
                [{ header: [1, 2, 3, 4, 5, 6, false] }],
                [{ size: ["small", false, "large", "huge"] }], // custom dropdown

                ["bold", "italic", "underline", "strike"], // toggled buttons
                ["blockquote", "code-block"],

                [{ list: "ordered" }, { list: "bullet" }],

                [{ color: [] }, { background: [] }], // dropdown with defaults from theme
                [{ font: [] }],
                [{ align: [] }],
                ["link", "image"],

                ["clean"] // remove formatting button
            ]
        }
    },
    mounted() {
        this.config = this.widget.config || {}

        this.modalWidgetOptions = new bootstrap.Modal(
            document.getElementById("modal-widget-options-" + this.widget.id),
            {
                keyboard: false
            }
        )

        this.$watch(
            "config",
            () => {
                this.$emit("updateConfig", this.widget, this.config)
            },
            { deep: true }
        )
    },
    methods: {
        showConfig() {
            this.modalWidgetOptions.show()
        },
        removeWidget() {
            this.$emit("removeWidget", this.widget)
        },
        updateConfig(widget, config) {
            this.$emit("updateConfig", widget, config)
        },
        duplicate() {
            this.$emit("duplicateWidget", this.widget)
        }
    }
}
</script>
