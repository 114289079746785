<template>
    <div>
        <div
            id="modal-restart-engine-form"
            class="modal modal-blur fade"
            tabindex="-1"
            style="display: none"
            aria-modal="true"
            role="dialog"
        >
            <div
                class="modal-dialog modal-lg modal-dialog-centered"
                role="document"
            >
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Reiniciar engine</h5>
                        <button
                            id="closeFormButton"
                            type="button"
                            class="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        ></button>
                    </div>

                    <div class="modal-body">
                        <p>
                            Tem certeza que deseja reiniciar a engine?<br />Todos
                            os fluxos em execução no momento serão
                            interrompidos!
                        </p>
                    </div>

                    <div class="modal-footer">
                        <button
                            id="cancelButton"
                            class="btn btn-link link-secondary"
                            data-bs-dismiss="modal"
                            @click="cancel()"
                        >
                            Cancelar
                        </button>
                        <button
                            id="restartButton"
                            class="btn btn-danger ms-auto"
                            data-bs-dismiss="modal"
                            @click="restart()"
                        >
                            <i class="ti ti-alert-triangle"></i>
                            Reiniciar
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "RestartEngineForm",
    emits: ["restart", "cancel"],
    methods: {
        cancel() {
            this.$emit("cancel")
        },
        restart() {
            this.$emit("restart")
        }
    }
}
</script>
