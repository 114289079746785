<template>
    <div
        v-if="store.workspace"
        id="navbar-menu"
        class="collapse navbar-collapse"
    >
        <div class="row">
            <div class="col mt-3">
                <HeaderLogo />
            </div>
        </div>
        <ul class="navbar-nav pt-lg-3">
            <li class="nav-item pt-3">
                <div class="nav-link strong text-yellow">
                    {{ store.workspace.name }}
                </div>
            </li>
            <li class="nav-item">
                <a
                    id="homeNavbarButton"
                    class="nav-link"
                    href="#"
                    @click="goToAccountPanel()"
                >
                    <span class="nav-link-icon d-md-none d-lg-inline-block">
                        <i class="ti ti-arrow-left"></i>
                    </span>
                    <span class="nav-link-title">Voltar ao início</span>
                </a>
            </li>
            <li class="nav-item pt-3">
                <div class="nav-link text-yellow">Construção</div>
            </li>
            <li class="nav-item">
                <a id="flowsNavbarButton" class="nav-link" href="/flows">
                    <span class="nav-link-icon d-md-none d-lg-inline-block">
                        <i class="ti ti-box"></i>
                    </span>
                    <span class="nav-link-title">Flows</span>
                </a>
            </li>

            <li class="nav-item">
                <a class="nav-link" href="/datasources" id="tablesNavbarButton">
                    <span class="nav-link-icon d-md-none d-lg-inline-block">
                        <i class="ti ti-database-export"></i>
                    </span>
                    <span class="nav-link-title">
                        Datasources
                    </span>
                </a>
            </li>
            <li class="nav-item">
                <a id="formsNavbarButton" class="nav-link" href="/forms">
                    <span class="nav-link-icon d-md-none d-lg-inline-block">
                        <i class="ti ti-forms"></i>
                    </span>
                    <span class="nav-link-title"> Forms </span>
                </a>
            </li>
            <li class="nav-item">
                <a id="queuesNavbarButton" class="nav-link" href="/queues">
                    <span class="nav-link-icon d-md-none d-lg-inline-block">
                        <i class="ti ti-list-check"></i>
                    </span>
                    <span class="nav-link-title"> Filas internas </span>
                </a>
            </li>
            <li class="nav-item">
                <a
                    id="customNodesNavbarButton"
                    class="nav-link"
                    href="/custom-nodes"
                >
                    <span class="nav-link-icon d-md-none d-lg-inline-block">
                        <i class="ti ti-box"></i>
                    </span>
                    <span class="nav-link-title">
                        Conectores personalizados
                    </span>
                </a>
            </li>
            <li class="nav-item pt-3">
                <div class="nav-link text-yellow">Armazenamento</div>
            </li>
            <li class="nav-item">
                <a
                    id="tablesNavbarButton"
                    class="nav-link"
                    href="/database/tables"
                >
                    <span class="nav-link-icon d-md-none d-lg-inline-block">
                        <i class="ti ti-database"></i>
                    </span>
                    <span class="nav-link-title"> Database </span>
                </a>
            </li>
            <li class="nav-item">
                <a id="variablesNavbarButton" class="nav-link" href="/storage">
                    <span class="nav-link-icon d-md-none d-lg-inline-block">
                        <i class="ti ti-files"></i>
                    </span>
                    <span class="nav-link-title">Storage</span>
                    <sup class="text-azure">beta</sup>
                </a>
            </li>

            <li class="nav-item pt-3">
                <div class="nav-link text-yellow">Monitoramento</div>
            </li>
            <li class="nav-item">
                <a
                    id="dashboardsNavbarButton"
                    class="nav-link"
                    href="/dashboards"
                >
                    <span class="nav-link-icon d-md-none d-lg-inline-block">
                        <i class="ti ti-chart-bar"></i>
                    </span>
                    <span class="nav-link-title">Dashboards</span>
                </a>
            </li>
            <li class="nav-item">
                <a
                    id="monitoringNavbarActiveButton"
                    class="nav-link"
                    href="/monitoring/active"
                >
                    <span class="nav-link-icon d-md-none d-lg-inline-block">
                        <i class="ti ti-activity"></i>
                    </span>
                    Execução em tempo real
                </a>
            </li>
            <li class="nav-item">
                <a
                    id="monitoringNavbarCompletedButton"
                    class="nav-link"
                    href="/monitoring/completed"
                >
                    <span class="nav-link-icon d-md-none d-lg-inline-block">
                        <i class="ti ti-history"></i>
                    </span>
                    Histórico de execuções
                </a>
            </li>
            <li class="nav-item">
                <a
                    id="monitoringNavbarLogsButton"
                    class="nav-link"
                    href="/monitoring"
                >
                    <span class="nav-link-icon d-md-none d-lg-inline-block">
                        <i class="ti ti-logs"></i>
                    </span>
                    Logs de monitoramento
                </a>
            </li>

            <li class="nav-item">
                <a class="nav-link" href="/alert-rules">
                    <span class="nav-link-icon d-md-none d-lg-inline-block">
                        <i class="ti ti-urgent"></i>
                    </span>
                    Regras de alerta
                </a>
            </li>

            <li class="nav-item pt-3">
                <div class="nav-link text-yellow">Configurações</div>
            </li>
            <li class="nav-item">
                <a
                    id="variablesNavbarButton"
                    class="nav-link"
                    href="/variables"
                >
                    <span class="nav-link-icon d-md-none d-lg-inline-block">
                        <i class="ti ti-code"></i>
                    </span>
                    <span class="nav-link-title">Variáveis</span>
                </a>
            </li>
            <li class="nav-item">
                <a
                    id="serviceAccountNavbarButton"
                    class="nav-link"
                    href="/service-accounts"
                >
                    <span class="nav-link-icon d-md-none d-lg-inline-block">
                        <i class="ti ti-key"></i>
                    </span>
                    <span class="nav-link-title">Contas de serviços</span>
                </a>
            </li>
            <li class="nav-item">
                <a id="groupsNavbarButton" class="nav-link" href="/groups">
                    <span class="nav-link-icon d-md-none d-lg-inline-block">
                        <i class="ti ti-folders"></i>
                    </span>
                    <span class="nav-link-title">Grupos</span>
                </a>
            </li>
            <!-- <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle" href="#monitoring" data-bs-toggle="dropdown"
                    data-bs-auto-close="false" role="button" aria-expanded="false" id="monitoringNavbarDropdownButton">
                    <span class="nav-link-icon d-md-none d-lg-inline-block">
                        <i class="ti ti-device-desktop-analytics"></i>
                    </span>
                    <span class="nav-link-title"> Monitoramento </span>
                </a>
                <div class="dropdown-menu" data-bs-popper="static">
                    <a class="dropdown-item" href="/monitoring/active" id="monitoringNavbarActiveButton">
                        Execução em tempo real
                    </a>
                    <a class="dropdown-item" href="/monitoring/completed" id="monitoringNavbarCompletedButton">
                        Flows executados
                    </a>
                    <a class="dropdown-item" href="/monitoring" id="monitoringNavbarLogsButton">
                        Logs de monitoramento
                    </a>
                    <a class="dropdown-item" href="/alert-rules">
                        Regras de alerta
                    </a>
                </div>
            </li> -->
        </ul>
        <div class="m-2">{{ workspaceVersion }}</div>
    </div>
</template>

<script>
import HeaderLogo from "@/components/HeaderLogo.vue"
import { store } from "@/store"

export default {
    name: "SidebarMenu",
    components: {
        HeaderLogo
    },

    data() {
        return {
            store: store,
            workspaceVersion: ""
        }
    },

    mounted() {
        this.workspaceVersion = process.env.VUE_APP_VERSION
    },

    methods: {
        goToAccountPanel() {
            window.location.href = store.accountPanelUrl
        }
    }
}
</script>
