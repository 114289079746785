<template>
    <div>
        <div v-if="canLoadPage" id="flow-editor-page-container" class="row">
            <div class="col">
                <div class="card mb-2 editor-edit-name">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-auto">
                                <a id="backButton" class="btn" @click="back">
                                    &larr; Voltar
                                </a>
                            </div>
                            <div class="col">
                                <div class="">
                                    <input
                                        id="flowNameInput"
                                        v-model="flow.name"
                                        type="text"
                                        class="form-control"
                                        maxlength="120"
                                        minlength="1"
                                        placeholder="Nome do flow"
                                        name="flow-name"
                                    />
                                </div>
                            </div>
                            <div class="col-auto">
                                <div class="input-group">
                                    <span class="input-group-text">
                                        <i class="ti ti-folders"></i>
                                    </span>
                                    <select
                                        v-model="flow.group"
                                        class="form-select"
                                    >
                                        <option value="">Sem grupo</option>
                                        <option
                                            v-for="group in groupsList"
                                            :key="group._id"
                                            :value="group._id"
                                        >
                                            {{ group.name }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-auto">
                                <label
                                    class="form-check form-switch spacer-top"
                                >
                                    <input
                                        id="flowEnabledCheckbox"
                                        v-model="flow.enabled"
                                        class="form-check-input"
                                        type="checkbox"
                                        @click="toggleBreakPoint()"
                                    />
                                    <span class="form-check-label">Ativo</span>
                                </label>
                            </div>
                            <div class="col-auto">
                                <FlowSaveModal
                                    @save="saveFlow"
                                    @save-snapshot="saveSnapshot"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card">
                    <div class="card-body">
                        <div class="row mb-3 editor-toolbar-top">
                            <div class="col">
                                <div class="row">
                                    <div class="col">
                                        <div class="btn-group" role="group">
                                            <div class="btn-group" role="group">
                                                <label
                                                    class="btn dropdown-toggle"
                                                    data-bs-toggle="dropdown"
                                                    aria-haspopup="true"
                                                    aria-expanded="false"
                                                >
                                                    <i
                                                        class="ti ti-template"
                                                    ></i
                                                    >&nbsp; Importar/Exportar
                                                </label>
                                                <div
                                                    class="dropdown-menu"
                                                    style=""
                                                >
                                                    <button
                                                        id="showTemplatesGalleryButton"
                                                        class="dropdown-item"
                                                        @click="
                                                            showTemplateGallery
                                                        "
                                                    >
                                                        <i
                                                            class="ti ti-template"
                                                        ></i>
                                                        Importar da galeria de
                                                        templates
                                                    </button>
                                                    <button
                                                        id="selectImportPositionButton"
                                                        class="dropdown-item"
                                                        @click="
                                                            selectImportPosition()
                                                        "
                                                    >
                                                        <i
                                                            class="ti ti-file-import"
                                                        ></i>
                                                        Importar de arquivo...
                                                    </button>
                                                    <button
                                                        id="exportFlowButton"
                                                        class="dropdown-item"
                                                        @click="exportFlow"
                                                    >
                                                        <i
                                                            class="ti ti-file-export"
                                                        ></i>
                                                        Exportar para arquivo...
                                                    </button>
                                                </div>
                                            </div>

                                            <div class="btn-group" role="group">
                                                <label
                                                    class="btn dropdown-toggle"
                                                    data-bs-toggle="dropdown"
                                                    aria-haspopup="true"
                                                    aria-expanded="false"
                                                >
                                                    <i
                                                        class="ti ti-settings"
                                                    ></i
                                                    >&nbsp; Configurações
                                                </label>
                                                <div
                                                    class="dropdown-menu"
                                                    style=""
                                                >
                                                    <button
                                                        v-if="flow"
                                                        id="flowVariablesButton"
                                                        class="dropdown-item position-relative"
                                                        @click="openVariables()"
                                                    >
                                                        <i
                                                            class="ti ti-code"
                                                        ></i>
                                                        Variáveis &nbsp;
                                                        <span
                                                            v-if="
                                                                flow.variables &&
                                                                flow.variables
                                                                    .length
                                                            "
                                                            class="badge badge-pill"
                                                            >{{
                                                                flow.variables
                                                                    .length
                                                            }}</span
                                                        >
                                                    </button>
                                                    <button
                                                        v-if="flow"
                                                        id="flowConfigButton"
                                                        class="dropdown-item position-relative"
                                                        @click="
                                                            openFlowConfiguration()
                                                        "
                                                    >
                                                        <i
                                                            class="ti ti-settings"
                                                        ></i>
                                                        Opções do Flow
                                                    </button>
                                                </div>
                                            </div>

                                            <div class="btn-group">
                                                <button
                                                    v-if="flow"
                                                    id="flowDescriptionButton"
                                                    class="btn position-relative"
                                                    @click="openDescription()"
                                                >
                                                    <i class="ti ti-note"></i>
                                                    Notas
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-auto">
                                        <div class="btn-group">
                                            <button
                                                id="toggleDebuggerButton"
                                                :disabled="id == 'new'"
                                                class="btn bg-blue-lt"
                                                @click="toggleDebugger()"
                                            >
                                                <i class="ti ti-radar-2"></i>
                                                Debugger
                                            </button>
                                            <button
                                                id="executeDebugButton"
                                                class="btn bg-red-lt"
                                                :disabled="
                                                    !canExecute ||
                                                    !showDebugger ||
                                                    debugMode
                                                "
                                                data-toggle="popover"
                                                data-content="O fluxo deve conter um conector 'Manual Trigger' para execução manual"
                                                @click="
                                                    callFlowExecutionWithDebugger()
                                                "
                                            >
                                                <i class="ti ti-bug"></i>
                                                Executar com Debug
                                            </button>
                                            <button
                                                id="executeButton"
                                                class="btn bg-red-lt"
                                                :disabled="!canExecute"
                                                data-toggle="popover"
                                                data-content="O fluxo deve conter um conector 'Manual Trigger' para execução manual"
                                                @click="callFlowExecution()"
                                            >
                                                <i class="ti ti-bolt"></i>
                                                Executar
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div class="editor-container">
                                    <input
                                        id="flowDataButton"
                                        v-model="flow.data"
                                        type="hidden"
                                    />
                                    <iframe
                                        id="flow-editor-iframe"
                                        src="/flow-editor/index.html"
                                        frameborder="0"
                                        @load="iframeLoaded"
                                    ></iframe>

                                    <div class="row editor-status-bar-bottom">
                                        <div class="col">
                                            <span class="text-orange"
                                                >Action/Trigger:
                                                {{ nodesCount }}</span
                                            >
                                            &nbsp;
                                            <span class="text-blue"
                                                >Controle de fluxo:
                                                {{
                                                    nodesCountFlowcontrol
                                                }}</span
                                            >
                                            &nbsp;
                                            <span class="text-azure"
                                                >Total de conectores:
                                                {{
                                                    nodesCount +
                                                    nodesCountFlowcontrol
                                                }}</span
                                            >
                                        </div>
                                        <div class="col-auto">
                                            <small
                                                class="col-auto h-8 w-4"
                                                @click="
                                                    showDialogHistoryModal()
                                                "
                                            >
                                                <i
                                                    class="ti ti-clock-share cursor-pointer"
                                                ></i>
                                            </small>
                                            <small
                                                v-show="flowUpdated"
                                                class="ml-2 text-mutted text-pinterest"
                                                >Existem alterações não salvas
                                                no fluxo</small
                                            >
                                            <small
                                                class="ml-2 text-mutted text-black"
                                                @click="
                                                    showDialogHistoryModal()
                                                "
                                                ><a
                                                    role="button"
                                                    class="link"
                                                    style="
                                                        color: black;
                                                        text-decoration: underline;
                                                    "
                                                    >Última alteração em
                                                    {{
                                                        formatDate(updated_at)
                                                    }}</a
                                                ></small
                                            >
                                            <small
                                                v-show="!flowUpdated"
                                                class="text-mutted"
                                            ></small>
                                        </div>
                                        <div class="col-auto">
                                            <button
                                                v-if="flowErrors.length"
                                                id="showFlowErrorsButton"
                                                class="btn btn-sm bg-red-lt"
                                                @click="showFlowErrors"
                                            >
                                                <i
                                                    class="ti ti-alert-triangle"
                                                ></i>
                                                Erros encontrados no fluxo
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <FlowErrorsViewer
            v-if="flowErrorsKey"
            :key="flowErrorsKey"
            :errors="flowErrors"
        />
        <FlowDebugger
            v-if="showDebugger"
            :key="debugggerComponentKey"
            :flow="flow"
            @start-debugger="onDebugStart"
            @stop-debugger="onDebugStop"
            @debug-mode-message="onDebugModeMessage"
        />
        <FlowTemplateGallery
            v-if="templateGalleryComponentKey"
            :key="templateGalleryComponentKey"
            @selected-flow="selectImportPosition"
        />
        <FlowManualTriggerForm
            v-if="showManualTriggerForm"
            :key="showManualTriggerForm"
            :flow="flow"
            :description="manualTriggerFormDescription"
            :fields="manualTriggerFormFields"
        />
        <CodeEditorModal
            v-if="showCodeEditorModal"
            :key="showCodeEditorModalKey"
            :content="codeEditorContent"
            :language="codeEditorLanguage"
            @change-content="updateCodeEditorContent"
        />
        <FlowVariablesModal
            v-if="showVariablesModal"
            :key="showVariablesModalKey"
            :init-variables="flow.variables"
            @update="updateVariables"
        />
        <FlowDescriptionModal
            v-if="showDescriptionModal"
            :key="showDescriptionModalKey"
            :init-description="flow.description"
            @update="updateDescription"
        />

        <FlowConfigurationsModal
            v-if="showConfigurationsModal"
            :key="showConfigurationsModalKey"
            :init-config="flow.config"
            @update="updateFlowConfig"
        />
        <FlowVersionModal
            :data-history="flowHistory"
            :show-dialog-history="showDialogHistory"
            @data-history="getFlowHistory"
            @restore-version="restoreVersion"
            @update-name="updateNameSnapshot"
            @delete-snapshot="deleteSnapshot"
        />

        <input
            id="importFlowInput"
            ref="importFlowInput"
            type="file"
            accept=".json"
            style="display: none"
        />
    </div>
</template>

<script>
import { store } from "../store"
import { limitGroupName, parseValidGroup } from "@/services/utils"
import EventBus from "../services/event-bus"
import Api from "../services/api"
import EngineApi from "../services/engine-api"
import moment from "moment"
import FlowErrorsViewer from "../components/FlowErrorsViewer.vue"
import FlowDebugger from "../components/FlowDebugger.vue"
import FlowTemplateGallery from "../components/FlowTemplateGallery.vue"
import FlowManualTriggerForm from "../components/FlowManualTriggerForm.vue"
import CodeEditorModal from "../components/CodeEditorModal.vue"
import FlowVariablesModal from "../components/FlowVariablesModal.vue"
import FlowConfigurationsModal from "../components/FlowConfigurationsModal.vue"
import FlowDescriptionModal from "../components/FlowDescriptionModal.vue"
import FlowSaveModal from "../components/FlowSaveModal.vue"
import FlowVersionModal from "../components/FlowVersionModal.vue"

export default {
    name: "FlowEditPage",
    components: {
        FlowErrorsViewer,
        FlowDebugger,
        FlowTemplateGallery,
        FlowManualTriggerForm,
        CodeEditorModal,
        FlowVariablesModal,
        FlowConfigurationsModal,
        FlowDescriptionModal,
        FlowSaveModal,
        FlowVersionModal
    },

    data() {
        return {
            id: null,
            initialized: false,
            flowUpdated: false,
            flow: {},
            nodesCount: 0,
            nodesCountFlowcontrol: 0,
            flowErrors: [],
            iframeResponseReceived: false,
            iframeImageResponseReceived: false,
            iframeComponent: null,
            editorLoaded: false,
            serviceAccounts: [],
            flowErrorsKey: null,
            showDebugger: false,
            debugggerComponentKey: null,
            workspace_id: null,
            templateGalleryComponentKey: null,
            manualTriggerFormFields: [],
            manualTriggerFormDescription: null,
            showManualTriggerForm: false,
            flowTriggers: [],
            codeEditorName: "",
            codeEditorContent: "",
            codeEditorLanguage: "plaintext",
            showCodeEditorModal: false,
            showCodeEditorModalKey: null,
            showVariablesModal: false,
            showVariablesModalKey: null,
            showDescriptionModal: false,
            showDescriptionModalKey: null,
            showConfigurationsModal: false,
            showConfigurationsModalKey: null,
            isInFullscreen: false,
            debugMode: false,
            flowToImport: null,
            groupsList: [],
            flowHistory: [],
            showDialogHistory: false,
            updated_at: null,
            flowSaving: false
        }
    },

    computed: {
        canLoadPage() {
            if (this.id == "new") return true
            return this.id !== "new" && this.flow._id
        },
        canExecute() {
            var can_execute = false

            if (!this.flow.enabled) return false
            if (!this.flowTriggers.length) return false

            this.flowTriggers.forEach((nodeTrigger) => {
                if (nodeTrigger.type == "core_manual_trigger") {
                    can_execute = true
                }
            })

            return can_execute
        }
    },

    async mounted() {
        Api.axiosInstance._disable_loaders = true
        EngineApi.axiosInstance._disable_loaders = true

        EventBus.emit("ajax-request-start")

        var groupsResponse = await Api.groups.list(1, 100)
        this.groupsList = groupsResponse.data.items.map((group) =>
            limitGroupName(group)
        )

        this.id = this.$route.params.id
        if (!this.id) {
            this.id = "new"
        }

        store.showSidebar = true
        store.sidebarSize = "mini"
        store.showHeader = false

        if (this.$route.query.fromNew) {
            store.backUrl = "/flows"
        }

        this.workspace_id = store.workspace._id

        const selectedGroup = parseValidGroup(
            localStorage.getItem("selectedGroup"),
            this.groupsList
        )

        if (this.id == "new") {
            // is new
            this.flow = {
                name: "",
                enabled: true,
                triggers: [],
                variables: [],
                data: null,
                group: selectedGroup,
                workspace: this.workspace_id
            }

            this.showDebugger = false
            localStorage.setItem("live-debugger", 0)
            document.body.classList.remove("live-debugger-active")
        } else {
            const filter = { id: this.$route.params.id }
            let responseData = await Api.flows.list(1, 1, filter, [
                "-history -thumbnailData"
            ])
            this.flow = responseData.data.items[0]
            this.updated_at = this.flow.updated_at
            const parsedGroup = parseValidGroup(
                this.flow.group,
                this.groupsList
            )
            this.flow.group = parsedGroup

            // existing flows without variables
            if (this.flow.variables == undefined) {
                this.flow.variables = []
            }
        }

        document.body.classList.add("flow-editor")

        // register iframe component
        this.iframeComponent = document.getElementById("flow-editor-iframe")

        // show live debugger if stored in local storage
        if (parseInt(localStorage.getItem("live-debugger"))) {
            this.toggleDebugger()
        }

        // on before unload
        window.addEventListener("beforeunload", async (e) => {
            if (this.flowUpdated) {
                e.preventDefault()
                e.returnValue = ""
            }
        })

        // execute flow from event bus
        EventBus.on("execute-flow", (flow_id) => {
            if (flow_id == this.flow._id) {
                this.callFlowExecution()
            }
        })
        await this.switchTitle(this?.flow?.name)

        Api.axiosInstance._disable_loaders = false
        EngineApi.axiosInstance._disable_loaders = false
    },
    methods: {
        async showDialogHistoryModal() {
            await this.getFlowHistory()
            this.showDialogHistory = !this.showDialogHistory
        },
        // put name of flow in page title
        async switchTitle(FlowName) {
            const title_el = document.querySelector("title")

            if (title_el) title_el.innerHTML = FlowName
        },

        async iframeLoaded() {
            this.iframeComponent = document.getElementById("flow-editor-iframe")

            var nodesLibraryResponse = await EngineApi.getNodesLibrary()
            var nodesLibrary = nodesLibraryResponse.data

            var nodesLimit = parseInt(store.user.customer.config.node_limit)

            // Embrulha funções externas para desativar os loaders
            const wrapExternalFunction = (
                externalFunction,
                disableLoaders = false
            ) => {
                const wrapper = (...args) => {
                    Api.axiosInstance._disable_loaders = false
                    return externalFunction(...args)
                }

                return wrapper
            }

            // Registra funções externas
            const externalFunctions = {
                "service-account-list": wrapExternalFunction(
                    Api.serviceAccounts.listOptions
                ),
                "slack-channels": wrapExternalFunction(
                    Api.serviceAccounts.slack.getChannels
                ),
                "trello-boards": wrapExternalFunction(
                    Api.serviceAccounts.trello.getBoards
                ),
                "trello-board-lists": wrapExternalFunction(
                    Api.serviceAccounts.trello.getLists
                ),
                "trello-board-custom-fields": wrapExternalFunction(
                    Api.serviceAccounts.trello.getCustomFields
                ),
                "database-table-list": wrapExternalFunction(async () => {
                    const response = await Api.database.tables.listAll()
                    return { data: response.data.items }
                }),
                "datasource-list": wrapExternalFunction(async () => {
                    let response = await Api.datasources.list(1, 1000, {}, [
                        "name",
                        "group"
                    ])
                    return { data: response.data.items }
                }),
                "database-field-list": wrapExternalFunction(
                    Api.database.tables.get
                ),
                "queue-list": wrapExternalFunction(async () => {
                    const response = await Api.queues.listAll()
                    return { data: response.data.items }
                }),
                "form-list": wrapExternalFunction(async () => {
                    const response = await Api.forms.listAll()
                    return { data: response.data.items }
                }),
                "flow-group": async () => {
                    const groupList = {}

                    this.groupsList.forEach((group) => {
                        groupList[group._id] = group
                    })

                    return {
                        groupList,
                        flowGroup: groupList[this.flow.group]
                    }
                },
                "flow-variables": () => {
                    return this.flow.variables
                }
            }

            this.iframeComponent.contentWindow.initializeEditor(
                nodesLibrary.nodes,
                JSON.parse(this.flow.data),
                nodesLimit,
                externalFunctions
            )

            this.iframeComponent.contentWindow.editor.addEventListener(
                "flow-initialized",
                async () => {
                    this.initialized = true
                    this.nodesCount =
                        this.iframeComponent.contentWindow.editor.getNodesCount(
                            true
                        )
                    this.nodesCountFlowcontrol =
                        this.iframeComponent.contentWindow.editor.getNodesCount() -
                        this.nodesCount
                    this.flowErrors =
                        this.iframeComponent.contentWindow.editor.getErrors()
                    this.flowTriggers =
                        this.iframeComponent.contentWindow.editor.getTriggers()

                    await this.resetDebugMode()

                    this.editorLoaded = true

                    if (this.showDebugger) {
                        this.setEditorDebuggerOpen()
                    } else {
                        this.setEditorDebuggerClosed()
                    }

                    window.getDebuggerBreakpoints =
                        this.iframeComponent.contentWindow.editor.getDebuggerBreakpoints.bind(
                            this.iframeComponent.contentWindow.editor
                        )
                }
            )
            this.iframeComponent.contentWindow.editor.addEventListener(
                "flow-updated",
                () => {
                    if (this.initialized) {
                        this.flowUpdated = true
                    }
                    this.nodesCount =
                        this.iframeComponent.contentWindow.editor.getNodesCount(
                            true
                        )
                    this.nodesCountFlowcontrol =
                        this.iframeComponent.contentWindow.editor.getNodesCount() -
                        this.nodesCount
                    this.flowErrors =
                        this.iframeComponent.contentWindow.editor.getErrors()
                    this.flowTriggers =
                        this.iframeComponent.contentWindow.editor.getTriggers()
                }
            )
            this.iframeComponent.contentWindow.editor.addEventListener(
                "detached-code-editor",
                (data) => {
                    this.openCodeEditorModal(data)
                }
            )

            // show debug mode messages
            this.iframeComponent.contentWindow.editor.addEventListener(
                "view-debug-message",
                async (data) => {
                    EventBus.emit("view-debug-message", {
                        flow_id: this.flow._id,
                        data: data
                    })
                }
            )

            // Escuta o comando CTRL + S em toda página Vue
            window.addEventListener("keydown", (e) => {
                if (
                    (e.keyCode === 83 && e.ctrlKey) ||
                    (e.keyCode === 83 && e.metaKey)
                ) {
                    e.preventDefault()
                    this.saveFlow()
                }
            })

            // Escuta o comando CTRL + S dentro do editor
            this.iframeComponent.contentWindow.editor.addEventListener(
                "save-flow",
                this.saveFlow
            )

            // get first errors
            this.flowErrors =
                this.iframeComponent.contentWindow.editor.getErrors()

            EventBus.emit("ajax-request-end")

            Api.axiosInstance._disable_loaders = false
            EngineApi.axiosInstance._disable_loaders = false
        },

        getFlowImage() {
            return this.iframeComponent.contentWindow.editor.exportToImage()
        },

        async saveFlow() {
            // prevent double click and call save shortcut multiple times
            if (this.flowSaving) {
                return
            }

            Api.axiosInstance._disable_loaders = true

            EventBus.emit("ajax-request-start")
            this.flowSaving = true

            let imageData, dataJson

            try {
                imageData = await this.getFlowImage()
                dataJson =
                    this.iframeComponent.contentWindow.editor.exportToJson()
            } catch (e) {
                EventBus.emit("message", {
                    type: "danger",
                    message:
                        "Algo deu errado ao preparar os dados para salvar o fluxo: " +
                        e.message
                })
                this.flowSaving = false
                return
            }

            // save flow
            let promise

            const isValid = this.validateFields(this.flow)
            if (!isValid) return

            if (this.id == "new") {
                this.flow.data = dataJson
                this.flow.thumbnailData = imageData

                promise = Api.flows.create({
                    ...this.flow,
                    group: this.flow.group || null
                })
            } else {
                // send only fields to update
                const flowData = {}
                flowData.name = this.flow.name
                flowData.thumbnailData = imageData
                flowData.data = dataJson
                flowData.description = this.flow.description ?? ""
                flowData.enabled = this.flow.enabled
                flowData.variables = this.flow.variables
                flowData.config = this.flow.config ?? {}
                flowData.type = "version"
                flowData.group = this.flow.group ? this.flow.group : null
                flowData.deprecated_nodes = this.flow.deprecated_nodes

                promise = Api.flows.update(this.$route.params.id, flowData)
            }

            promise
                .then((response) => {
                    EventBus.emit("ajax-request-end")
                    Api.axiosInstance._disable_loaders = false

                    EventBus.emit("message", {
                        type: "success",
                        message: "Fluxo salvo com sucesso"
                    })

                    this.flowUpdated = false
                    this.updated_at = response.data.updated_at
                    if (this.id == "new") {
                        this.$router.push(
                            "/flows/" + response.data._id + "?fromNew=true"
                        )
                    }
                })
                .catch((error) => {
                    EventBus.emit("ajax-request-end")
                    Api.axiosInstance._disable_loaders = false

                    let errorMessage = error?.response?.data?.error ?? error

                    if (error.response) {
                        switch (error.response.status) {
                            case 400:
                                errorMessage =
                                    "O fluxo contém um ou mais conectores que serão removidos em breve. Por favor, remova-os antes de salvar o fluxo."
                                break
                            case 429:
                                errorMessage =
                                    "Você atingiu o limite de conectores do tipo Action/Trigger (" +
                                    nodesLimit +
                                    ") para o mesmo fluxo! Entre em contato com o suporte para saber mais."
                                break
                        }
                    }

                    EventBus.emit("message", {
                        type: "danger",
                        message:
                            "Ocorreu um erro ao salvar o fluxo: " + errorMessage
                    })

                    this.flowUpdated = false
                })
                .finally(() => {
                    this.flowSaving = false
                })
        },

        async saveSnapshot(nameSnapshot) {
            try {
                if (this.id == "new") {
                    EventBus.emit("message", {
                        type: "danger",
                        message:
                            "Não é possível criar um snapshot de um fluxo não salvo"
                    })
                    return
                }

                Api.axiosInstance._disable_loaders = true
                EventBus.emit("ajax-request-start")

                const dataJson =
                    this.iframeComponent.contentWindow.editor.exportToJson()

                const isValid = this.validateFields(this.flow)
                if (!isValid) return

                // send only fields to update
                let flowData = {}
                flowData.name = this.flow.name
                flowData.data = dataJson
                flowData.description = this.flow.description ?? ""
                flowData.enabled = this.flow.enabled
                flowData.variables = this.flow.variables
                flowData.config = this.flow.config ?? {}
                flowData.version_name = nameSnapshot
                flowData.type = "snapshot"
                flowData.group = this.flow.group ? this.flow.group : null
                flowData.deprecated_nodes = this.flow.deprecated_nodes

                let response = await Api.flows.snapshot.save(
                    this.$route.params.id,
                    flowData
                )
                EventBus.emit("ajax-request-end")
                Api.axiosInstance._disable_loaders = false

                EventBus.emit("message", {
                    type: "success",
                    message: "Snapshot criado com sucesso"
                })

                if (this.id == "new") {
                    this.$router.push(
                        "/flows/" + response.data._id + "?fromNew=true"
                    )
                }
            } catch (error) {
                EventBus.emit("ajax-request-end")
                Api.axiosInstance._disable_loaders = false

                let errorMessage =
                    error?.response?.data?.error_message ||
                    error?.response?.data?.error ||
                    "Erro ao salvar snapshot"

                EventBus.emit("message", {
                    type: "danger",
                    message: errorMessage
                })
            }
        },

        async getFlowHistory() {
            const id = this.$route.params.id
            const responseHistory = await Api.flows.history.get(id)
            this.flowHistory = responseHistory.data[0]?.history || []
        },

        async restoreVersion(versionId) {
            try {
                this.flowUpdated = false
                const isconfirmed = confirm(
                    "Deseja realmente restaurar o fluxo? Ele será o fluxo principal automaticamente!"
                )

                if (!isconfirmed) {
                    return
                }

                await Api.flows.history.restore(this.$route.params.id, {
                    versionId: versionId
                })
                EventBus.emit("message", {
                    type: "success",
                    message: "Versão restaurada com sucesso"
                })

                location.reload()
            } catch (err) {
                EventBus.emit("message", {
                    type: "danger",
                    message: "Ocorreu um erro ao restaurar a versão"
                })
            }
        },

        async updateNameSnapshot(versionId, newName) {
            try {
                await Api.flows.snapshot.updateName(this.$route.params.id, {
                    versionId: versionId,
                    name: newName
                })
                EventBus.emit("message", {
                    type: "success",
                    message: "Nome do snapshot atualizado com sucesso"
                })
                return
            } catch (error) {
                let errorMessage =
                    error?.response?.data?.error_message ||
                    "Ocorreu um erro ao atualizar o nome do snapshot"

                EventBus.emit("message", {
                    type: "danger",
                    message: errorMessage
                })
            }
        },

        async deleteSnapshot(versionId) {
            try {
                const data = { versionId }
                await Api.flows.snapshot.delete(this.$route.params.id, data)

                EventBus.emit("message", {
                    type: "success",
                    message: "Snapshot deletado com sucesso"
                })

                await this.getFlowHistory()
                return
            } catch (err) {
                EventBus.emit("message", {
                    type: "danger",
                    message:
                        err.message || "Ocorreu um erro ao deletar o snapshot"
                })
            }
        },

        showFlowErrors() {
            this.flowErrorsKey = Math.random()
        },

        // execution in edit
        async callFlowExecution() {
            this.manualTriggerFormFields = []
            this.manualTriggerFormDescription = null
            this.showManualTriggerForm = false

            try {
                var response = await EngineApi.flows.execute(this.flow._id)

                if (!response.data.success && response.data.form_fields) {
                    this.manualTriggerFormFields = response.data.form_fields
                    this.manualTriggerFormDescription =
                        response.data.form_description
                    this.showManualTriggerForm = Math.random()

                    return
                }

                if (!this.showDebugger) {
                    EventBus.emit("message", {
                        type: "success",
                        message:
                            "Flow iniciado! Verifique os logs de monitoramento para detalhes da execução."
                    })
                }
            } catch (e) {
                EventBus.emit("message", {
                    type: "danger",
                    message: "Ocorreu um erro ao tentar executar o Flow!"
                })
            }
        },

        async callFlowExecutionWithDebugger() {
            EventBus.emit("call-start-debugger", {
                flow_id: this.flow._id,
                callback: "execute-flow"
            })
        },

        goToMonitoring() {
            var route = this.$router.resolve({
                path: "/monitoring",
                query: {
                    filters: JSON.stringify({
                        flow: this.flow._id,
                        date_alias: "1d"
                    })
                }
            })
            window.open(route.href)
        },

        async toggleDebugger() {
            this.debugggerComponentKey = Math.random()

            if (this.showDebugger) {
                this.showDebugger = false
                localStorage.setItem("live-debugger", 0)
                document.body.classList.remove("live-debugger-active")

                await this.resetDebugMode()
            } else {
                document.body.classList.add("live-debugger-active")
                this.showDebugger = true
                localStorage.setItem("live-debugger", 1)
            }
            if (this.editorLoaded) {
                if (this.showDebugger && this.flow.enabled)
                    this.setEditorDebuggerOpen()
                if (!this.showDebugger) this.setEditorDebuggerClosed()
            }
        },

        async toggleBreakPoint() {
            if (!this.flow.enabled && this.showDebugger)
                this.setEditorDebuggerOpen()
            if (this.flow.enabled || !this.showDebugger)
                this.setEditorDebuggerClosed()
        },

        async resetDebugMode() {
            // stop debug mode (if is running)
            await EngineApi.flows.stopDebugMode(this.flow._id)
            this.iframeComponent.contentWindow.editor.resetDebugMode()
        },

        slugfy(string) {
            return string
                .toLowerCase()
                .replace(/[^\w ]+/g, "")
                .replace(/ +/g, "-")
        },

        onDebugStart() {
            this.debugMode = true
            this.iframeComponent.contentWindow.editor.dispatchEvent(
                "debug-mode-start"
            )
        },

        onDebugStop() {
            this.debugMode = false
            this.iframeComponent.contentWindow.editor.dispatchEvent(
                "debug-mode-stop"
            )
        },

        onDebugModeMessage(data) {
            this.iframeComponent.contentWindow.editor.appendDebugMessage(data)
        },

        exportFlow() {
            var flowData = JSON.parse(
                this.iframeComponent.contentWindow.editor.exportToJson(true)
            )
            flowData.data.exported_at = moment().format("YYYY-MM-DD HH:mm:ss")
            flowData.variables = this.flow.variables

            var datetime = moment().format("YYYYMMDDHHmmss")

            var dataStr =
                "data:text/json;charset=utf-8," +
                encodeURIComponent(JSON.stringify(flowData))

            var fileName =
                this.slugfy(this.flow.name || "untitled") +
                "-" +
                datetime +
                ".floui.json"
            var downloadAnchorNode = document.createElement("a")
            downloadAnchorNode.setAttribute("href", dataStr)
            downloadAnchorNode.setAttribute("download", fileName)
            document.body.appendChild(downloadAnchorNode) // required for firefox

            downloadAnchorNode.click()
            downloadAnchorNode.remove()
        },

        selectImportPosition(data = null) {
            EventBus.emit("message", {
                type: "info",
                message: "Selecione a posição no editor para importar o fluxo"
            })

            this.iframeComponent.contentWindow.editor.selectImportPosition(
                (position) => {
                    this.importFlow(position.x, position.y, data)
                }
            )
        },

        importFlow(positionX, positionY, data = null) {
            EngineApi.axiosInstance._disable_loaders = true

            // data from argument
            if (data) {
                var flowData = JSON.parse(data)
                return this.iframeComponent.contentWindow.editor.importFlow(
                    flowData,
                    positionX,
                    positionY
                )
            }

            const importFlowInput = this.$refs.importFlowInput
            const file = importFlowInput.files[0]

            const handleImportFlow = (file) => {
                var reader = new FileReader()

                // When remove file after import it will trigger this function again so the value will be null
                if (!file) {
                    return
                }

                reader.onload = (e) => {
                    var flowData = JSON.parse(e.target.result)
                    EngineApi.flows
                        .validateFlowData(store.workspace, flowData)
                        .then((response) => {
                            if (response.data.isValid) {
                                this.iframeComponent.contentWindow.editor.importFlow(
                                    flowData,
                                    positionX,
                                    positionY
                                )
                                // import flow variables (append)
                                if (flowData.variables) {
                                    flowData.variables.forEach((variable) => {
                                        this.flow.variables.push(variable)
                                    })
                                }
                                EventBus.emit("message", {
                                    type: "success",
                                    message: "Arquivo importado com sucesso"
                                })
                            } else {
                                EventBus.emit("message", {
                                    type: "danger",
                                    message:
                                        "O arquivo possui erros e não pode ser importado"
                                })
                            }
                        })
                        .catch((error) => {
                            EventBus.emit("message", {
                                type: "danger",
                                message:
                                    "Ocorreu um erro ao importar o fluxo: " +
                                    error
                            })
                        })
                        .finally(() => {
                            EngineApi.axiosInstance._disable_loaders = false
                        })
                }

                reader.readAsText(file)
                importFlowInput.value = null
            }

            if (file) {
                handleImportFlow(file)
            } else {
                importFlowInput.addEventListener("change", (e) =>
                    handleImportFlow(e.target.files[0])
                )
                importFlowInput.click()
            }
        },

        showTemplateGallery() {
            this.templateGalleryComponentKey = Math.random()
        },

        openCodeEditorModal(data) {
            this.codeEditorContent = data.content
            this.codeEditorLanguage = data.language
            this.codeEditorName = data.name
            this.showCodeEditorModal = true
            this.showCodeEditorModalKey = Math.random()
        },

        updateCodeEditorContent(content) {
            this.iframeComponent.contentWindow.editor.dispatchEvent(
                "update-code-editor-content",
                {
                    content: content,
                    name: this.codeEditorName
                }
            )
        },

        setEditorDebuggerOpen() {
            this.iframeComponent.contentWindow.editor.dispatchEvent(
                "set-debugger-open"
            )
        },

        setEditorDebuggerClosed() {
            this.iframeComponent.contentWindow.editor.dispatchEvent(
                "set-debugger-closed"
            )
        },

        openVariables() {
            this.showVariablesModal = true
            this.showVariablesModalKey = Math.random()
        },
        openDescription() {
            this.showDescriptionModal = true
            this.showDescriptionModalKey = Math.random()
        },
        openFlowConfiguration() {
            this.showConfigurationsModal = true
            this.showConfigurationsModalKey = Math.random()
        },
        updateVariables(data) {
            this.flow.variables = data

            this.iframeComponent.contentWindow.editor.dispatchEvent(
                "update-flow-variables",
                data
            )
        },
        updateDescription(description) {
            this.flow.description = description
        },
        updateFlowConfig(data) {
            if (!data.rate_limit == "" && data.rate_limit <= 0) {
                data.rate_limit = 0
                this.flow.config = data
            } else {
                const numRate =
                    typeof data.rate_limit == "number"
                        ? parseInt(data.rate_limit)
                        : data.rate_limit
                data.rate_limit = numRate
                this.flow.config = data
            }
            // this.showConfigurationsModalKey = null
        },
        back() {
            if (store.backUrl) {
                var tmpValue = store.backUrl
                store.backUrl = null
                this.$router.push(tmpValue)
            } else {
                history.back()
            }
        },

        validateFields(flow) {
            const name = flow.name || ""

            if (
                name.length > 120 ||
                name.length <= 0 ||
                name.trim().length < 1
            ) {
                EventBus.emit("ajax-request-end")
                EventBus.emit("message", {
                    type: "danger",
                    message: "O nome do fluxo deve ter entre 1 a 120 caracteres"
                })
                return false
            }

            return true
        },
        formatDate(date) {
            moment.locale("pt-br")
            return moment(date).format("DD/MM/YYYY HH:mm:ss")
        }
    }
}
</script>
