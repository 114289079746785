<template>
    <div>
        <div class="page-wrapper">
            <div class="page-body">
                <div class="row g-2 col-xl-8">
                    <div class="col">
                        <div class="card">
                            <div class="card-header">
                                <div class="col">
                                    <h3 class="card-title">
                                        <i class="ti ti-alert-triangle"></i>
                                        Editar regra de alerta
                                    </h3>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col">
                                    <div class="card-body">
                                        <div class="row mb-3">
                                            <div class="col">
                                                <label
                                                    class="form-check form-switch required"
                                                >
                                                    <input
                                                        v-model="
                                                            alertRule.enabled
                                                        "
                                                        class="form-check-input"
                                                        type="checkbox"
                                                    />
                                                    <span
                                                        class="form-check-label"
                                                        >Habilitar regra</span
                                                    >
                                                </label>
                                            </div>
                                        </div>

                                        <div class="row mb-3">
                                            <div class="col">
                                                <label
                                                    class="form-label required"
                                                    >Nome da regra</label
                                                >
                                                <input
                                                    v-model="alertRule.name"
                                                    type="text"
                                                    class="form-control"
                                                    placeholder=""
                                                />
                                            </div>

                                            <div class="col-auto">
                                                <label
                                                    class="form-label required"
                                                    >Grupo</label
                                                >
                                                <div class="input-group">
                                                    <span
                                                        class="input-group-text"
                                                    >
                                                        <i
                                                            class="ti ti-folders"
                                                        ></i>
                                                    </span>
                                                    <select
                                                        v-model="
                                                            alertRule.group
                                                        "
                                                        class="form-select"
                                                    >
                                                        <option value="">
                                                            Sem grupo
                                                        </option>
                                                        <option
                                                            v-for="group in groupsList"
                                                            :key="group._id"
                                                            :value="group._id"
                                                        >
                                                            {{ group.name }}
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row mb-3">
                                            <div class="col">
                                                <label
                                                    class="form-label required"
                                                    >Tipo da regra</label
                                                >
                                                <select
                                                    v-model="alertRule.type"
                                                    class="form-select"
                                                >
                                                    <option value="match_log">
                                                        Match de logs
                                                    </option>
                                                </select>
                                            </div>
                                            <div class="col">
                                                <label
                                                    class="form-label required"
                                                    >Ação de alerta</label
                                                >
                                                <select
                                                    v-model="alertRule.action"
                                                    class="form-select"
                                                >
                                                    <option value="email">
                                                        E-mail
                                                    </option>
                                                    <option value="webhook">
                                                        Webhook
                                                    </option>
                                                </select>
                                            </div>
                                        </div>

                                        <div class="row mb-3">
                                            <div
                                                v-if="
                                                    alertRule.action == 'email'
                                                "
                                                class="col-12 mb-3"
                                            >
                                                <label
                                                    class="form-label required"
                                                    >E-mail para
                                                    recebimento</label
                                                >
                                                <input
                                                    v-model="
                                                        alertRule.action_email_to
                                                    "
                                                    type="text"
                                                    class="form-control"
                                                    placeholder=""
                                                />
                                            </div>
                                            <div
                                                v-if="
                                                    alertRule.action == 'email'
                                                "
                                                class="col-12"
                                            >
                                                <label class="form-label"
                                                    >Mensagem adicional para o
                                                    e-mail</label
                                                >
                                                <textarea
                                                    v-model="
                                                        alertRule.action_email_message
                                                    "
                                                    class="form-control"
                                                    placeholder=""
                                                ></textarea>
                                            </div>
                                            <div
                                                v-if="
                                                    alertRule.action ==
                                                    'webhook'
                                                "
                                                class="col-auto"
                                            >
                                                <label
                                                    class="form-label required"
                                                    >Método para Webhook</label
                                                >
                                                <select
                                                    v-model="
                                                        alertRule.action_webhook_method
                                                    "
                                                    class="form-select"
                                                >
                                                    <option value="post">
                                                        POST
                                                    </option>
                                                    <option value="put">
                                                        PUT
                                                    </option>
                                                    <option value="delete">
                                                        DELETE
                                                    </option>
                                                    <option value="patch">
                                                        PATCH
                                                    </option>
                                                    <option value="get">
                                                        GET
                                                    </option>
                                                </select>
                                            </div>
                                            <div
                                                v-if="
                                                    alertRule.action ==
                                                    'webhook'
                                                "
                                                class="col"
                                            >
                                                <label
                                                    class="form-label required"
                                                    >URL do webhook</label
                                                >
                                                <input
                                                    v-model="
                                                        alertRule.action_webhook_url
                                                    "
                                                    type="text"
                                                    class="form-control"
                                                    placeholder="https://"
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div class="card-body">
                                        <div class="row mb-3">
                                            <h4>Configuração da regra</h4>
                                        </div>
                                        <div class="row mb-3">
                                            <div
                                                v-if="
                                                    alertRule.type ==
                                                    'match_log'
                                                "
                                                class="col-auto"
                                            >
                                                <label
                                                    class="form-label required"
                                                    >Buscar em</label
                                                >
                                                <select
                                                    v-model="
                                                        alertRule.match_log_type
                                                    "
                                                    class="form-select"
                                                >
                                                    <option value="label">
                                                        Label do log (texto)
                                                    </option>
                                                    <option value="message">
                                                        Mensagem do log (texto)
                                                    </option>
                                                    <option
                                                        value="message_json"
                                                    >
                                                        Mensagem do log (JSON
                                                        field)
                                                    </option>
                                                </select>
                                            </div>
                                            <div
                                                v-if="
                                                    alertRule.type ==
                                                        'match_log' &&
                                                    alertRule.match_log_type ==
                                                        'message_json'
                                                "
                                                class="col-3"
                                            >
                                                <label
                                                    class="form-label required"
                                                    >Campo do JSON na
                                                    mensagem</label
                                                >
                                                <div class="row g-2">
                                                    <div class="col">
                                                        <input
                                                            v-model="
                                                                alertRule.match_log_json_field
                                                            "
                                                            type="text"
                                                            class="form-control"
                                                            placeholder=""
                                                        />
                                                    </div>
                                                </div>
                                                <small
                                                    class="form-text text-muted"
                                                >
                                                    Exemplos:
                                                    <code>event</code>,
                                                    <code>order.status</code>
                                                </small>
                                            </div>
                                            <div
                                                v-if="
                                                    alertRule.type ==
                                                    'match_log'
                                                "
                                                class="col"
                                            >
                                                <label
                                                    class="form-label required"
                                                    >Conteúdo para match</label
                                                >
                                                <div class="row g-2">
                                                    <div class="col">
                                                        <input
                                                            v-model="
                                                                alertRule.match_log_content
                                                            "
                                                            type="text"
                                                            class="form-control"
                                                            placeholder=""
                                                        />
                                                    </div>
                                                    <!-- <div class="col-auto">
                                                        <button class="btn">
                                                            <i class="ti ti-search"></i>
                                                        </button>
                                                    </div> -->
                                                </div>
                                                <small
                                                    class="form-text text-muted"
                                                >
                                                    Utiliza texto simples ou
                                                    expressões regulares.
                                                    Exemplos:
                                                    <code
                                                        >flow_run_exception</code
                                                    >,
                                                    <code
                                                        >Falha de
                                                        integração</code
                                                    >
                                                </small>
                                            </div>
                                        </div>
                                        <div class="row mb-3">
                                            <div class="col-auto">
                                                <label
                                                    class="form-label required"
                                                    >Janela de captura</label
                                                >
                                                <select
                                                    v-model="
                                                        alertRule.timewindow_seconds
                                                    "
                                                    class="form-select"
                                                >
                                                    <option value="60">
                                                        1 minuto
                                                    </option>
                                                    <option value="300">
                                                        5 minutos
                                                    </option>
                                                    <option value="600">
                                                        10 minutos
                                                    </option>
                                                    <option value="1800">
                                                        30 minutos
                                                    </option>
                                                    <option value="3600">
                                                        1 hora
                                                    </option>
                                                </select>
                                            </div>
                                            <div
                                                v-if="
                                                    alertRule.type ==
                                                    'match_log'
                                                "
                                                class="col"
                                            >
                                                <label
                                                    class="form-label required"
                                                    >Filtrar por logs gerados
                                                    por</label
                                                >
                                                <select
                                                    v-model="alertRule.flow_id"
                                                    class="form-select"
                                                >
                                                    <option value="">
                                                        Qualquer Flow
                                                    </option>
                                                    <option
                                                        v-for="flow in flowList"
                                                        :key="flow._id"
                                                        :value="flow._id"
                                                    >
                                                        {{ flow.name }}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="card-footer">
                                <div class="row">
                                    <div class="col"></div>
                                    <div class="col-auto align-right">
                                        <button
                                            class="btn btn-primary"
                                            @click="saveAlertRule"
                                        >
                                            <i class="ti ti-device-floppy"></i>
                                            Salvar regra de alerta
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { store } from "../store"
import { limitGroupName, parseValidGroup } from "@/services/utils"
import Api from "../services/api"
import EventBus from "../services/event-bus"

export default {
    name: "AlertRuleEditPage",
    data() {
        return {
            alertRule: {
                name: "Nova regra de alerta",
                type: "match_log",
                enabled: true,
                action: "email",
                action_email_to: "",
                action_webhook_method: "post",
                action_webhook_url: "",
                match_log_type: "message",
                match_log_content: "",
                flow_id: null,
                timewindow_seconds: 300
            },
            flowList: [],
            groupsList: []
        }
    },
    async mounted() {
        store.showSidebar = true
        store.showHeader = true
        store.showBackButton = true
        store.backUrl = "/alert-rules"

        var groupsResponse = await Api.groups.list(1, 100)
        this.groupsList = groupsResponse.data.items.map((group) =>
            limitGroupName(group)
        )

        var flowResponse = await Api.flows.list(1, 1000, {}, ["name"])
        this.flowList = flowResponse.data.items

        const selectedGroup = parseValidGroup(
            localStorage.getItem("selectedGroup"),
            this.groupsList
        )

        // new
        if (this.$route.params.id) {
            var response = await Api.alertRules.get(this.$route.params.id)
            this.alertRule = response.data

            if (!this.alertRule.flow_id) {
                this.alertRule.flow_id = ""
            }

            const parsedGroup = parseValidGroup(
                this.alertRule.group,
                this.groupsList
            )
            this.alertRule.group = parsedGroup
        } else {
            this.alertRule.group = selectedGroup
        }
    },
    methods: {
        async saveAlertRule() {
            try {
                if (!this.$route.params.id) {
                    await Api.alertRules.create({
                        ...this.alertRule,
                        group: this.alertRule.group || null
                    })
                } else {
                    // update
                    await Api.alertRules.update(this.alertRule._id, {
                        ...this.alertRule,
                        group: this.alertRule.group || null
                    })
                }

                EventBus.emit("message", {
                    type: "success",
                    message: "A regra de alerta foi salva com sucesso!"
                })

                this.$router.push("/alert-rules")
            } catch (e) {
                var message = e.message
                if (e.response && e.response.data && e.response.data.message) {
                    message = e.response.data.message
                }

                EventBus.emit("message", {
                    type: "danger",
                    message:
                        "Ocorreu um erro ao salvar a regra de alerta: " +
                        message
                })
            }
        }
    }
}
</script>
