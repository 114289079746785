<template>
    <div>
        <div v-if="serviceAccountsList.length" class="card">
            <div class="card-header">
                <div class="col">
                    <h3 class="card-title">
                        <i class="ti ti-key"></i>
                        Contas de serviço do workspace
                    </h3>
                </div>
                <div class="col-auto">
                    <button
                        id="newServiceAccountButton"
                        class="btn btn-primary"
                        @click="newServiceAccount()"
                    >
                        <i class="ti ti-plus"></i>
                        Criar conta
                    </button>
                </div>
            </div>
            <div>
                <table class="table table-vcenter card-table">
                    <thead>
                        <tr>
                            <th>Nome</th>
                            <th>Tipo de autenticação</th>
                            <th>URL/Host</th>
                            <th>Atualização</th>
                            <th>Ações</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            v-for="serviceAccount in serviceAccountsList"
                            :key="serviceAccount._id"
                        >
                            <td>{{ serviceAccount.name }}</td>
                            <td>
                                <span class="badge">
                                    {{ serviceAccount.auth_type }}
                                </span>
                            </td>
                            <td>
                                {{
                                    serviceAccount.auth_type === "smtp_account"
                                        ? serviceAccount.smtp_account.host
                                        : serviceAccount.url
                                }}
                            </td>
                            <td>{{ formatDate(serviceAccount.updated_at) }}</td>
                            <td>
                                <button
                                    class="btn btn-sm editServiceAccountButton"
                                    @click="editServiceAccount(serviceAccount)"
                                >
                                    <i class="ti ti-pencil"></i>
                                    Editar
                                </button>
                                <span>&nbsp;</span>
                                <button
                                    class="btn btn-sm removeServiceAccountButton"
                                    @click="
                                        removeServiceAccount(serviceAccount)
                                    "
                                >
                                    <i class="ti ti-trash"></i>
                                    Remover
                                </button>
                                <span>&nbsp;</span>
                                <button
                                    v-if="
                                        serviceAccount.auth_type.indexOf(
                                            'oauth2'
                                        ) !== -1 &&
                                        (!serviceAccount.oauth2 ||
                                            !serviceAccount.oauth2.access_token)
                                    "
                                    class="btn btn-sm btn-primary bg-blue-lt authorizeServiceAccountButton"
                                    @click="authorizeAccount(serviceAccount)"
                                >
                                    <i class="ti ti-key"></i>
                                    Autorizar conta
                                </button>
                                <button
                                    v-if="
                                        serviceAccount.auth_type.indexOf(
                                            'oauth2'
                                        ) !== -1 &&
                                        serviceAccount.oauth2 &&
                                        serviceAccount.oauth2.access_token
                                    "
                                    class="btn btn-sm bg-red-lt reauthorizeServiceAccountButton"
                                    @click="authorizeAccount(serviceAccount)"
                                >
                                    <i class="ti ti-key"></i>
                                    Reautorizar conta
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="card-footer">
                Total de
                <strong
                    >{{ serviceAccountsList.length }} contas de serviço</strong
                >
                <br />
            </div>
        </div>

        <div v-if="!serviceAccountsList.length" class="card">
            <div class="card-header">
                <h3 class="card-title">Contas de serviço do workspace</h3>
            </div>
            <div class="card-body">
                <div class="empty">
                    <p class="empty-title">
                        Nenhuma conta de serviço criado ainda.
                    </p>
                    <p class="empty-subtitle text-muted">
                        Clique no botão abaixo para criar sua primeira conta de
                        serviço!
                    </p>
                    <div class="empty-action">
                        <button
                            id="newServiceAccountButton"
                            data-bs-target="#modal-service-account-form"
                            data-bs-toggle="modal"
                            class="btn btn-primary"
                            @click="newServiceAccount()"
                        >
                            <i class="ti ti-plus"></i>
                            Criar conta de serviço
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <ServiceAccountForm
            v-if="serviceAccountFormData"
            :key="randomKey"
            :action="serviceAccountFormAction"
            :form-data="serviceAccountFormData"
            @save="saveServiceAccount"
        />
    </div>
</template>

<script>
import { store } from "../store"
import Api from "../services/api"
import AccountApi from "../services/account-api"
import EventBus from "../services/event-bus"
import ServiceAccountForm from "../components/ServiceAccountForm"

export default {
    name: "ServiceAccountsPage",
    components: {
        ServiceAccountForm
    },
    data() {
        return {
            serviceAccountFormAction: "create",
            serviceAccountFormData: null,
            serviceAccountsList: [],
            workspace_id: null,
            randomKey: ""
        }
    },
    async mounted() {
        await this.loadServiceAccounts()
    },
    methods: {
        formatDate(date) {
            return new Date(date).toLocaleString()
        },
        async loadServiceAccounts() {
            await Api.serviceAccounts.list().then((response) => {
                this.serviceAccountsList = response.data
            })
        },
        newServiceAccount() {
            this.randomKey = Math.random()
            this.serviceAccountFormAction = "create"
            this.serviceAccountFormData = {}
        },
        editServiceAccount(serviceAccount) {
            this.randomKey = Math.random()
            this.serviceAccountFormAction = "edit"
            this.serviceAccountFormData = serviceAccount
        },
        async authorizeAccount(serviceAccount) {
            const legacyAuth = [
                "trello_oauth2",
                "google_oauth2",
                "slack_oauth2",
            ]

            try {
                let authorizationUrl

                if (legacyAuth.includes(serviceAccount.auth_type)) {
                    const result = await AccountApi.serviceAccounts.oauth2.getAuthorizationUrl(
                            serviceAccount.auth_type,
                            serviceAccount._id,
                            serviceAccount.workspace
                        )
                        authorizationUrl = result.data.authorize_url
                }
                else {
                    const result = await Api.serviceAccounts.oauth2.getAuthorizationurl(
                        serviceAccount._id,
                    )

                    authorizationUrl = result.data.authorization_url
                }

                document.location.href = authorizationUrl
            } catch (e) {
                EventBus.emit("message", {
                    type: "danger",
                    message: "Ocorreu um erro ao autorizar a conta de serviço"
                })
            }
        },
        async removeServiceAccount(serviceAccount) {
            try {
                if (
                    !confirm(
                        "Tem certeza que deseja remover esta conta de serviço?"
                    )
                ) {
                    return
                }

                await Api.serviceAccounts.delete(serviceAccount._id)

                EventBus.emit("message", {
                    type: "success",
                    message: "Conta de serviço removida com sucesso"
                })

                await this.loadServiceAccounts()
            } catch (e) {
                console.log(e)

                EventBus.emit("message", {
                    type: "danger",
                    message: "Ocorreu um erro ao remover a conta de serviço"
                })
            }
        },
        async saveServiceAccount(data) {
            try {
                if (this.serviceAccountFormAction == "create") {
                    data.workspace = this.workspace_id
                    await Api.serviceAccounts.create(data)
                } else {
                    await Api.serviceAccounts.update(data._id, data)
                }

                EventBus.emit("message", {
                    type: "success",
                    message: "Conta de serviço salva com sucesso"
                })

                await this.loadServiceAccounts()
            } catch (e) {
                console.log(e)

                EventBus.emit("message", {
                    type: "danger",
                    message: "Ocorreu um erro ao salvar a conta de serviço"
                })
            }
        }
    }
}
</script>
