<template>
    <div class="page">
        <aside
            v-if="store.showSidebar"
            class="navbar navbar-vertical navbar-expand-sm navbar-dark"
            data-bs-theme="dark"
            :class="store.sidebarSize"
        >
            <div class="container-fluid">
                <button
                    id="navBarToggleButton"
                    class="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbar-menu"
                >
                    <span class="navbar-toggler-icon"></span>
                </button>
                <SidebarMenu />
            </div>
        </aside>
        <div class="page-wrapper">
            <Header v-if="store.showHeader" />
            <!-- <div class="page-body"> -->
            <div class="container-fluid mt-2">
                <Messages />
                <router-view :key="$route.fullPath"></router-view>
            </div>
            <!-- </div> -->
        </div>
    </div>
</template>

<script>
import Header from "./components/Header.vue"
import SidebarMenu from "./components/SidebarMenu.vue"
import { store } from "./store"
import Messages from "./components/Messages"

export default {
    name: "App",
    components: {
        Header,
        SidebarMenu,
        Messages
    },
    data() {
        return {
            store
        }
    }
}
</script>
