<template>
    <div>
        <div id="live-debugger">
            <div class="header">
                <div class="row">
                    <div class="col">
                        <h4>Live Debugger</h4>
                    </div>
                    <div class="col-auto">
                        <small v-if="logs.length" class="badge log-count"
                            >Exibindo últimos {{ logs.length }} logs</small
                        >
                    </div>
                </div>
            </div>

            <div class="card">
                <div class="row m-1 mt-2 mb-2">
                    <div class="col">
                        <button
                            v-show="flow.id !== 'new' && !debugMode"
                            id="startDebugButton"
                            class="btn btn-sm bg-blue-lt"
                            @click="startDebug()"
                        >
                            <i class="ti ti-player-play-filled"></i>
                            Start Debug
                        </button>
                        <button
                            v-show="flow.id !== 'new' && debugMode"
                            id="stopDebugButton"
                            class="btn btn-sm bg-red-lt"
                            :disabled="debugModeWaitingStop"
                            @click="stopDebug()"
                        >
                            <i class="ti ti-player-stop-filled"></i>
                            <span v-if="!debugModeWaitingStop">Stop Debug</span>
                            <span v-else>Stopping...</span>
                        </button>
                        <span>&nbsp;</span>
                        <button
                            id="debugNextStepButton"
                            class="btn btn-sm btn-next-step"
                            :disabled="!canRunNextStep()"
                            @click="nextStep()"
                        >
                            <i class="ti ti-player-skip-forward-filled"></i>
                            <span>Next Step</span>
                        </button>
                        <span>&nbsp;</span>
                        <button
                            id="debugContinueButton"
                            class="btn btn-sm btn-continue"
                            :disabled="!canRunNextStep()"
                            @click="continueExecution()"
                        >
                            <i class="ti ti-player-play-filled"></i>
                            <span>Continue</span>
                        </button>
                    </div>
                    <div
                        v-show="debugMode && !debugModeWaitingStop"
                        class="col-auto"
                        style="margin-top: -5px"
                    >
                        <small class="text-muted">Debug ativo</small>
                        <div class="progress">
                            <div
                                class="progress-bar progress-bar-indeterminate bg-red"
                            ></div>
                        </div>
                    </div>
                    <div
                        v-show="debugMode && debugModeWaitingStop"
                        class="col-auto"
                        style="margin-top: -5px"
                    >
                        <small class="text-muted">Aguardando finalização</small>
                        <div class="progress">
                            <div
                                class="progress-bar progress-bar-indeterminate bg-orange"
                            ></div>
                        </div>
                    </div>
                </div>

                <div class="card">
                    <div class="card-header">
                        <ul
                            class="nav nav-tabs card-header-tabs nav-fill"
                            data-bs-toggle="tabs"
                            role="tablist"
                        >
                            <li class="nav-item" role="presentation">
                                <a
                                    id="debugger-logs-viewer-tab-button"
                                    href="#debugger-logs"
                                    class="nav-link active"
                                    data-bs-toggle="tab"
                                    aria-selected="true"
                                    role="tab"
                                    tabindex="-1"
                                >
                                    Mensagens da execução
                                </a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a
                                    id="debugger-message-viewer-tab-button"
                                    href="#debugger-message-viewer"
                                    class="nav-link"
                                    data-bs-toggle="tab"
                                    role="tab"
                                    :disabled="!debugMode"
                                    >Debug Output</a
                                >
                            </li>
                        </ul>
                    </div>
                    <div class="card-body">
                        <div class="tab-content">
                            <div
                                id="debugger-logs"
                                class="tab-pane active show debugger-logs-panel"
                                role="tabpanel"
                            >
                                <div class="message-list-container">
                                    <ol class="list-group">
                                        <li
                                            v-for="log in reversedLogs"
                                            v-show="canShowLog(log)"
                                            :key="log._id"
                                            class="list-group-item"
                                            :data-node-uid="log.node_uid"
                                            :data-execution-id="
                                                log.execution_id
                                            "
                                        >
                                            <div class="log-info">
                                                <span>
                                                    <span class="timestamp"
                                                        >Data/hora:
                                                        {{
                                                            formatTimestamp(
                                                                log.timestamp
                                                            )
                                                        }}</span
                                                    >
                                                    <div
                                                        v-if="
                                                            log.type == 'debug'
                                                        "
                                                        class="badge badge-sm bg-orange text-orange-fg type"
                                                    >
                                                        Type: {{ log.type }}
                                                    </div>
                                                    <div
                                                        v-if="
                                                            log.type != 'debug'
                                                        "
                                                        class="badge badge-sm bg-azure text-azure-fg type"
                                                    >
                                                        Type: {{ log.type }}
                                                    </div>
                                                </span>
                                                <span
                                                    v-if="log.node_uid"
                                                    class="type"
                                                >
                                                    Node ID:
                                                    <button
                                                        class="btn btn-sm"
                                                        @click="
                                                            hintNode(
                                                                log.node_uid
                                                            )
                                                        "
                                                    >
                                                        {{ log.node_uid }}
                                                    </button>
                                                </span>
                                                <span class="execution-id"
                                                    >Execution ID:
                                                    {{ log.execution_id }}</span
                                                >
                                                <span
                                                    v-if="log.label"
                                                    class="label"
                                                    >Label:
                                                    {{ log.label }}</span
                                                >
                                            </div>

                                            <div class="log-message">
                                                <div
                                                    v-if="log.message_too_large"
                                                    class="message"
                                                >
                                                    <p>
                                                        A mensagem está muito
                                                        grande. Para visualizar
                                                        <a
                                                            role="button"
                                                            tabindex="0"
                                                            class="link"
                                                            @click="
                                                                showLogDetails(
                                                                    log
                                                                )
                                                            "
                                                            >clique aqui</a
                                                        >
                                                    </p>
                                                </div>

                                                <vue-json-pretty
                                                    v-else-if="log.show_as_json"
                                                    :collapsed-node-length="10"
                                                    :data="log.message"
                                                />

                                                <pre v-else class="message">{{
                                                    log.message
                                                }}</pre>

                                                <a
                                                    class="btn btn-sm download-details"
                                                    @click="downloadLog(log)"
                                                >
                                                    <i
                                                        class="ti ti-download"
                                                    ></i>
                                                    download
                                                </a>
                                                <a
                                                    class="btn btn-sm view-details"
                                                    @click="showLogDetails(log)"
                                                >
                                                    <i class="ti ti-eye"></i>
                                                    visualizar
                                                </a>
                                                <a
                                                    class="btn btn-sm copy"
                                                    @click="
                                                        copyData(log.message)
                                                    "
                                                >
                                                    <i class="ti ti-copy"></i>
                                                    copiar
                                                </a>
                                            </div>
                                        </li>
                                    </ol>
                                    <div class="row">
                                        <div class="col">
                                            <label
                                                class="form-check form-switch"
                                            >
                                                <input
                                                    id="showInternalLogsCheckbox"
                                                    v-model="
                                                        includeInternalLogs
                                                    "
                                                    class="form-check-input"
                                                    type="checkbox"
                                                />
                                                <span class="form-check-label"
                                                    >Exibir logs internos</span
                                                >
                                            </label>
                                        </div>
                                        <div class="col-auto">
                                            <button
                                                id="clearLogsButton"
                                                class="btn btn-sm btn-secondary"
                                                @click="clear"
                                            >
                                                <i class="ti ti-trash"></i>
                                                Limpar logs
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                id="debugger-message-viewer"
                                class="tab-pane"
                                role="tabpanel"
                            >
                                <div
                                    v-if="
                                        !debugOutputData || debugModeWaitingNext
                                    "
                                >
                                    <p
                                        class="text-muted"
                                        style="margin-top: 10px"
                                    >
                                        <span class="execution-spinner"></span>
                                        Aguardando execução...
                                    </p>
                                </div>
                                <div
                                    v-if="
                                        debugOutputData && !debugModeWaitingNext
                                    "
                                >
                                    <div class="row row-info">
                                        <div class="col">
                                            <small
                                                >Node
                                                <code>{{
                                                    debugOutputData.node_type
                                                }}</code>
                                                UID:
                                                <code>{{
                                                    debugOutputData.node_uid
                                                }}</code>
                                            </small>
                                        </div>
                                    </div>

                                    <div class="path-selector">
                                        <div class="input-group input-group-sm">
                                            <span class="input-group-text">
                                                JSON Path
                                            </span>
                                            <input
                                                v-model="
                                                    debugModeSelectedNodeData.path
                                                "
                                                type="text"
                                                readonly
                                                class="form-control debugger-json-path-input"
                                                placeholder="Selecione um parâmetro..."
                                            />
                                            <button
                                                id="copyDataButton"
                                                class="btn copy"
                                                :disabled="
                                                    !debugModeSelectedNodeData.path
                                                "
                                                @click="
                                                    copyData(
                                                        debugModeSelectedNodeData.path,
                                                        '.debugger-json-path-input'
                                                    )
                                                "
                                            >
                                                <i class="ti ti-copy"></i>
                                                copiar
                                            </button>
                                        </div>
                                    </div>

                                    <div class="debug-message-output-viewer">
                                        <a
                                            class="btn btn-sm download-details"
                                            @click="
                                                downloadLog(debugOutputData)
                                            "
                                        >
                                            <i class="ti ti-download"></i>
                                            download
                                        </a>

                                        <a
                                            id="showLogDetailsButton"
                                            class="btn btn-sm view-details"
                                            @click="
                                                showLogDetails(debugOutputData)
                                            "
                                        >
                                            <i class="ti ti-eye"></i>
                                            visualizar
                                        </a>

                                        <a
                                            class="btn btn-sm copy"
                                            @click="
                                                copyData(
                                                    debugOutputData.message
                                                )
                                            "
                                        >
                                            <i class="ti ti-copy"></i>
                                            copiar
                                        </a>

                                        <div
                                            v-if="
                                                debugOutputData.message_too_large
                                            "
                                            class="message"
                                        >
                                            <p>
                                                A mensagem está muito grande.
                                                Para visualizar
                                                <a
                                                    role="button"
                                                    tabindex="0"
                                                    class="link"
                                                    @click="
                                                        showLogDetails(
                                                            debugOutputData
                                                        )
                                                    "
                                                    >clique aqui</a
                                                >
                                            </p>
                                        </div>

                                        <vue-json-pretty
                                            v-else
                                            :collapsed-node-length="10"
                                            :data="debugOutputData.message"
                                            :root-path="'msg'"
                                            :show-icon="true"
                                            @node-click="
                                                getDebugModeSelectedNode
                                            "
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div
            id="modal-log-viewer"
            class="modal modal-blur fade"
            tabindex="-1"
            style="display: none"
            aria-modal="true"
            role="dialog"
        >
            <div class="modal-dialog modal-xl" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Visualizar detalhes do log</h5>
                        <button
                            id="closeFormButton"
                            type="button"
                            class="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        ></button>
                    </div>
                    <div class="modal-body">
                        <div
                            v-show="selectedLog && selectedLog.show_as_json"
                            class="path-selector"
                        >
                            <div class="input-group">
                                <span class="input-group-text">
                                    JSON Path
                                </span>
                                <input
                                    v-model="selectedNodeData.path"
                                    type="text"
                                    readonly
                                    class="form-control json-path-input"
                                    placeholder="Selecione um parâmetro..."
                                />
                                <button
                                    id="copyDataButton"
                                    class="btn copy"
                                    :disabled="!selectedNodeData.path"
                                    @click="
                                        copyData(
                                            selectedNodeData.path,
                                            '.json-path-input'
                                        )
                                    "
                                >
                                    <i class="ti ti-copy"></i> copiar
                                </button>
                            </div>
                        </div>
                        <div v-if="selectedLog" class="log-container">
                            <pre
                                v-if="!selectedLog.show_as_json"
                                class="message"
                                >{{ selectedLog.message }}</pre
                            >
                            <vue-json-pretty
                                v-if="selectedLog.show_as_json"
                                :collapsed-node-length="10"
                                :data="selectedLog.message"
                                :show-icon="true"
                                :root-path="'msg'"
                                @node-click="getSelectedNode"
                            />
                            <a
                                id="copyContentButton"
                                class="btn btn-sm copy"
                                @click="copyData(selectedLog.message)"
                            >
                                <i class="ti ti-copy"></i> copiar conteúdo
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from "moment"
import { store } from "../store"

import VueJsonPretty from "vue-json-pretty"
import "vue-json-pretty/lib/styles.css"

import EventBus from "@/services/event-bus"
import EngineApi from "@/services/engine-api"

export default {
    name: "FlowDebugger",
    components: {
        VueJsonPretty
    },
    props: {
        flow: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            socket: null,
            logs: [],
            selectedLog: null,
            includeUserLogs: true,
            includeInternalLogs: true,
            selectedNodeData: {},
            debugModeSelectedNodeData: {},
            debugMode: false,
            debugOutputData: null,
            debugModeWaitingNext: false,
            debugModeCurrentNode: null,
            debugModeWaitingStop: false,
            debugWaitContinueInterval: null,
            debugModeContinue: false
        }
    },
    computed: {
        reversedLogs() {
            return this.logs.slice().reverse()
        },

        maxMessageSize() {
            return maxMessageSize
        }
    },
    async mounted() {
        var url = store.workspace.engine_url + "debugger-" + this.flow._id
        this.socket = io(url, {
            extraHeaders: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            }
        })

        this.socket.on("connect", () => {
            console.log(
                "%c 🚀 Floui Live Debugger connected!",
                "color: #206bc4"
            )
        })

        this.socket.on("message", (data) => {
            if (data.is_debug_mode) {
                this.$emit("debugModeMessage", data)
                this.executeDebugModeCommand(data)
            } else {
                this.appendLog(data)
            }
        })

        this.socket.on("disconnect", () => {
            console.log("%cFloui Live Debugger disconnected!", "color: gray")
        })

        this.$watch("selectedLog", () => {
            this.selectedNodeData = {}
        })

        EventBus.on("view-debug-message", (data) => {
            this.showDebugOutputData(data)
        })

        // execute flow after start debugger
        EventBus.on("call-start-debugger", async (data) => {
            // console.log('call-start-debugger', data)
            await this.startDebug(data.callback)
        })

        // on before unload
        window.addEventListener("beforeunload", async (e) => {
            if (this.debugMode) {
                this.stopDebug()
                e.preventDefault()
                e.returnValue = ""
            }
        })
    },
    async unmounted() {
        this.socket.disconnect()
    },
    methods: {
        clear() {
            this.logs = []
        },
        appendLog(log) {
            var maxLogs = 50

            if (log.message == undefined) return

            if (this.logs.length >= maxLogs) {
                this.logs.shift()
            }

            log.show_as_json = false

            try {
                var tryToParse = true
                var maxMessageSize = 100000

                log.message_too_large = log.message.length > maxMessageSize

                if (
                    typeof log.message === "string" &&
                    log.message.length > maxMessageSize
                ) {
                    log.show_as_json = false
                    tryToParse = false
                }

                if (
                    typeof log.message === "object" &&
                    JSON.stringify(log.message).length > maxMessageSize
                ) {
                    log.show_as_json = false
                    tryToParse = false
                }

                if (tryToParse) {
                    if (typeof log.message === "string") {
                        let parsed = JSON.parse(log.message)

                        log.message = parsed
                        log.show_as_json = true
                    }

                    if (typeof log.message === "object") {
                        log.show_as_json = true
                    }
                }
            } catch (e) {}

            this.logs.push(log)
        },
        formatTimestamp(timestamp) {
            var datetime = moment(timestamp).format("DD/MM/YYYY HH:mm:ss")
            return datetime
        },
        formatMessage(message) {
            if (typeof message === "object") {
                return JSON.stringify(message, null, 2)
            }
            return message
        },
        copyData(content, elSelector) {
            if (typeof content === "object") {
                content = JSON.stringify(content, null, 2)
            }

            if (!elSelector) {
                var el = document.createElement("textarea")
                el.value = content
                document.body.appendChild(el)
            } else {
                var el = document.querySelector(elSelector)
            }

            el.select()
            el.setSelectionRange(0, 9999999999999)
            navigator.clipboard.writeText(el.value)
            if (!elSelector) {
                document.body.removeChild(el)
            }

            if (elSelector) {
                setTimeout(() => {
                    el.setSelectionRange(0, 0)
                    el.blur()
                }, 1000)
            }
        },
        hintNode(node_uid) {
            if (node_uid) {
                const iframeComponent =
                    document.getElementById("flow-editor-iframe")
                const editor = iframeComponent.contentWindow.editor

                editor.hintNode(node_uid, true)
            }
        },
        showDebugOutputData(data) {
            if (this.flow._id != data.flow_id) return
            if (data.data) {
                this.debugOutputData = data.data

                const maxMessageSize = 100000
                const message = JSON.stringify(this.debugOutputData.message)

                this.debugOutputData.message_too_large =
                    message.length > maxMessageSize
            }

            // force show output
            document
                .querySelector("#debugger-message-viewer-tab-button")
                .dispatchEvent(new Event("click"))
        },
        downloadLog(body) {
            let jsonString = null
            if (typeof body.message == "string") {
                try {
                    jsonString = JSON.parse(body.message)
                } catch (error) {
                    jsonString = body.message
                }
                jsonString = JSON.stringify(jsonString, null, 2)
            } else if (typeof body.message == "object") {
                jsonString = JSON.stringify(body.message, null, 2)
            }
            const blob = new Blob([jsonString], { type: "application/json" })
            const url = URL.createObjectURL(blob)
            const link = document.createElement("a")
            const nodeId = body.node_uid ? `-node_uid-${body.node_uid}` : ``
            link.href = url

            link.download = `log-${moment().format(
                "YYYY-MM-DD-HH-mm-ss"
            )}${nodeId}.json`

            document.body.appendChild(link)

            link.click()

            document.body.removeChild(link)

            URL.revokeObjectURL(url)
        },
        showLogDetails(log) {
            this.selectedLog = JSON.parse(JSON.stringify(log))

            var maxMessageSizeToParse = 100000

            if (
                typeof this.selectedLog.message == "string" &&
                !this.selectedLog.show_as_json
            ) {
                try {
                    var tryToParse = true

                    if (
                        typeof this.selectedLog.message === "string" &&
                        this.selectedLog.message.length > maxMessageSizeToParse
                    ) {
                        tryToParse = false
                    }

                    if (tryToParse) {
                        let parsed = JSON.parse(this.selectedLog.message)

                        this.selectedLog.message = parsed
                        this.selectedLog.show_as_json = true
                    } else {
                        this.downloadBody(this.selectedLog.message)
                    }
                } catch (e) {}
            }

            if (typeof this.selectedLog.message == "object") {
                this.selectedLog.show_as_json = true

                if (
                    JSON.stringify(this.selectedLog.message).length >
                    maxMessageSizeToParse
                ) {
                    this.selectedLog.show_as_json = false
                }
            }

            var modal = new bootstrap.Modal(
                document.getElementById("modal-log-viewer"),
                { keyboard: false }
            )
            modal.show()
        },
        getSelectedNode(node) {
            this.selectedNodeData = node
        },
        getDebugModeSelectedNode(node) {
            this.debugModeSelectedNodeData = node
        },
        canShowLog(log) {
            if (log.type == "internal" && !this.includeInternalLogs)
                return false
            return true
        },
        canRunNextStep() {
            return (
                this.flow.id !== "new" &&
                this.debugMode &&
                !this.debugModeWaitingNext &&
                this.debugModeCurrentNode
            )
        },
        async startDebug(callbackType) {
            this.debugMode = true
            this.debugOutputData = null
            this.debugModeSelectedNodeData = {}
            this.debugModeCurrentNode = null

            try {
                await EngineApi.flows.startDebugMode(this.flow._id)

                // force show output
                document
                    .querySelector("#debugger-message-viewer-tab-button")
                    .dispatchEvent(new Event("click"))

                if (callbackType && callbackType == "execute-flow") {
                    EventBus.emit("execute-flow", this.flow._id)
                }
            } catch (e) {
                let message = e.response.data
                    ? e.response.data.message
                    : e.message
                EventBus.emit("message", {
                    type: "danger",
                    message:
                        "Ocorreu um erro ao tentar iniciar o modo de debug: " +
                        message
                })
                this.debugMode = false
            }
        },
        async stopDebug() {
            this.debugModeWaitingStop = true
            try {
                await EngineApi.flows.stopDebugMode(this.flow._id)
            } catch (e) {
                EventBus.emit("message", {
                    type: "danger",
                    message: "Ocorreu um erro ao tentar parar o modo de debug"
                })
            }
        },
        async nextStep() {
            try {
                this.debugModeWaitingNext = true

                this.selectedNodeData = {}
                this.debugModeSelectedNodeData = {}

                await EngineApi.flows.nextStep(this.flow._id)
            } catch (e) {
                EventBus.emit("message", {
                    type: "danger",
                    message:
                        "Ocorreu um erro ao tentar executar o próximo passo de debug"
                })

                this.debugModeWaitingNext = false
            }
        },
        async continueExecution() {
            this.debugModeContinue = true
            this.nextStep()
        },
        executeDebugModeCommand(data) {
            // Data coming from engine
            if (data.node_uid && data.iteration_type == "output") {
                this.debugModeWaitingNext = false
                this.debugModeCurrentNode = data.node_uid

                // force show output
                EventBus.emit("view-debug-message", {
                    flow_id: this.flow._id,
                    data: data
                })

                if (this.debugModeContinue) {
                    let breakpoints = window.getDebuggerBreakpoints()

                    // stop on breakpoint
                    if (breakpoints.indexOf(data.node_uid) !== -1) {
                        this.debugModeContinue = false
                    } else {
                        setTimeout(() => {
                            this.continueExecution()
                        }, 200)
                    }
                }
            }

            if (data.event && data.event == "debug-start") {
                this.debugMode = true
                this.$emit("startDebugger")
            }
            if (data.event && data.event == "debug-stop") {
                this.debugModeContinue = false
                this.debugMode = false
                this.debugModeWaitingStop = false
                this.$emit("stopDebugger")
                clearInterval(this.debugWaitContinueInterval)
                // force back to logs
                document
                    .querySelector("#debugger-logs-viewer-tab-button")
                    .dispatchEvent(new Event("click"))
            }
        }
    }
}
</script>

<style scoped>
.execution-spinner {
    width: 16px;
    height: 16px;
    border: 3px solid #ddd;
    border-bottom-color: #333;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
    position: relative;
    top: 2px;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
</style>
