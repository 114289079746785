<template>
    <div class="datasource-result-container">
        <div class="card">
            <div class="card-header">
                <div class="col">
                    <div class="row">
                        <div class="col-auto">
                            <h2 class="card-title">
                                Resultado da consulta
                            </h2>
                        </div>
                        <div class="col-auto">
                            <button class="btn btn-sm btn-secondary mr-3" :disabled="!canRenderTable" :class="{'btn-outline-secondary': outputType != 'table'}" @click="outputType = 'table'">Tabela</button>
                            <button class="btn btn-sm btn-secondary"  :class="{'btn-outline-secondary': outputType != 'json'}" @click="outputType = 'json'">JSON</button>
                        </div>
                    </div>
                </div>
                <div class="col-auto mr-2">
                    <span class="badge bg-azure-lt" v-if="resultSet.length > 1">{{ resultSet.length }} registros retornados</span>
                    <span class="badge bg-azure-lt" v-else-if="resultSet.length === 1">1 registro retornado</span>
                    <span class="badge bg-azure-lt" v-else>Nenhum registro retornado</span>
                </div>
                <div class="col-auto" v-show="resultSet && resultSet.length">
                    <button class="btn btn-sm btn-secondary" @click="exportCsv()">
                        <i class="ti ti-file-export"></i>
                        Exportar CSV
                    </button>
                </div>
            </div>

            <div class="table-responsive p-1">
                <table class="table text-nowrap table-bordered table-datasource-result" v-if="resultSet.length && outputType === 'table'" width="100%">
                    <thead>
                        <tr>
                            <th v-for="(value, key) in resultSet[0]" :key="key">
                                {{ key }}
                                <span class="badge bg-azure-lt typeof">{{ typeof value }}</span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(row, index) in resultSet" :key="index">
                            <td v-for="(value, key) in row" :key="key">{{ value }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="card-body" v-if="resultSet.length && outputType === 'json'">
                <vue-json-pretty :data="resultSet" />
            </div>

            <div class="card-body" v-if="resultSet.length === 0 && message">
                <p class="alert alert-warning">
                    {{ message }}
                </p>
            </div>
        </div>
    </div>
</template>

<style>
.table-datasource-result td,
.table-datasource-result th {
    padding: 3px 8px
}
.table-datasource-result thead th {
    font-weight: bold;
    text-align: left;
    background-color: #e7ebf3
}
.table-datasource-result th span.typeof {
    float: right;
}
.table-datasource-result tr:hover td {
    background-color: #e7ebf3;
}
/* odd rows */
.table-datasource-result tbody tr:nth-child(odd) {
    background-color: #f9f9f9;
}
.datasource-result .btn:disabled {
    color: #fff;
}
</style>

<script>
import VueJsonPretty from "vue-json-pretty"
import "vue-json-pretty/lib/styles.css"

export default {
    components: {
        VueJsonPretty
    },
    props: {
        error: {
            type: Boolean,
            required: false
        },
        message: {
            type: String,
            required: false
        },
        result: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            outputType: 'table',
            resultSet: this.result,
            search: ''
        }
    },
    computed: {
        canRenderTable() {
            // verifica se o resultado é um array de objetos
            return this.resultSet.length && typeof this.resultSet[0] === 'object'
        }
    },
    mounted() {
        if (!this.canRenderTable) {
            this.outputType = 'json'
        }
    },
    methods: {
        async exportCsv() {
            let csvContent = "data:text/csv;charset=utf-8,"
            csvContent += Object.keys(this.resultSet[0]).join(",") + "\n"

            this.resultSet.forEach((row) => {
                csvContent += Object.values(row).join(",") + "\n"
            })

            var encodedUri = encodeURI(csvContent)
            var link = document.createElement("a")
            var timestamp = new Date().toISOString().replace(/[^0-9]/g, "")
            link.setAttribute("href", encodedUri)
            link.setAttribute("download", `export-datasource-${timestamp}.csv`)
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
        }
    }
}
</script>