<template>
    <div class="card">
        <v-breadcrumbs
            :items="path"
            class="ml-2 mt-3 bg-white h-5 p-0 text-2"
            density="compact"
        >
            <template #title="{ item }">
                {{ item.title.toUpperCase() }}
            </template>
        </v-breadcrumbs>
    </div>
</template>

<script>
export default {
    name: "BreadCrumb",
    props: {
        path: {
            type: Object[{ title: String, href: String, disabled: Boolean }],
            default: []
        }
    }
}
</script>
