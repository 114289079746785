<template>
    <header class="navbar navbar-expand-md navbar-light d-print-none">
        <div class="container-fluid">
            <div class="navbar-nav">
                <a
                    v-if="store.showBackButton"
                    id="showBackButton"
                    class="btn m-1"
                    @click="back"
                >
                    &larr; Voltar
                </a>
                <div v-if="store.showGroupButton" class="input-group m-1">
                    <span class="input-group-text">
                        <i class="ti ti-folders"></i>
                    </span>
                    <select
                        v-model="selectedGroup"
                        class="form-select"
                        @change="
                            (event) => updateSelectedGroup(event.target.value)
                        "
                    >
                        <option value="">Todos os grupos</option>
                        <option
                            v-for="group in store.groups"
                            :key="group._id"
                            :value="group._id"
                        >
                            {{ group.name }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="navbar-nav flex-row order-md-last">
                <div class="nav-item d-none d-md-flex me-3">
                    <div class="btn-list">
                        <a
                            id="openDocsButton"
                            href="https://docs.floui.io"
                            class="btn"
                            target="_blank"
                        >
                            <i class="ti ti-book"></i> &nbsp; Docs
                        </a>
                        <a
                            id="openSupportButton"
                            href="https://floui.freshdesk.com"
                            class="btn"
                            target="_blank"
                        >
                            <i class="ti ti-help"></i> &nbsp; Suporte
                        </a>
                    </div>
                </div>
                <div class="d-none d-md-flex"></div>
                <div class="nav-item dropdown">
                    <a
                        href="#"
                        class="nav-link d-flex lh-1 text-reset p-0"
                        data-bs-toggle="dropdown"
                        aria-label="Open user menu"
                        aria-expanded="false"
                    >
                        <span class="nav-link-icon">
                            <i class="ti ti-user-circle text-lg"></i>
                        </span>
                        <div class="d-none d-xl-block ps-2">
                            <div>
                                <small class="text-blue">{{
                                    customer.name
                                }}</small>
                            </div>
                            <div class="mt-1 small text-muted">
                                {{ user.email }}
                            </div>
                        </div>
                    </a>
                    <div
                        class="dropdown-menu dropdown-menu-end dropdown-menu-arrow"
                    >
                        <a
                            id="profileMenuButton"
                            href="#"
                            class="dropdown-item"
                            @click="goToProfileUrl"
                            >Meu perfil</a
                        >
                        <div class="dropdown-divider"></div>
                        <a
                            id="signOutMenuButton"
                            href="#"
                            class="dropdown-item"
                            @click="logout"
                            >Sair</a
                        >
                    </div>
                </div>
                <div class="nav-item d-none d-md-flex me-3"></div>
            </div>
        </div>
    </header>
</template>

<script>
import EventBus from "../services/event-bus"
import Api from "../services/api"
import { store } from "../store"
import { limitGroupName, parseValidGroup } from "@/services/utils"

export default {
    name: "Header",
    data() {
        return {
            headerTitle: "",
            user: {},
            customer: {},
            store: store,
            selectedGroup: ""
        }
    },
    async mounted() {
        if (localStorage.getItem("user")) {
            this.user = JSON.parse(localStorage.getItem("user"))
            this.customer = this.user.customer
        }

        EventBus.on("set-header-title", (title) => {
            this.headerTitle = title
        })

        EventBus.on("update-groups-list", async () => {
            if (!localStorage.getItem("token")) {
                return
            }

            const groupsResponse = await Api.groups.list(1, 100)
            store.groups = groupsResponse.data.items.map((group) =>
                limitGroupName(group)
            )

            const parsedGroup = parseValidGroup(
                localStorage.getItem("selectedGroup"),
                store.groups
            )
            this.selectedGroup = parsedGroup

            // Force update of selected group if it is invalid (not found in the list)
            if (!parsedGroup) {
                this.updateSelectedGroup(parsedGroup)
            }
        })

        EventBus.emit("update-groups-list")
    },
    methods: {
        logout() {
            EventBus.emit("call-logout")
        },
        back() {
            if (this.store.backUrl) {
                var tmpValue = this.store.backUrl
                this.store.backUrl = null
                this.$router.push(tmpValue)
            } else {
                history.back()
            }
        },
        goToProfileUrl() {
            window.location.href =
                process.env.VUE_APP_ACCOUNT_URL + "user/profile"
        },
        updateSelectedGroup(selectedGroup) {
            localStorage.setItem("selectedGroup", selectedGroup)
            EventBus.emit("update-selected-group", selectedGroup)
        }
    }
}
</script>
