<template>
    <div>
        <ul class="pagination">
            <li class="page-item">
                <button
                    id="paginationPreviousButton"
                    class="btn"
                    :disabled="currentPage <= 1"
                    @click.prevent="setPage(currentPage - 1)"
                >
                    <i class="ti ti-chevron-left"></i>
                    Anterior
                </button>
            </li>

            <li
                v-for="page in pages"
                :key="page"
                class="page-item m-1"
                :class="{ active: page === currentPage }"
            >
                <a
                    id="paginationCurrentPage"
                    class="page-item page-link"
                    href="#"
                    @click.prevent="setPage(page)"
                >
                    {{ page }}
                </a>
            </li>

            <li v-if="totalPages > maxVisiblePageItems" class="page-item px-2">
                <input
                    id="paginationCurrentPageInput"
                    v-model="currentPageInput"
                    type="number"
                    class="form-control input-page"
                    @change="setPage(currentPageInput)"
                />
            </li>

            <li class="page-item ml-1">
                <button
                    id="paginationNextButton"
                    class="btn"
                    href="#"
                    :disabled="currentPage >= totalPages"
                    @click.prevent="setPage(currentPage + 1)"
                >
                    Próxima
                    <i class="ti ti-chevron-right"></i>
                </button>
            </li>

            <li class="page-item m-2">
                <strong>
                    <span v-if="total == 10000">+</span
                    >{{ total }} item(s)</strong
                >
                - {{ currentPage }} de {{ totalPages }} página(s)
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: "Pagination",
    props: {
        showPerPage: {
            type: Number,
            default: 20
        },
        currentPage: {
            type: Number,
            default: 1
        },
        total: {
            type: Number,
            default: 0
        }
    },
    emits: ["pageChange"],
    data() {
        return {
            totalPages: 0,
            maxVisiblePageItems: 5,
            currentPageInput: 1,
            pages: []
        }
    },
    mounted() {
        this.currentPageInput = this.currentPage
        this.updateTotalPages()
        this.$watch("currentPage", (newVal) => {
            this.currentPageInput = newVal
        })
        this.$watch("total", () => {
            this.updateTotalPages()
        })
    },
    methods: {
        setPage(page) {
            if (page > this.totalPages) {
                page = this.totalPages
            }

            if (page <= 0) {
                page = 1
            }
            this.$emit("pageChange", page)
        },
        updateTotalPages() {
            this.pages = []
            this.totalPages = Math.ceil(this.total / this.showPerPage)
            if (this.totalPages <= this.maxVisiblePageItems) {
                for (let i = 1; i <= this.totalPages; i++) {
                    this.pages.push(i)
                }
            }
        }
    }
}
</script>

<style scoped>
.input-page {
    width: 60px;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
    -moz-appearance: textfield;
}
</style>
