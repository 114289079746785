<template>
    <div
        id="modal-flow-errors-viewer"
        class="modal modal-blur fade"
        tabindex="-1"
        style="display: none"
        aria-modal="true"
        role="dialog"
    >
        <div class="modal-dialog modal-xl" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Erros encontrados no fluxo</h5>
                    <button
                        id="closeFormButton"
                        type="button"
                        class="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                    ></button>
                </div>
                <div class="modal-body">
                    <ol class="list-group">
                        <li
                            v-for="error in errors"
                            :key="error.id"
                            class="list-group-item list-group-flush list-group-hoverable"
                        >
                            <span
                                v-if="error.type == 'error'"
                                class="badge bg-red-lt"
                                >Error</span
                            >
                            <span
                                v-if="error.type == 'warning'"
                                class="badge bg-orange-lt"
                                >Warning</span
                            >
                            &nbsp;
                            <span v-html="error.message"></span>
                        </li>
                    </ol>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "FlowErrorsViewer",
    props: {
        errors: {
            type: Array,
            required: true
        }
    },
    data() {
        return {}
    },
    mounted() {
        var modal = new bootstrap.Modal(
            document.getElementById("modal-flow-errors-viewer"),
            {
                keyboard: false
            }
        )
        modal.show()
    },
    methods: {}
}
</script>
