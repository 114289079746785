<template>
    <div>
        <div class="row">
            <div class="col">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col">
                                <h3 class="card-title">Remover Datasource</h3>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <p class="mb-3">
                                    Tem certeza que deseja remover o datasource
                                    <strong>{{ datasource.name }}</strong>? <br />
                                    <span>Esta ação não poderá ser
                                        desfeita.</span>
                                </p>
                                <div class="form-group">
                                    <label class="form-label">Para confirmar a remoção, digite abaixo
                                        o texto
                                        <span class="bg-red-lt px-1">{{
                                            datasource.name
                                            }}</span>:</label>
                                    <input type="text" class="form-control" v-model="datasourceNameConfirmation"
                                        id="confirmationButton" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-footer">
                        <div class="row">
                            <div class="col">
                                <a href="/datasources/" class="btn btn" id="cancelAndBackButton">
                                    <i class="ti ti-arrow-left"></i>
                                    Cancelar e voltar
                                </a>
                            </div>
                            <div class="col-auto">
                                <a v-on:click="removeDatasource" class="btn btn-danger" id="removeDatasourceButton">
                                    <i class="ti ti-trash"></i>
                                    Remover o datasource
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Api from "../services/api"
import EventBus from "../services/event-bus"

export default {
    name: "DatasourceDeletePage",
    data() {
        return {
            datasourceNameConfirmation: "",
            datasource: {}
        }
    },
    async mounted() {
        var id = this.$route.params.id
        var response = await Api.datasources.get(id)
        this.datasource = response.data
    },
    methods: {
        async removeDatasource() {
            var id = this.$route.params.id
            try {
                if (this.validate()) {
                    await Api.datasources.delete(id)

                    this.$router.push("/datasources")

                    EventBus.emit("message", {
                        type: "success",
                        message: "Datasource removido com sucesso!"
                    })
                }
            } catch (error) {
                EventBus.emit("message", {
                    type: "error",
                    message: "Erro ao remover Datasource:" + error
                })
            }
        },
        validate() {
            if (this.datasource.name !== this.datasourceNameConfirmation) {
                EventBus.emit("message", {
                    type: "warning",
                    message:
                        "O nome do datasource não confere com o nome digitado!"
                })

                return false
            }
            return true
        }
    }
}
</script>
