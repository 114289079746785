<template>
    <div
        id="modal-flow-description"
        class="modal modal-blur fade"
        tabindex="-1"
        aria-modal="true"
        role="dialog"
    >
        <div class="modal-dialog modal-xl" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Notas do Flow</h5>
                    <button
                        id="closeFormButton"
                        type="button"
                        class="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                    ></button>
                </div>
                <div class="editor-container">
                    <QuillEditor
                        ref="QuillEditorRef"
                        v-model:content="description"
                        theme="snow"
                        :toolbar="toolbarOptions"
                        :content-type="'html'"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { QuillEditor } from "@vueup/vue-quill"
import "@vueup/vue-quill/dist/vue-quill.snow.css"
import EventBus from "@/services/event-bus"
export default {
    name: "FlowDescriptionModal",
    components: {
        QuillEditor
    },
    props: {
        initDescription: {
            type: String
        }
    },
    data() {
        return {
            oldDescription: "",
            description: "",
            isEditMode: false,
            toolbarOptions: [
                [{ header: [1, 2, 3, 4, 5, 6, false] }],
                [{ size: ["small", false, "large", "huge"] }], // custom dropdown

                ["bold", "italic", "underline", "strike"], // toggled buttons
                ["blockquote", "code-block"],

                [{ list: "ordered" }, { list: "bullet" }],

                [{ color: [] }, { background: [] }], // dropdown with defaults from theme
                [{ font: [] }],
                [{ align: [] }],
                ["link", "image"],

                ["clean"] // remove formatting button
            ]
        }
    },
    mounted() {
        var modal = new bootstrap.Modal(
            document.getElementById("modal-flow-description"),
            { keyboard: false }
        )
        modal.show()

        if (this.initDescription) {
            this.description = this.initDescription
            this.oldDescription = this.initDescription
        }

        this.$watch("description", () => {
            this.validateNoteSize()
            this.$emit("update", this.description)
        })
    },
    methods: {
        validateNoteSize() {
            const maxSize = 1e6 // same as 1MB
            if (this.description.length > maxSize) {
                this.description = this.oldDescription

                this.$refs.QuillEditorRef.setHTML(this.oldDescription)

                EventBus.emit("message", {
                    type: "danger",
                    message:
                        "Limite de tamanho de nota atingido. O tamanho máximo é de 1MB."
                })

                this.$emit("update", this.description)
            }

            this.oldDescription = this.description
        }
    }
}
</script>

<style>
#modal-flow-description .editor-container {
    height: 80vh;
    overflow: auto;
    position: relative;
}
#modal-flow-description .ql-toolbar.ql-snow {
    border: none !important;
    border-bottom: #f1f1f1 solid 1px !important;
    background: #fafafa;
}
#modal-flow-description .ql-container.ql-snow {
    border: none !important;
    min-height: 400px !important;
}
</style>
