<template>
    <div>
        <div class="page-wrapper">
            <div class="page-body">
                <div class="row g-2 mb-3">
                    <div class="col">
                        <div class="form-datasource">
                            <div class="card">
                                <div class="card-header">
                                    <div class="col">
                                        <h3 class="card-title">
                                            <i class="ti ti-database-export"></i>
                                            Datasource: {{ datasource.name }}
                                        </h3>
                                    </div>
                                    <div class="col-auto mr-2">
                                        <a v-bind:href="'/datasources/edit/' + datasource._id" class="btn">
                                            <i class="ti ti-pencil"></i>
                                            Editar
                                        </a>
                                    </div>
                                    <div class="col-auto">
                                        <button class="btn btn-success" @click="callExecuteQuery()">
                                            <i class="ti ti-player-play-filled"></i>
                                            Executar datasource
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row g-2">
                    <div class="col">
                        <DatasourceResult :result="queryResult" :key="queryResultKey" :message="queryErrorMessage"
                            v-if="queryResult" class="datasource-result" />
                    </div>
                </div>
            </div>
        </div>

        <div class="modal modal-blur fade" id="modal-input-variables" tabindex="-1" aria-modal="true" role="dialog">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">
                            Entre com os valores para a consulta
                        </h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body pt-3">
                        <div class="modal-body-container">
                            <div class="row mb-2">
                                <div class="col">
                                    <div v-for="variable in datasource.input_variables" :key="variable.key"
                                        class="form-execution-variables">
                                        <div class="row mb-3">
                                            <div class="col">
                                                <label class="form-label" :class="{ required: variable.required }">{{
                                                    variable.label }} <small class="text-muted">({{ variable.key
                                                        }})</small></label>
                                                <input type="text" class="form-control"
                                                    :name="'$input-variable-' + variable.key"
                                                    :value="variable.default_value" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-success" @click="executeQuery()">
                            <i class="ti ti-player-play-filled"></i>
                            Executar
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style>
.table-variables td,
.table-variables th {
    padding: 3px;
    vertical-align: middle;
}
.table-variables td {
    text-align: center
}
</style>

<script>
import { store } from "../store"
import Api from "../services/api"
import EngineApi from "../services/engine-api"

import DatasourceResult from "../components/DatasourceResult.vue"

const default_query_sql = `SELECT * FROM table`

export default {
    name: "DatasourceEditPage",
    data() {
        return {
            datasource: {},
            modalInputVariables: null,
            canLeavePage: true,
            queryResult: null,
            queryResultTotal: 0,
            queryResultKey: null,
        }
    },
    components: {
        DatasourceResult
    },
    computed: {
        serviceAccountListComputed() {
            return this.serviceAccountList
                .filter((serviceAccount) => serviceAccount.auth_type === "database_uri")
                .map((serviceAccount) => {
                    return {
                        ...serviceAccount,
                        label: `${serviceAccount.name} (${serviceAccount.auth_type})`
                    }
                })
        },
        selectedServiceAccount() {
            return this.serviceAccountList.find((serviceAccount) => serviceAccount._id === this.datasource.service_account) || {}
        }
    },
    async mounted() {
        store.showSidebar = true
        store.showHeader = true
        store.showBackButton = true

        var response = await Api.serviceAccounts.list(1, 1000, { auth_type: "database_uri" })
        this.serviceAccountList = response.data

        // edit
        var response = await Api.datasources.get(this.$route.params.id)
        this.datasource = response.data

        this.modalInputVariables = new bootstrap.Modal(document.getElementById("modal-input-variables"), { keyboard: false })
    },
    methods: {
        validateInputVariables() {
            let isValid = true

            let inputVariables = this.datasource.input_variables

            if (inputVariables && inputVariables.length > 0) {
                inputVariables.forEach((variable) => {
                    let input = document.querySelector(`input[name="$input-variable-${variable.key}"]`)

                    if (variable.required && !input.value.trim()) {
                        input.classList.add('is-invalid')
                        isValid = false
                    } else {
                        input.classList.remove('is-invalid')
                    }
                })
            }

            return isValid
        },

        async callExecuteQuery() {
            if (this.datasource.input_variables.length > 0) {
                this.modalInputVariables.show()
            } else {
                await this.executeQuery()
            }
        },

        async executeQuery() {
            if (!this.validateInputVariables()) {
                return
            }

            let variablesData = {}

            if (this.datasource.input_variables.length) {
                this.datasource.input_variables.forEach((variable) => {
                    let input = document.querySelector(`input[name="$input-variable-${variable.key}"]`)
                    variablesData[variable.key] = input.value
                })
            }

            this.modalInputVariables.hide()

            let queryResponse = await EngineApi.datasources.executeDatasource(this.datasource._id, variablesData)

            this.queryResult = queryResponse.data?.result || []
            this.queryErrorMessage = queryResponse.data?.message || ""
            this.queryResultKey = Math.random()

            // scroll page to result
            setTimeout(() => {
                document.querySelector('.datasource-result').scrollIntoView({ behavior: 'smooth' })
            }, 200)
        }
    }
}
</script>
