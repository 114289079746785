<template>
    <div>
        <div
            id="modal-storage-form"
            class="modal modal-blur fade"
            tabindex="-1"
            style="display: none"
            aria-modal="true"
            role="dialog"
        >
            <div
                class="modal-dialog modal-lg modal-dialog-centered"
                role="document"
            >
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 v-if="action === 'upload'" class="modal-title">
                            Upload de arquivo
                        </h5>
                        <h5 v-else class="modal-title">Criar pasta</h5>
                        <button
                            id="closeFormButton"
                            type="button"
                            class="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        ></button>
                    </div>

                    <div class="modal-body">
                        <div class="row">
                            <div class="col">
                                <div v-if="action == 'folder'" class="mb-4">
                                    <label class="form-label mt-4"
                                        >Insira o nome da pasta
                                    </label>
                                    <div class="input-group mt-1">
                                        <input
                                            v-model="pathFile"
                                            type="text"
                                            class="form-control"
                                            :placeholder="pathFile"
                                            autocomplete="off"
                                        />
                                    </div>
                                    <div
                                        class="input-group-text mt-3 h-5 d-flex justify-content-between p-2 pr-0"
                                    >
                                        <span
                                            class="text-truncate"
                                            :title="startPath + pathFile"
                                        >
                                            {{
                                                transformStartPath(
                                                    startPath,
                                                    pathFile
                                                )
                                            }}
                                        </span>
                                        <button
                                            class="btn btn-primary h-1"
                                            type="button"
                                            @click="
                                                CopyPath(startPath + pathFile)
                                            "
                                        >
                                            Copy
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div v-if="action === 'upload'" class="row">
                            <label class="form-label"
                                >Selecione o arquivo</label
                            >
                            <div class="col">
                                <v-file-input
                                    v-model="file"
                                    density="compact"
                                    prepend-icon="ti ti-upload"
                                >
                                </v-file-input>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button
                            id="cancelButton"
                            class="btn btn-link link-secondary"
                            data-bs-dismiss="modal"
                            @click="cancel()"
                        >
                            Cancelar
                        </button>
                        <button
                            id="saveVariableButton"
                            class="btn btn-primary ms-auto"
                            data-bs-dismiss="modal"
                            @click="save()"
                        >
                            <div v-if="action === 'upload'">Enviar</div>
                            <div v-else>Criar</div>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import EventBus from "../services/event-bus"

export default {
    name: "VariableForm",
    props: {
        action: {
            type: String,
            required: true,
            default: "upload"
        },
        data: {
            type: Object,
            required: false,
            default: null
        }
    },
    data() {
        return {
            actionTitle: "Upload de arquivo",
            pathFile: "",
            startPath: this.$route.query.path || "/",
            newPath: "",
            file: ""
        }
    },
    mounted() {
        if (this.action === "folder") {
            this.actionTitle = "Nova pasta"
        }

        this.$watch("data", (newData) => {
            this.variable = newData
        })
    },
    methods: {
        cancel() {
            this.$emit("cancel")
        },
        save() {
            if (this.action === "upload") {
                // atualiza o estado newPath
                this.transformPath(this.startPath, this.pathFile)

                this.$emit("upload", {
                    file: this.file,
                    pathFile: this.startPath
                })
                return
            }
            if (this.action === "folder") {
                // atualiza o estado newPath
                this.transformPath(this.startPath, this.pathFile)

                this.$emit("newFolder", { pathFile: this.newPath })
                return
            }
        },
        transformStartPath(startPath, pathFile) {
            const lastLetterStartPath = startPath[startPath.length - 1]
            if (lastLetterStartPath != "/") {
                this.startPath = startPath + "/"
            }
            return this.startPath + this.pathFile
        },
        transformPath(startPath, pathFile) {
            const lastLetterPathFile = pathFile[pathFile.length - 1]
            if (lastLetterPathFile === "/") {
                pathFile = pathFile.slice(0, -1)
            }

            this.newPath = startPath + pathFile

            return startPath + pathFile
        },
        async CopyPath(path) {
            navigator.clipboard.writeText(path)
            EventBus.emit("message", {
                type: "success",
                message: "Caminho copiado para a área de transferência"
            })
        }
    }
}
</script>
<style scoped>
.text-truncate {
    display: inline-block;
    max-width: 85%; /* Defina o tamanho máximo conforme necessário */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>
