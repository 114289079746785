<template>
    <div class="card-body p-1">
        <div class="row">
            <div class="col-3 mb-2 mt-2 ml-4 h-6">
                <input
                    id="searchFileButton"
                    v-model="searchData"
                    type="text"
                    class="form-control"
                    placeholder="Buscar pastas e arquivos"
                    @change="search"
                />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "SearchBar",
    props: {
        title: {
            type: String,
            default: ""
        },
        titleButton: {
            type: String,
            default: ""
        }
    },
    emits: ["search"],
    data: function () {
        return {
            searchData: this.$route.query.search || ""
        }
    },
    methods: {
        async search() {
            const term = this.searchData || ""
            this.$emit("search", term)
        }
    }
}
</script>
