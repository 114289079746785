<template>
    <div>
        <div class="widget-content">
            <div class="widget-title">
                <div class="row">
                    <div class="col">
                        <h4 v-if="config.show_label">{{ config.label }}</h4>
                    </div>
                    <div class="col-auto">
                        <div v-if="queryData" class="table-pagination">
                            <small v-if="queryData.total" class="total"
                                >Exibindo de
                                {{
                                    (queryData.page - 1) *
                                        queryData.show_per_page +
                                    1
                                }}
                                até
                                {{ queryData.page * queryData.show_per_page }}
                                ({{
                                    numberFormat(queryData.total)
                                }}
                                registros)</small
                            >
                            <button
                                class="btn btn-sm"
                                :disabled="queryData.page <= 1"
                                @click="prevPage()"
                            >
                                &laquo; prev
                            </button>
                            <button
                                class="btn btn-sm"
                                :disabled="
                                    queryData.total_pages <= queryData.page
                                "
                                @click="nextPage()"
                            >
                                next &raquo;
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div
                v-if="queryData && queryData.total"
                class="widget-table-container"
            >
                <table class="table">
                    <thead>
                        <th v-show="config.show_column_message">Mensagem</th>
                        <th v-show="config.show_column_created_at">
                            Data/Hora
                        </th>
                    </thead>
                    <tbody>
                        <tr v-for="row in queryData.items" :key="row._id">
                            <td v-show="config.show_column_message">
                                <div v-show="config.show_log_label">
                                    <small
                                        v-show="row.label"
                                        class="text-muted"
                                        >{{ row.label }}</small
                                    >
                                </div>
                                <small>{{ row.message }}</small>
                            </td>
                            <td v-show="config.show_column_created_at">
                                {{ formatDate(row.timestamp) }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <div v-if="editMode" class="widget-options">
            <i class="btn-icon ti ti-settings-filled" @click="showConfig()"></i>
            <i class="btn-icon ti ti-copy" @click="duplicate()"></i>
            <i
                class="btn-icon ti ti-trash text-red"
                @click="removeWidget()"
            ></i>
        </div>

        <WidgetConfigModal
            v-if="editMode && showWidgetConfig"
            :key="showWidgetConfig"
            :widget="widget"
            :defaults="config"
            :params="widgetParams"
            :flows="flows"
            @refresh-data="refreshData"
            @update-config="updateConfig"
        />
    </div>
</template>

<script>
import moment from "moment-timezone"
import WidgetConfigModal from "./WidgetConfigModal.vue"

export default {
    name: "LogListWidget",
    components: {
        WidgetConfigModal
    },
    props: {
        widget: Object,
        editMode: Boolean,
        flows: Array,
        widgetsQueryResult: Object
    },
    data() {
        return {
            config: {
                periodType: "dashboard",
                flow_id: "",
                start_date: null,
                end_date: null,
                show_flow_name: false,
                show_label: true,
                font_size: "m",
                label: "",
                search_term: "",
                search_field: "message",
                show_column_message: true,
                show_column_created_at: true,
                show_log_label: true,
                show_per_page: 10
            },
            modalWidgetOptions: null,
            showWidgetConfig: null,
            queryData: {},
            flowName: null,
            widgetParams: [
                {
                    name: "search_term",
                    type: "text",
                    label: "Buscar por logs com (termo ou frase exata)",
                    required: false
                },
                {
                    name: "search_field",
                    type: "radio",
                    label: "Buscar pelo termo em",
                    required: false,
                    options: [
                        { label: "Mensagem do log", value: "message" },
                        { label: "Label do log", value: "label" }
                    ]
                },
                {
                    name: "flow_id",
                    type: "flow-selector",
                    label: "Selecione o Flow",
                    required: false,
                    refresh_on_update: true
                },
                {
                    name: "show_per_page",
                    type: "text",
                    label: "Registros por página",
                    required: false
                },
                {
                    name: "periodType",
                    type: "period",
                    label: "Período",
                    required: true,
                    refresh_on_update: true
                },
                {
                    name: "show_label",
                    type: "boolean",
                    label: "Exibir título",
                    required: false
                },
                {
                    name: "label",
                    type: "text",
                    label: "Título",
                    required: false
                },
                {
                    name: "show_column_message",
                    type: "boolean",
                    label: "Exibir coluna de mensagem",
                    required: false
                },
                {
                    name: "show_column_created_at",
                    type: "boolean",
                    label: "Exibir coluna de data do log",
                    required: false
                },
                {
                    name: "show_log_label",
                    type: "boolean",
                    label: "Exibir label mensagem (junto à mensagem)",
                    required: false
                }
            ]
        }
    },
    mounted() {
        moment.tz.setDefault("America/SaoPaulo")

        if (this.widget.config) {
            this.config = this.widget.config
        }

        this.$watch("widgetsQueryResult", this.updateQueryData, { deep: true })

        this.updateConfig()
        this.updateQueryData()
    },
    methods: {
        showConfig() {
            this.showWidgetConfig = Date.now()
        },
        removeWidget() {
            this.$emit("removeWidget", this.widget)
        },
        duplicate() {
            this.$emit("duplicateWidget", this.widget)
        },
        updateConfig() {
            this.$emit("updateConfig", this.widget, this.config)
        },
        updateQueryData() {
            this.flowName = this.flows.find(
                (flow) => flow._id === this.config.flow_id
            )?.name
            this.queryData = this.widgetsQueryResult[this.widget.id]
        },
        refreshData() {
            this.$emit("refreshData")
        },
        numberFormat(number) {
            return new Intl.NumberFormat("pt-BR").format(number)
        },
        formatDate(string) {
            return moment(string).format("DD/MM/YYYY HH:mm:ss")
        },
        nextPage() {
            this.$emit("refreshWidgetData", this.widget, {
                page: this.queryData.page + 1
            })
        },
        prevPage() {
            this.$emit("refreshWidgetData", this.widget, {
                page: this.queryData.page - 1
            })
        }
    }
}
</script>

<style scoped></style>
