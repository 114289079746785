<template>
    <div>
        <div class="row">
            <div class="col">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col">
                                <h3 class="card-title">Remover Form</h3>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <p class="mb-3">
                                    Tem certeza que deseja remover o form
                                    <strong>{{ form.name }}</strong
                                    >? <br />
                                    <span
                                        >Esta ação não poderá ser
                                        desfeita.</span
                                    >
                                </p>
                                <div class="form-group">
                                    <label class="form-label"
                                        >Para confirmar a remoção, digite abaixo
                                        o texto
                                        <span class="bg-red-lt px-1">{{
                                            form.name
                                        }}</span
                                        >:</label
                                    >
                                    <input
                                        id="confirmationInput"
                                        v-model="formNameConfirmation"
                                        type="text"
                                        class="form-control"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-footer">
                        <div class="row">
                            <div class="col">
                                <a
                                    id="cancelAndBackButton"
                                    href="/forms"
                                    class="btn btn"
                                >
                                    <i class="ti ti-arrow-left"></i>
                                    Cancelar e voltar
                                </a>
                            </div>
                            <div class="col-auto">
                                <a
                                    id="removeFormButton"
                                    class="btn btn-danger"
                                    @click="removeForm"
                                >
                                    <i class="ti ti-trash"></i>
                                    Remover o form
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Api from "../services/api"
import EventBus from "../services/event-bus"

export default {
    name: "FormDeletePage",
    data() {
        return {
            formNameConfirmation: "",
            form: {}
        }
    },
    async mounted() {
        var id = this.$route.params.id
        var response = await Api.forms.get(id)
        this.form = response.data
    },
    methods: {
        async removeForm() {
            var id = this.$route.params.id
            try {
                if (this.validate()) {
                    await Api.forms.delete(id)

                    this.$router.push("/forms")

                    EventBus.emit("message", {
                        type: "success",
                        message: "Form removido com sucesso!"
                    })
                }
            } catch (error) {
                EventBus.emit("message", {
                    type: "error",
                    message: "Erro ao remover Form:" + error
                })
            }
        },
        validate() {
            if (this.form.name !== this.formNameConfirmation) {
                EventBus.emit("message", {
                    type: "warning",
                    message: "O nome do form não confere com o nome digitado!"
                })

                return false
            }
            return true
        }
    }
}
</script>
