<template>
    <div>
        <div class="card">
            <div class="card-body">
                <div v-if="data?.data?.length">
                    <table class="table table-vcenter card-table">
                        <thead>
                            <tr>
                                <th>Nome</th>
                                <th>Criação</th>
                                <th>Modificação</th>
                                <th>Tamanho</th>
                                <th></th>
                                <th>Ações</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="file in data?.data" :key="file.name">
                                <td>
                                    <div
                                        v-if="file.isFolder"
                                        class="d-flex align-items-center cursor-pointer overflow-hidden file-container"
                                        :title="file.path"
                                    >
                                        <i
                                            class="ti ti-folder h-10 w-10 me-1"
                                            @click="updatePage(file.path)"
                                        ></i>
                                        <div
                                            class="me-1 underline-on-hover file-name"
                                            @click="updatePage(file.path)"
                                        >
                                            {{ file.name }}
                                        </div>
                                        <i
                                            class="ti ti-copy cursor-pointer"
                                            @click="CopyPath(file.path)"
                                        ></i>
                                    </div>

                                    <div
                                        v-else
                                        class="d-flex align-items-center file-container"
                                        :title="file.path"
                                    >
                                        <i
                                            class="ti ti-file h-10 w-10 me-1"
                                        ></i>
                                        <div class="me-1">
                                            {{ file.name }}
                                        </div>
                                        <i
                                            class="ti ti-copy cursor-pointer"
                                            @click="CopyPath(file.path)"
                                        ></i>
                                    </div>
                                </td>
                                <td>
                                    <div
                                        class="underline-on-hover cursor-grabbing"
                                    >
                                        {{
                                            file.isFolder
                                                ? ""
                                                : formatDate(file.created_at)
                                        }}
                                    </div>
                                </td>
                                <td>
                                    <div
                                        class="underline-on-hover cursor-grabbing"
                                    >
                                        {{
                                            file.isFolder
                                                ? ""
                                                : formatDate(file.updated_at)
                                        }}
                                    </div>
                                </td>
                                <td>
                                    <div class="">{{ file.size }}</div>
                                </td>
                                <td></td>
                                <td>
                                    <div
                                        v-if="!file.isFolder"
                                        class="btn btn-sm h-4 bg-blue border rounded-2 border-light"
                                        @click="
                                            emitDownloadFile({
                                                path: file.path,
                                                name: file.name
                                            })
                                        "
                                    >
                                        <i class="ti ti-download"></i>
                                    </div>
                                    <div
                                        v-if="file.isFolder"
                                        class="btn btn-sm h-4 cursor-not-allowed bg-gray-800 border rounded-2 border-light"
                                    >
                                        <i class="ti ti-download"></i>
                                    </div>
                                    <div
                                        class="btn btn-sm h-4 bg-red ml-2 border rounded-2 border-light"
                                        @click="
                                            deleteFile(file.path, file.isFolder)
                                        "
                                    >
                                        <i class="ti ti-trash"></i>
                                    </div>

                                    <span>&nbsp;</span>
                                    <!-- <span class="dropdown">
                                        <button class="btn btn-sm ti ti-dots-vertical tableOptionsDropdown h-4 m-0"
                                            data-bs-toggle="dropdown">
                                        </button>
                                        <div class="dropdown-menu">
                                            <a :href="'/storage/tables/edit/' +
                                                file.name
                                                " class="dropdown-item editTableButton">
                                                <i class="ti ti-pencil"></i>
                                                Renomear arquivo
                                            </a>
                                            <a href="#" class="dropdown-item " @click="() => { }">
                                                <i class="ti ti-copy"></i>
                                                Duplicar
                                            </a>
                                            <a href="#" class="dropdown-item " @click="() => { }">
                                                <i class="ti ti-download"></i>
                                                Mover arquivo
                                            </a>
                                        </div>
                                    </span> -->
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div v-if="!data?.data?.length && !filter.query" class="card-body">
                <div class="empty">
                    <p class="empty-title">Sem arquivos</p>
                    <p class="empty-subtitle text-muted">
                        Clique no botão abaixo para fazer o upload de um
                        arquivo!
                    </p>
                    <div class="empty-action">
                        <a
                            id="newTableButton"
                            href="#"
                            data-bs-toggle="modal"
                            data-bs-target="#modal-storage-form"
                            class="btn btn-primary"
                        >
                            <i class="ti ti-plus"></i>
                            Upload
                        </a>
                    </div>
                </div>
            </div>

            <div v-if="!data?.data?.length && filter.query" class="card-body">
                <div class="empty bg-muted-lt">
                    <p class="empty-title">
                        Nenhum arquivo encontrado para esta busca.
                    </p>
                </div>
            </div>

            <div class="card-footer"></div>
        </div>
    </div>
</template>

<script>
import Api from "../services/api"
import EventBus from "../services/event-bus"
import moment from "moment-timezone"
export default {
    name: "FilesList",
    components: {
        // Pagination
    },
    props: {
        data: {
            type: Object[{}],
            default: []
        }
    },
    emits: ["updateData", "downloadFile"],
    data() {
        return {
            tableList: [],
            filter: {
                query: ""
            },
            dataFileList: [],
            paginationKey: 0,
            showPerPage: 10,
            currentPage: 1,
            total: 0
        }
    },
    async created() {},

    async mounted() {
        this.dataFileList = this.dataFile
        this.currentPage = parseInt(this.$route.query.page) || 1
        this.filter.query = this.$route.query.query || ""
        this.filter.group = localStorage.getItem("selectedGroup") || ""

        EventBus.on("update-selected-group", () => {})
    },
    methods: {
        async updatePage(path) {
            this.$router.replace({
                query: {
                    path: path
                }
            })
        },

        async emitLoadStorage() {
            this.$emit("updateData", "")
        },

        async emitDownloadFile(data) {
            this.$emit("downloadFile", data)
        },

        async duplicateFile(pathFile) {
            return
        },

        async deleteFile(path, isFolder) {
            const type = isFolder ? "Pasta" : "Arquivo"
            try {
                if (
                    confirm(
                        `Deseja realmente excluir este ${type.toLowerCase()}? Esta ação não poderá ser revertida.`
                    )
                ) {
                    await Api.storage.delete(path)
                    EventBus.emit("message", {
                        type: "success",
                        message: `${type} excluída(o) com sucesso`
                    })
                    this.dataFileList = await this.emitLoadStorage()
                }
            } catch (err) {
                const error = err.response.data
                if (err.response.status === 400) {
                    EventBus.emit("message", {
                        type: error.type || "danger",
                        message: error.message || "Erro ao excluir arquivo"
                    })
                    return
                }

                EventBus.emit("message", {
                    type: error.type || "danger",
                    message: error.message || "Erro ao excluir arquivo"
                })
            }
        },

        async CopyPath(path) {
            navigator.clipboard.writeText(path)
            EventBus.emit("message", {
                type: "success",
                message: "Caminho copiado para a área de transferência"
            })
        },

        formatDate(date) {
            moment.locale("pt-br")
            return moment(date).format("DD/MM/YYYY HH:mm:ss")
        }
    }
}
</script>
<style scoped>
.underline-on-hover:hover {
    text-decoration: underline;
}

.file-name {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100%;
    display: block; /* Necessário para que o text-overflow funcione */
}

.file-container {
    /* overflow: hidden; */
    width: 500px;
}
</style>
