<template>
    <div class="text-center">
        <v-btn-group color="#0054a6" density="comfortable" divided>
            <v-btn
                id="saveFlowButton"
                class="pe-2"
                variant="flat"
                @click="emitSaveFlow()"
            >
                <div class="text-none font-weight-regular">Salvar</div>
            </v-btn>

            <v-btn size="small" icon>
                <v-icon icon="ti ti-chevron-down"></v-icon>
                <v-menu
                    activator="parent"
                    location="bottom end"
                    transition="fade-transition"
                >
                    <v-list density="compact" min-width="150" rounded="lg" slim>
                        <v-list-item title="Salvar snapshot" link>
                            <v-dialog
                                v-model="dialogSnapShot"
                                activator="parent"
                                max-width="500"
                            >
                                <v-card rounded="lg">
                                    <v-card-text class="my-0 py-3">
                                        <strong class="text-medium-emphasis">
                                            Salvar Snapshot
                                        </strong>
                                        <v-text-field
                                            v-model="nameSnapshot"
                                            class="mt-4 mb-0"
                                            label="Nome"
                                            :rules="rules"
                                            density="compact"
                                            variant="outlined"
                                        ></v-text-field>
                                    </v-card-text>
                                    <v-card-actions
                                        class="mx-3 my-1 d-flex justify-space-between"
                                    >
                                        <v-btn
                                            class="text-none"
                                            rounded="md"
                                            text="Cancelar"
                                            @click="dialogSnapShot = false"
                                        ></v-btn>
                                        <v-btn
                                            class="text-none"
                                            color="primary"
                                            rounded="md"
                                            text="Criar"
                                            variant="flat"
                                            @click="emitSaveSnapshot()"
                                        ></v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </v-btn>
        </v-btn-group>
    </div>
</template>

<script>
export default {
    name: "FlowSaveModal",
    emits: ["save", "saveSnapshot"],
    data() {
        return {
            rules: [
                (value) => !!value || "Required.",
                (value) => (value || "").length <= 60 || "Max 60 characters"
            ],
            nameSnapshot: "",
            dialogSnapShot: false
        }
    },
    methods: {
        emitSaveFlow() {
            this.$emit("save", "")
        },
        emitSaveSnapshot() {
            this.dialogSnapShot = false
            this.$emit("saveSnapshot", this.nameSnapshot)
        }
    }
}
</script>

<style scoped>
.text-center {
    text-align: center;
}

.font-weight-regular {
    font-weight: 400;
}

.text-medium-emphasis {
    color: rgba(0, 0, 0, 0.6);
}
</style>
