<template>
    <div class="page-wrapper view-mode">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col">
                        <div class="row mb-2">
                            <div class="col">
                                <h2 class="page-title">{{ dashboard.name }}</h2>
                                <span class="text-muted"
                                    >Visualização de Dashboard</span
                                >
                                <a
                                    id="editDashboardButton"
                                    class="btn btn-sm btn-link"
                                    :href="'/dashboards/edit/' + dashboard._id"
                                >
                                    <i class="ti ti-pencil"></i>
                                    Editar
                                </a>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-auto mb-1">
                                <div class="form-selectgroup">
                                    <label class="form-selectgroup-item">
                                        <input
                                            id="todayPeriodInput"
                                            v-model="filters.periodType"
                                            type="radio"
                                            name="period"
                                            value="today"
                                            class="form-selectgroup-input"
                                        />
                                        <span class="form-selectgroup-label"
                                            >Hoje</span
                                        >
                                    </label>
                                    <label class="form-selectgroup-item">
                                        <input
                                            id="24hPeriodInput"
                                            v-model="filters.periodType"
                                            type="radio"
                                            name="period"
                                            value="1_day"
                                            class="form-selectgroup-input"
                                        />
                                        <span class="form-selectgroup-label"
                                            >Últimas 24 horas</span
                                        >
                                    </label>
                                    <label class="form-selectgroup-item">
                                        <input
                                            id="7dPeriodInput"
                                            v-model="filters.periodType"
                                            type="radio"
                                            name="period"
                                            value="7_day"
                                            class="form-selectgroup-input"
                                        />
                                        <span class="form-selectgroup-label"
                                            >7 dias</span
                                        >
                                    </label>
                                    <label class="form-selectgroup-item">
                                        <input
                                            id="15dPeriodInput"
                                            v-model="filters.periodType"
                                            type="radio"
                                            name="period"
                                            value="15_day"
                                            class="form-selectgroup-input"
                                        />
                                        <span class="form-selectgroup-label"
                                            >15 dias</span
                                        >
                                    </label>
                                    <label class="form-selectgroup-item">
                                        <input
                                            id="30dPeriodInput"
                                            v-model="filters.periodType"
                                            type="radio"
                                            name="period"
                                            value="30_day"
                                            class="form-selectgroup-input"
                                        />
                                        <span class="form-selectgroup-label"
                                            >30 dias</span
                                        >
                                    </label>
                                    <label class="form-selectgroup-item">
                                        <input
                                            id="customPeriodInput"
                                            v-model="filters.periodType"
                                            type="radio"
                                            name="period"
                                            value="custom"
                                            class="form-selectgroup-input"
                                        />
                                        <span class="form-selectgroup-label"
                                            >Personalizado</span
                                        >
                                    </label>
                                </div>
                            </div>
                            <div
                                v-show="filters.periodType == 'custom'"
                                class="col-auto"
                            >
                                <div class="input-group">
                                    <div class="input-group-text">De</div>
                                    <input
                                        id="filterStartDateInput"
                                        v-model="filters.start_date"
                                        type="datetime-local"
                                        class="form-control"
                                    />
                                    <div class="input-group-text">Até</div>
                                    <input
                                        id="filterEndDateInput"
                                        v-model="filters.end_date"
                                        type="datetime-local"
                                        class="form-control"
                                    />
                                </div>
                            </div>
                            <div class="col-auto">
                                <button
                                    id="refreshDataButton"
                                    class="btn"
                                    @click="refreshData()"
                                >
                                    <i class="ti ti-refresh"></i>
                                    Atualizar
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row mt-2">
            <div class="col">
                <div v-show="!dashboardLoaded" class="dashboard-grid-loader">
                    <div class="container container-slim py-5">
                        <div class="text-center">
                            <div class="text-muted mb-3">
                                Carregando widgets...
                            </div>
                            <div class="progress progress-sm">
                                <div
                                    class="progress-bar progress-bar-indeterminate"
                                ></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-show="dashboardLoaded" class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col">
                                <div class="dashboard-grid-container">
                                    <Widget
                                        v-for="widget in widgets"
                                        :id="widget.id"
                                        :key="widget.id"
                                        :widget="widget"
                                        :widgets-query-result="widgetsQueryResult"
                                        :gs-id="widget.id"
                                        :gs-x="widget.x || 0"
                                        :gs-y="widget.y || 0"
                                        :gs-min-w="widget.minW || 1"
                                        :gs-min-h="widget.minH || 1"
                                        :gs-w="widget.w"
                                        :gs-h="widget.h"
                                        :edit-mode="false"
                                        :flows="flows"
                                        @refresh-widget-data="refreshWidgetData"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from "moment-timezone"
import "gridstack/dist/gridstack.min.css"
import "gridstack/dist/gridstack-extra.min.css"
import "@vueup/vue-quill/dist/vue-quill.snow.css"

import Api from "../services/api"
import EventBus from "../services/event-bus"
import { store } from "../store"
import { GridStack } from "gridstack"

// widgets
import Widget from "../components/dashboard-widgets/Widget.vue"

export default {
    name: "DashboardEditPage",
    components: {
         
        Widget
    },
    data() {
        return {
            isNew: false,
            table: {},
            dashboard: {
                name: "",
                data: []
            },
            dashboardLoaded: false,
            grid: null,
            widgets: [],
            flows: [],
            tables: [],
            widgetsQueryResult: {},
            filters: {
                periodType: null,
                start_date: null,
                end_date: null
            }
        }
    },
    async mounted() {
        moment.tz.setDefault("America/SaoPaulo")

        store.showSidebar = true
        store.showBackButton = true
        store.sidebarSize = "mini"
        EventBus.emit("set-header-title", "Visualizar dashboard")

        var response = await Api.dashboards.get(this.$route.params.id)
        this.dashboard = response.data
        this.widgets = response.data.data

        await this.loadFlows()
        await this.loadTables()

        // initialize filters
        this.filters.periodType = "today"
        if (localStorage.getItem("dashboard-view-options")) {
            this.filters = JSON.parse(
                localStorage.getItem("dashboard-view-options")
            )
        }

        this.initEditor()
        this.$watch(
            "filters",
            () => {
                this.refreshData()
            },
            { deep: true }
        )
    },
    methods: {
        async initEditor() {
            this.grid = GridStack.init(
                {
                    column: 12,
                    cellHeight: 50
                },
                ".dashboard-grid-container"
            )

            await this.$nextTick()

            this.grid.disable()

            if (this.widgets.length > 0) {
                this.widgets.forEach((w) => (w.initialized = true))
            }

            await this.$nextTick()
            await this.refreshData()

            this.dashboardLoaded = true
        },

        async loadFlows() {
            var response = await Api.flows.list(1, 1000, {}, ["name"])

            this.flows = response.data.items.map((f) => {
                return {
                    _id: f._id,
                    name: f.name
                }
            })
        },

        async loadTables() {
            var response = await Api.database.tables.list(1, 1000)

            this.tables = response.data.items.map((f) => {
                return {
                    _id: f._id,
                    name: f.name
                }
            })
        },

        parseViewOptions(config) {
            var viewOptions = JSON.parse(JSON.stringify(config)) // clone

            if (config.periodType) {
                if (viewOptions.periodType != "custom") {
                    var period = viewOptions.periodType
                        ? viewOptions.periodType.split("_")[0]
                        : 1
                    var type = viewOptions.periodType
                        ? viewOptions.periodType.split("_")[1]
                        : "day"
                    viewOptions.start_date = moment()
                        .subtract(period, type)
                        .format("YYYY-MM-DDTHH:mm:ssZ")
                    viewOptions.end_date = moment().format(
                        "YYYY-MM-DDTHH:mm:ssZ"
                    )
                } else {
                    viewOptions.start_date = moment(
                        viewOptions.start_date
                    ).format("YYYY-MM-DDTHH:mm:ssZ")
                    viewOptions.end_date = moment(viewOptions.end_date).format(
                        "YYYY-MM-DDTHH:mm:ssZ"
                    )
                }

                if (viewOptions.periodType == "today") {
                    viewOptions.start_date = moment()
                        .startOf("day")
                        .format("YYYY-MM-DDTHH:mm:ssZ")
                    viewOptions.end_date = moment()
                        .endOf("day")
                        .format("YYYY-MM-DDTHH:mm:ssZ")
                }
            }

            return viewOptions
        },

        async refreshData() {
            var viewOptions = this.parseViewOptions(this.filters)
            var clonedWidgets = JSON.parse(JSON.stringify(this.widgets))

            localStorage.setItem(
                "dashboard-view-options",
                JSON.stringify(viewOptions)
            )

            // parse view options for each widget config
            clonedWidgets.forEach((w) => {
                if (w.config) {
                    w.config = this.parseViewOptions(w.config)
                }
            })

            console.log(clonedWidgets)

            var response = await Api.dashboards.query(
                null,
                { data: clonedWidgets },
                viewOptions
            )
            this.widgetsQueryResult = response.data
        },

        async refreshWidgetData(widget, config = {}) {
            var viewOptions = this.parseViewOptions(this.filters)
            viewOptions.widgetUserOptions = config

            var clonedWidget = JSON.parse(JSON.stringify(widget))
            var response = await Api.dashboards.query(
                null,
                { data: [clonedWidget] },
                viewOptions
            )
            this.widgetsQueryResult[widget.id] = response.data[widget.id]
        },

        makeWidget(widget) {
            if (!widget.initialized) {
                const elSelector = `#${widget.id}`
                widget.initialized = true
                return this.grid.makeWidget(elSelector)
            }
        }
    }
}
</script>

<style scoped>
.dashboard-grid-container .grid-stack-item {
    border: 1px solid #f0f0f0;
}
</style>
